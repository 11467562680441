import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StoreHour } from 'src/app/_models/StoreHour';
import { Website } from 'src/app/_models/website';
import { WebsiteCompany } from 'src/app/_models/company';
import { CompanyStoreHoursService } from 'src/app/_services/advertising/storehour.service';

@Component({
  selector: 'app-store-hours',
  templateUrl: './store-hours.component.html',
  styleUrls: ['./store-hours.component.css']
})
export class StoreHoursComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() company: WebsiteCompany;
  @Input() website: Website;
storeHours: StoreHour;

@HostListener('window:beforeunload', ['$event'])
unloadNotification($event: any) {
  if (this.editForm.dirty) {
    $event.returnValue = true;
  }
}
  constructor(private alertify: ToastrService,
    private companyStoreHours: CompanyStoreHoursService) { }

  ngOnInit() {
    this.storeHours = this.website?.company?.storeHours;
  }

  onSubmit(e) {
     this.companyStoreHours.edit(this.storeHours).subscribe(
       (data) => {
         this.storeHours = data;
       },
       (error) => {
         this.alertify.error(error);
       },
       () => {
         this.alertify.success('Changes saved');
         this.editForm.form.markAsPristine();
       }
     );
   }
}
