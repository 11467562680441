import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { FileUploadModule } from 'ng2-file-upload';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TimeagoModule } from 'ngx-timeago';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DealInforFormat } from '../_classes/dealinfoclasses/dealinfoformat';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HasRoleDirective } from '../_directives/has-role.directive';
import { HumanizePipe } from '../_pipes/humanize';
import { SaleData } from '../_classes/pdfdataclasses/saledata';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
import { OrderBy } from '../_pipes/orderby';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer'; // 'ngx-extended-pdf-viewer';
import { ReactiveFormsModule } from '@angular/forms';
import { CdkStepperModule } from '@angular/cdk/stepper';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { SafePipe } from '../_pipes/safeurlspipe';
import { DateFilterComponent } from '../shared/date-filter/date-filter.component';
import { DeletedactiveOptionComponent } from '../shared/deletedactive-option/deletedactive-option.component';
@NgModule({
  declarations: [ DeletedactiveOptionComponent,  DateFilterComponent, HumanizePipe, HasRoleDirective, OrderBy, SafePipe ],
  providers: [ DealInforFormat, SaleData],
  imports: [
   
    CdkStepperModule,
    CommonModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right'
    }),
    TabsModule.forRoot(),
    NgxGalleryModule,
    FileUploadModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ButtonsModule.forRoot(),
    TimeagoModule.forRoot(),
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    NgbDropdownModule,
    ReactiveFormsModule,
    
  ],
  exports: [
    DeletedactiveOptionComponent,
    DateFilterComponent,
    SafePipe,
    ReactiveFormsModule,
    NgxLoadingModule,
    NgbModule,
    HasRoleDirective,
    HumanizePipe,
    OrderBy,
    BsDropdownModule,
    ToastrModule,
    TabsModule,
    NgxGalleryModule,
    FileUploadModule,
    BsDatepickerModule,
    PaginationModule,
    ButtonsModule,
    TimeagoModule,
    ModalModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule
  ],
})
export class SharedModule { }
