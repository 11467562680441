import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Item } from 'src/app/_models/Item';
import { AuthService } from 'src/app/_services/auth.service';
import { ItemService } from 'src/app/_services/itemservices/item.service';
import { ListService } from 'src/app/_services/listServices/list.service';

@Component({
  selector: 'app-addnewdescription-vehicle',
  templateUrl: './addnewdescription-vehicle.component.html',
  styleUrls: ['./addnewdescription-vehicle.component.css']
})
export class AddnewdescriptionVehicleComponent implements OnInit {
@Input() chartItem: Item;
@ViewChild('editForm', {static: true}) editForm: NgForm;

largeDescription: string;
  constructor(private chartItemservice: ItemService, private alertify: ToastrService,
    private authService: AuthService, private listService: ListService) { }

  ngOnInit() {
    // if  (this.authService.currentchartItemId != null) {
    //   this.authService.currentchartItemId.subscribe(data => { this.chartItem = data; });
    // }
  }

  noRightClick() {

  }
  onSubmit(e) {
    this.chartItemservice.updateChartItemDescriptions(this.chartItem.id, this.editForm.value).subscribe(data => {
      this.chartItem = data;
    //  this.sharedInvoicesService.invoices.emit(data);
      this.listService.changeItemVehicleEdit(data);
      console.log(data);
     }, error => {
       this.alertify.error(error);
      }, () => {
        this.alertify.success('Descriptions Updated');
    });
  }
}
