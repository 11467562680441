<div class="customer-form-sec">
  <div class="container-fluid">
    <br />
    <div class="row">
      <div class="form-sec1 tab-content col-md-10">
        <div id="depreciate-costs3" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->
              <div class="md-col-10">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedCompanyDepartmentTypes"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Website Company Department Types</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedSalesSoldAs"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Seed Sold As</label>
                </div>
              <div class="md-col-10">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedCompanyDepartments"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Website Company Departments</label>
                </div>
              <div class="md-col-10">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedSaleToRetrieveDealInfo"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >PDf Fields Deal Info $</label>
                </div>
              <div class="md-col-10">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedSaleToRetrieveLoan"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Fields Loan</label>
                </div>
              <div class="md-col-10">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPDFVehicleSold"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Fields Sold Vehicle</label>
                </div>
              <div class="md-col-10">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPDFVehicleTrade1"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Fields Trade 1</label>
                </div>
              <div class="md-col-10">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPDFVehicleTrade2"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Fields Trade 2</label>
                </div>
              <div class="md-col-10">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.SeedPdfFieldSalesman1"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Field Salesman 1</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPropertyRepairTypes"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Real Property Repair Types</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.SeedPdfFieldSalesman2"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Field Salesman 2</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfCompanyProfile"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Field Company Profile</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfSoldToInsurance"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Field Sold To Insurance</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedSaleToRetrieve"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Field Deal Info Sale</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfVehicleTrade2LienHolderProfile"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Field Trade 2 LienHolder</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfVehicleTrade1LienHolderProfile"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Field Trade 1 LienHolder</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfVehicleSoldLienHolderProfile"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Field Sold Vehicle LienHolder</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfFieldNameTypes"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Field Name Types</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfFieldNameBuyers"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Seed Buyers Info</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfFieldCoBuyers"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Seed CoBuyers Info</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfFieldReference1"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Seed Reference 1</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfFieldReference2"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Seed Reference 2</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPdfFieldReference3"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Pdf Seed Reference 3</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedStates"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >State List</label>
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedFormTypes"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >PDF Form Types</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedProgramDefaults"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Program Defaults</label
                  >
                </div>
                <!-- Filled-in checkbox disabled-->
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedAmoInstallments"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Installments</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedDollarTypes"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Dollar Types</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedTaxTemplate"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Tax Template</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedItemTypes"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >POS Item Types</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedSaleTaxDefault"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Tax Defaults</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedVehicleBodyTypes"
                    disabled
                  />

                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Vehicle Body Class Types</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedVehicleMileageCodes"
                    disabled
                  />

                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Vehicle Mileage Codes</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedSaleType"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Sale Type</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedSaleStatus"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Sale Status</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedAmoLoanMethod"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Loan Methods</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedZipCodeLookups"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >States, Zips, Counties</label
                  >
                </div>

                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPersonTypes"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Person TYpes</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedCompanyDepartments"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Company Departments</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedCompanyDepartmentTypes"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Company Department Types</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedFormNameDealInfo"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Form Name DealInfo</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedFormNameVehicleDetails"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Form Name Vehicle Details</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedLabelsDealInfo"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Form Name Labels Sales</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedLabelsVehicleDetails"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Form Name Labels Vehicle Details</label
                  >
                </div>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input filled-in"
                    id="filledInCheckboxDisabled"
                    [checked]="companySeeds?.seedPaymentType"
                    disabled
                  />
                  <label
                    class="form-check-label disabled"
                    for="filledInCheckboxDisabled"
                    >Payment Types</label
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-2">
        <div class="inner-sidebar">
          <ul>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png" />
                <span>Return</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-2.png" />
                <span>Run Seeds</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
