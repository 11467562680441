import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MemberListComponent } from './members/member-list/member-list.component';
import { MemberDetailComponent } from './members/member-detail/member-detail.component';
import { ListsComponent } from './lists/lists.component';
import { MessagesComponent } from './messages/messages.component';
import { AuthGuard } from './_guards/auth.guard';
import { TestErrorsComponent } from './errors/test-errors/test-errors.component';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { ServerErrorComponent } from './errors/server-error/server-error.component';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { PreventUnsavedChangesGuard } from './_guards/prevent-unsaved-changes.guard';
import { MemberDetailedResolver } from './_resolvers/member-detailed.resolver';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { AdminGuard } from './_guards/admin.guard';
import { CompanySeedsComponent } from './company-seeds/company-seeds.component';
import { VehicleBodyClassTypesResolver } from './_resolvers/vehicle-bodytype-categories.resolver';
import { VehicleGridResolver } from './_resolvers/product-list-vehiclegrid.resolver';
import { EmailverifiedComponent } from './members/emailverified/emailverified.component';
import { PasswordresetemailsentComponent } from './members/passwordresetemailsent/passwordresetemailsent.component';


const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'emailverified/:passwordResetGuid', component: EmailverifiedComponent},
  {path: 'emailsent/:passwordResetGuid', component: PasswordresetemailsentComponent},
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: 'list', loadChildren: () => import('./lists/list.module').then(m => m.ListModule) },
      { path: 'buyersguide', loadChildren: () => import('./buyersguid/buyersguide.module').then(m => m.BuyersGuideModule) },
      { path: 'video', loadChildren: () => import('./video/video.module').then(m => m.VideoModule) },
      { path: 'supportdashboard', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
      { path: 'companyprofiledashboard', loadChildren: () => import('./companyprofile/companyprofile.module').then(m => m.CompanyProfileModule) },
      { path: 'realestate', loadChildren: () => import('./realestate/realestate.module').then(m => m.RealEstateModule) },
      { path: 'dashboardloans', loadChildren: () => import('./loans/loan.module').then(m => m.LoanModule) },
      { path: 'dashboardpeople', loadChildren: () => import('./people/people.module').then(m => m.PeopleModule) },
      { path: 'reportsmanager', loadChildren: () => import('./forms/forms.module').then(m => m.FormAndReportsModule) },
      { path: 'admindashboard', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
      { path: 'vehicledashboard', loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule) },
      { path: 'saledashboard', loadChildren: () => import('./sales/sale.module').then(m => m.SaleModule) },
      { path: 'service', loadChildren: () => import('./serviceshop/service-shop.module').then(m => m.ServiceShopModule) },
      { path: 'accountingdashboard', loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule) },
      { path: 'advertising', loadChildren: () => import('./advertising/advertising.module').then(m => m.AdvertisingModule) },
      { path: 'member', loadChildren: () => import('./members/member.module').then(m => m.MemberModule) },
      {path: 'home', component: HomeComponent,
      resolve: { bodyTypes: VehicleBodyClassTypesResolver, chartItems: VehicleGridResolver
      }},
      {path: 'seedcompany', component: CompanySeedsComponent},

      {path: 'lists', component: ListsComponent},
      {path: 'messages', component: MessagesComponent},

    ]
  },
  {path: 'errors', component: TestErrorsComponent},
  {path: 'not-found', component: NotFoundComponent},
  {path: 'server-error', component: ServerErrorComponent},
  {path: '**', component: NotFoundComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
