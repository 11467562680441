import { Component, OnInit } from '@angular/core';
/// <reference path ="../../node_modules/@types/jquery/index.d.ts"/>
// declare var $: any;
// import * as $ from 'jquery';

@Component({
  selector: 'app-inventory-add',
  templateUrl: './inventory-add.component.html',
  styleUrls: ['./inventory-add.component.css']
})
export class InventoryAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {
   // jQuery('#buyer-guide-model').show();
 //    $('#buyer-guide-model').show();
  }

}
