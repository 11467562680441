import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { VehicleCashDownDropDown } from 'src/app/_models/VehicleCashDownDropDown';
import { Website } from 'src/app/_models/website';
import { NgForm } from '@angular/forms';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';
import { WebsiteService } from 'src/app/_services/advertising/website.service';
import { WebsiteCashDownDropDownsService } from 'src/app/_services/advertising/website-cash-down-drop-downs.service';
import { AlertifyService } from 'src/app/_services/alertify.service';


@Component({
  selector: 'app-cash-down',
  templateUrl: './cash-down.component.html',
  styleUrls: ['./cash-down.component.css']
})
export class CashDownComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() website: Website;
  isSelected = false;
  vehicleCashDownDropDowns: VehicleCashDownDropDown[];
  companyGuid: string;
  userAssignedToCompany: UserAssignedToCompany;
  model: VehicleCashDownDropDown;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    }
  }

  constructor(private authService: AuthService,
    private websiteCashDownDropDownsService: WebsiteCashDownDropDownsService,
    private alertify: AlertifyService,
    private websiteService: WebsiteService) {
      this.isSelected = false;
     }

  ngOnInit() {
   // this.vehicleCashDownDropDowns = this.website.vehicleCashDownDropDowns;
    this.isSelected = false;
    this.getVehicleCashDowns();
  }
 getVehicleCashDowns() {
   this.websiteCashDownDropDownsService.get(this.website.id)
     .subscribe(arg => this.vehicleCashDownDropDowns = arg);
   
 }
  onSubmit(e) {
    this.websiteCashDownDropDownsService
      .update(this.editForm.value, this.model.id, this.website.id)
      .subscribe(
        (data) => {
          this.vehicleCashDownDropDowns = data;
          //   this.editForm.reset();
        },
        (error) => {
          this.alertify.error(error);
        },
        () => {
          this.alertify.success('Success');
          this.isSelected = false;
        }
      );
  }

  navigate(e: VehicleCashDownDropDown) {
    this.isSelected = true;
    this.model = e;
    console.log(this.model);
  }

}
