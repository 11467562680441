import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import moment from 'moment';
import { Item } from 'src/app/_models/item';
@Injectable({ providedIn: 'root' })
export class InventoryDataNonSoldVehicle {

    moment = require('moment');
    convertDate(date) {
        const momentDate = moment().format('L').toString();
        return momentDate;
}
      fillPdfFieldsNonSoldVehicle(vehicle: Item, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{

        let  field = '';
     // console.log(sale);
      let newpdfField = {} as PdfSaleFields;
      const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
            field = element.field;
            const str = field;
            const matches = str.match(/\d+/g);
        let i;
        if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
            field = field.replace(i.toString(), '');
            newpdfField.groupBy = 'Vehicle';
            switch (field) {
                case prefix + 'SerialNumber': {
                    newpdfField  = {
                        field: prefix + 'SerialNumber' + i,
                        value: vehicle?.serialNumber,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'DateAdded': {
                    newpdfField  = {
                        field: prefix + 'DateAdded' + i,
                        value: this.convertDate(vehicle?.vehicle?.dateAdded),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'BarCode': {
                    newpdfField  = {
                        field: prefix + 'BarCode' + i,
                        value: vehicle?.barcode,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'StockNumber': {
                    newpdfField  = {
                        field: prefix + 'StockNumber' + i,
                        value: vehicle?.itemVehicleTransientDetails?.stockNumber,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'WeightTransient': {
                    newpdfField  = {
                        field: prefix + 'WeightTransient' + i,
                        value: vehicle?.itemVehicleTransientDetails?.weight,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Vin': {
                    newpdfField  = {
                        field: prefix + 'Vin' + i,
                        value: vehicle?.vehicle.vin,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Make': {
                    newpdfField  = {
                        field: prefix + 'Make' + i,
                        value: vehicle?.vehicle?.make,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Model': {
                    newpdfField  = {
                        field: prefix + 'Model' + i,
                        value: vehicle?.vehicle?.model,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'PurchasedAs': {
                    newpdfField  = {
                        field: prefix + 'PurchasedAs' + i,
                        value: vehicle?.vehicle?.purchasedAs,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Series': {
                    newpdfField  = {
                        field: prefix + 'Series' + i,
                        value: vehicle?.vehicle?.series,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'SmallDescription': {
                    newpdfField  = {
                        field: prefix + 'SmallDescription' + i,
                        value: vehicle?.vehicle?.smallDescription,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }


                case prefix + 'Trim': {
                    newpdfField  = {
                        field: prefix + 'Trim' + i,
                        value: vehicle?.vehicle?.trim,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Weight': {
                    newpdfField  = {
                        field: prefix + 'Weight' + i,
                        value: vehicle?.vehicle?.weight.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Year': {
                    newpdfField  = {
                        field: prefix + 'Year' + i,
                        value: vehicle?.vehicle?.year.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Yr': {
                    newpdfField  = {
                        field: prefix + 'Yr' + i,
                        value: vehicle?.vehicle?.yr,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'BodyClass': {
                    newpdfField  = {
                        field: prefix + 'BodyClass' + i,
                        value: vehicle?.vehicle?.bodyClass,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'ExteriorColor1': {
                    newpdfField  = {
                        field: prefix + 'ExteriorColor1' + i,
                        value: vehicle?.vehicle?.exteriorColor1,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'ExteriorColor2': {
                    newpdfField  = {
                        field: prefix + 'ExteriorColor2' + i,
                        value: vehicle?.vehicle?.exteriorColor2,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
            }
        });
        return of(newPdfFields);
   }
}
