import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscriptions } from 'src/app/_models/Subscriptions';
import { UserCart } from 'src/app/_models/UserCart';
import { UserSubscriptionPlan } from 'src/app/_models/UserSubscriptionPlan';
import { AuthService } from 'src/app/_services/auth.service';
import { UserCartService } from 'src/app/_services/userpaymentservices/User-Cart.service';
import { UserSubscriptionServiceService } from 'src/app/_services/userpaymentservices/UserSubscriptionService.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {
subscriptions: Subscriptions[];
@Input() userSubscriptionPlan = {} as UserSubscriptionPlan;
selectedCategory: any;
userCart = [] as UserCart[];
isOrder = false;
price = 0;
currentTabId = 0;
  constructor(private subscriptionService: UserSubscriptionServiceService,
    private userCartService: UserCartService,
    private alertify: ToastrService,
    private userAssignedToCompanies: AuthService) { }

  ngOnInit() {
    this.subscriptionService.getSubscriptions().subscribe(data => {
      this.subscriptions = data;
    });

  //  this.userCartService.getCart().subscribe(data => { this.userCartService.changeCartItems(data); });
  }
  selectedCategoryChanged(e) {}
  orderSoftware(e: UserSubscriptionPlan) {
    console.log(e);
    this.userSubscriptionPlan = e;
    this.userCartService.changeuserSubscriptionPlan(e);
    if (this.userSubscriptionPlan) {
      this.isOrder = true;
    }
    this.currentTabId = 1;
  }

  subscriptionsisOrder(e) {
    if (!this.userSubscriptionPlan) {
      this.isOrder = false;
    }
    
  }
}
