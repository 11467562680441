
    <br><br>
<div class="col-md-12 " *ngIf="websiteMenus?.length > 0">
    <div class="row">
    <div class="col-md-12">
    
      <!-- <button style="width: 200px;" *ngIf="isImages || isMenuContent"  (click) = "returnToMenu()" class="btn btn-success">Return To Menus</button> -->
     
      <div >
        <div class="row">
          <div class="col-12">
            <button style="width: 200px;" *ngIf="!isImages && !isMenuContent" (click) = "addMenu()" class="btn btn-success">Add Menu</button>
            <table *ngIf="!isImages && !isMenuContent" class="table table-hover" style="cursor: pointer;">
              <thead class="thead-light">
              <tr>
                <td>Id</td>
                <th>Name</th>
                <th>Deleted Date</th>
                <th class="align-left text-center">Delete?</th>
                <th>Active</th>
                <th>Edit</th>
                <th>+ Photos</th>
                <th>+ Content</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let entity of website.websiteMenus" >
                <th># {{entity.id}}</th>
                <th>{{entity.name}}</th>
                <td>{{entity.deletedDate | date: 'short'}}</td>
                
                <td class="align-left text-center">
                  <a class="text-danger">
                      <i  (click)="deleteItem(entity)" class="fa fa-trash" style="font-size: 2em; cursor: pointer;"></i>
                  </a>
                </td> 
                <td  *ngIf="entity.deletedDate !== null" > <i style="color: red;" class=" fa fa-check-circle "></i></td>
                <td  *ngIf="entity.deletedDate === null"> <i style="color: green;" class=" fa fa-check-circle"></i></td>
           
                <td class="align-left text-center">
                    <a class="text-success ">
                        <i  (click)="editItem(entity)" class="fa fa-edit" style="font-size: 2em; cursor: pointer;"></i>
                    </a>
                  </td> 
                  <td class="align-left text-center">
                    <a class="text-success">
                        <i  (click)="editMenuImages(entity)" class="fa fa-photo" style="font-size: 2em; cursor: pointer;"></i>
                    </a>
                  </td> 
                  <td class="align-left text-center">
                    <a class="text-success">
                        <i  (click)="editMenuContent(entity)" class="fa fa-file" style="font-size: 2em; cursor: pointer;"></i>
                    </a>
                  </td> 
            </tr>
         
              </tbody>
            </table>

            <app-websiteimagemanager [contentBannerPhotos] = "contentBannerPhotos" [contentPagePhotos] = "contentPagePhotos" [websiteMenu] = "websiteMenu" *ngIf="isImages && !isMenuContent"></app-websiteimagemanager>
            <app-websitemenu-contentlist [websiteMenu] = "websiteMenu" (websiteChanged2)="updateWebsite($event)" [website] = "website" [websiteMenuParagrahps] = "websiteMenuParagrahps" *ngIf="!isImages && isMenuContent"></app-websitemenu-contentlist>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
