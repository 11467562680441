<div class="container mt-5">
    <div *ngIf="!registerMode" style='text-align: center;'>
        <h1>Start your new cloud software!</h1>
        <p class="lead">Sign up now for free demo</p>
        <div class="text-center">
            <button (click)="registerToggle()" class="btn btn-primary btn-lg mr-2">Register</button>
            <button class="btn btn-info btn-lg">Learn more</button>
        </div>
    </div>

    <div *ngIf="registerMode" class="container">
        <div class="row justify-content-center">
            <div class="col-4">
                <app-register (cancelRegister)="cancelRegisterMode($event)" ></app-register>
            </div>
        </div>
    </div>
</div>
