import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { CompanyProfileRoutingModule } from './companyprofile-routing';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CompanyInformationComponent } from './company-information/company-information.component';
import { PaymeIndividualComponent } from './payme-individual/payme-individual.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { PaymentsGridComponent } from './payments-grid/payments-grid.component';


@NgModule({
  declarations: [
    CompanyProfileComponent,
    CompanyInformationComponent,
    PaymeIndividualComponent,
    SubscriptionsComponent,
    PaymentsGridComponent
],
  entryComponents: [
],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    CompanyProfileRoutingModule,
    AgChartsAngularModule,
    AgGridModule.withComponents([]),
  ]
})
export class CompanyProfileModule { }
