<br>
<div class="container-fluid customer-tabs-sec">
  <ul class="nav nav-tabs">
    <li><a [ngClass]="{'active show' : isDealInfo}"  (click)="tabClicked('isDealInfo')" href="javascript:void(0)" >Deal Info</a></li>
    <li><a  [ngClass]="{'active show' : isCustomer}" (click)="tabClicked('isCustomer')"  href="javascript:void(0)" >Buyer</a></li>
    <li><a  [ngClass]="{'active show' : isCobuyer}" (click)="tabClicked('isCobuyer')"  href="javascript:void(0)" >Co Buyer</a></li>
    <li><a  [ngClass]="{'active show' : isVehicle}" (click)="tabClicked('isVehicle')"  href="javascript:void(0)" >Vehicle</a></li>
    <li><a  [ngClass]="{'active show' : isMisc}" (click)="tabClicked('isMisc')"  href="javascript:void(0)" >Misc Info</a></li>
    <li><a  [ngClass]="{'active show' : isPdfForms}" (click)="tabClicked('isPdfForms')"  href="javascript:void(0)" >PDF Forms</a></li>
    <li><a  [ngClass]="{'active show' : isWashout}" (click)="tabClicked('isWashout')"  href="javascript:void(0)" >Financials</a></li>
    <li><a  [ngClass]="{'active show' : isAmortisation}" (click)="tabClicked('isAmortisation')"  href="javascript:void(0)" >Amortization</a></li>
  </ul>
</div>
<app-sale-amortization [hidden] = "!isAmortisation" [sale]="sale" [amoAmortization] = "amoAmortization"></app-sale-amortization>
<app-sale-new [formName] ="saleLabelFormName" [hidden] = "!isDealInfo" [sale] = "sale"></app-sale-new>
<app-sale-customer [hidden] = "!isCustomer"  [sale] = "sale"></app-sale-customer>
<app-sale-cobuyer [hidden] = "!isCobuyer"  [sale] = "sale"></app-sale-cobuyer>
<app-sale-vehicle   [hidden] = "!isVehicle"  [sale] = "sale"></app-sale-vehicle>
<!-- <app-sale-notes [hidden] = "!isNotes" [saleNotes] = "saleNotes"  [sale] = "sale"></app-sale-notes> -->
<app-sale-forms [sale] = "sale"  [salePackages] ="salePackages" [inventoryPackages] = "inventoryPackages" [hidden]="!isPdfForms"></app-sale-forms>
<!-- <app-pdf-merging  [sale] = "sale"  [salePackages] ="salePackages" [inventoryPackages] = "inventoryPackages" [hidden]="!isPdfForms"></app-pdf-merging> -->
<!-- <app-resports-manager [hidden] = "!isPdfFroms"></app-resports-manager> -->
<app-sale-miscinfo [hidden] = "!isMisc"  [sale] = "sale"></app-sale-miscinfo>
<app-sale-washout [hidden] = "!isWashout"  [sale] = "sale"></app-sale-washout>

