
import { Injectable } from '@angular/core';
import { PDFDocument, PDFTextField } from 'pdf-lib';
import { Observable, of } from 'rxjs';
import { Item } from 'src/app/_models/item';
import { PdfFIeldName } from 'src/app/_models/PdfFiledName';
import { PdfForms } from 'src/app/_models/PdfForms';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { ItemData } from '../pdfdataclasses/InvenoryClasses/inventoryData';
import { PdfFieldLocationClass } from './pdffield-location';
@Injectable({ providedIn: 'root' })
export class PdfMergeInventoryPdfs {
  constructor(
    private pdfService: PdfService,
    // private saleData: SaleData,
    private inventoryData: ItemData,
    private pdfFieldLocationClass: PdfFieldLocationClass
  ) {}
  pdfSaleFields = [] as PdfSaleFields[];
  pdfSaleField = {} as PdfSaleFields;
  pdfSaleFieldsWithDAta = [] as PdfSaleFields[];
  cachePdfFieldName = {} as PdfFIeldName;
  i: any;
  id: any;

  /** creates pdfFormPackages out of multiple url arrays and Item data
   * if send iswith data fills pdf with Item data
   * if send with data false fills form with formfield names
   * if send false false fills form empty as empty pdf package
   * @param item item model with data
   * @param url1 url of first page of document to add not insert
   * @param urlArray url array to insert to new document
   */
  async copyPages(item: Item, url1, urlArray, isWithData, isEmptyForm) {
    console.log(item);
    this.pdfService.getIsEmpty().subscribe((data) => {
      isEmptyForm = data;
    });
    this.pdfService.getIsWithData().subscribe((data) => {
      isWithData = data;
    });
    const donorBytes = [];
    const donorBytesFInal = [];
    const donorPage = [];
    const donorDoc = [];
    /**
     * first page get bytes from url
     * then load data
     * then convert the data bytes to pdfDocument
     * later in routine this firstDonorDoc pages are inserted not added
     */
    let firstDonorPdfBytes = await fetch(url1).then((res) => res.arrayBuffer());

    await this.loadDataTodocument(
      firstDonorPdfBytes,
      item,
      isWithData,
      isEmptyForm
    ).then((data) => {
      firstDonorPdfBytes = data;
    });

    /**
     * load first document
     */
    const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes);

    /**
     * load url array convert to bytes, send bytes to populate textfields with data
     */
    for (let i = 0; i < urlArray.length; ++i) {
      donorBytes[i] = await fetch(urlArray[i].url).then((res) =>
        res.arrayBuffer()
      );
    }

    /* Insert data to donorBytes and create DonorBytesFinal array with data */
    // tslint:disable-next-line:prefer-for-of
    console.log('donorByteslenght: ' + donorBytes.length);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < donorBytes.length; ++i) {
      await this.loadDataTodocument(
        donorBytes[i],
        item,
        isWithData,
        isEmptyForm
      ).then((data) => {
        donorBytesFInal.push(data);
      });
    }
    //  console.log(donorBytesFInal);
    /*
    convert donor bytes to PdfDocument after bytes include data re donorBytesFInal
    */
    for (let i = 0; i < donorBytesFInal.length; ++i) {
      donorDoc[i] = await PDFDocument.load(donorBytesFInal[i]);
    }
    /* create out put document **/
    const pdfDoc = await PDFDocument.create();

    /**
     * copay first page... not in array
     */
    const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [0]);

    /**
     * copy all array pages of singular docuemnts output pdfdoc. Notices these are insertpages nto addpage
     */
    for (let i = 0; i < donorBytes.length; ++i) {
      [donorPage[i]] = await pdfDoc.copyPages(donorDoc[i], [0]);
      pdfDoc.insertPage(0, donorPage[i]);
    }

    /** first page is an ADDpage not an insert */
    /** this is where it adds same page twice on larger merges than 1 form */
  //  pdfDoc.addPage(firstDonorPage);
    console.log(donorBytes.length );
    if (donorBytes.length >  1) {

    } else {
      pdfDoc.addPage(firstDonorPage);
    }

    /** create tyes for 64 and 8 and update globally */
    const u8 = await pdfDoc.save();
    const n64 = await pdfDoc.saveAsBase64();
    this.pdfService.changeUint8ByteArray(u8);
    this.pdfService.changeBase64Array(n64);
    const pdfBytes = u8;
    /** redundant empty urlarray */
    urlArray = [];
  }

  async convertPdfFormTOUintByteArray(
    item: Item,
    pdfForm: PdfForms
  ): Promise<Uint8Array> {
    let isEmptyForm = true;
    let isWithData = true;
    let u8;
    const bytes = await fetch(pdfForm.pdfFormUrl).then((res) =>
      res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(bytes);
    this.pdfService.getIsEmpty().subscribe((data) => {
      isEmptyForm = data;
    });
    this.pdfService.getIsWithData().subscribe((data) => {
      isWithData = data;
    });

    await this.loadDataTodocument(bytes, item, isWithData, isEmptyForm).then(
      (data) => {
        u8 = data;
      }
    );
    //  u8 = await pdfDoc.save();
    // const u64 = await pdfDoc.saveAsBase64();
    return await u8;
  }
  /** loads data to pdf form returns a uint8byte array
   * if send iswith data fills pdf with item data
   * if send with data false fills form with formfield names
   * if send false false fills form empty as empty pdf package
   * updates globasl to null
   * @param pdfDocument uint8ByteArray
   * @param item item model
   * @param isWithData boolean
   * @param isEmptyForm boolean
   */
  async loadDataTodocument(
    pdfDocument,
    item: Item,
    isWithData: boolean,
    isEmptyForm: boolean
  ): Promise<Uint8Array> {
    this.pdfService.changeUint8ByteArray(null);
    this.pdfService.changeBase64Array(null);

    const pdfDoc = await PDFDocument.load(pdfDocument);
    const form = pdfDoc.getForm();
    const fields = form.getFields();

    let fieldToSet = {} as PDFTextField;
    console.log(fields);
    fields.forEach((field) => {
      const type = field.constructor.name;
      const name = field.getName();
      // PDFTextField
      if (type === 'PDFTextField') {
        fieldToSet = form.getTextField(name);
        fieldToSet.removeMaxLength();
        this.pdfSaleField = {
          id: this.i,
          field: '',
          value: '',
          calculate: 'false',
        };
        this.pdfSaleField.field = name.toString();
        this.pdfSaleField.id = this.id;
        // get calculation here!!! what if user renamed field? or Deleted Field?
        // stop users ability to rename or delete.
        const strFirstThree = name.substring(0, 3);
        if (strFirstThree === 'cst') {
          this.getDivCalculationForCstFields(name).subscribe(data => {
            // if PDFFIELDNAME in pdfFieldNameType was deleted or renamed this would cause no data
            if (data) {
              this.pdfSaleField.calculation = data.calculation;
              this.pdfSaleField.parseBy = data.parseBy;
              this.pdfSaleField.isCalculated = data.isCalculated;
            }
          });
        }
        this.pdfSaleField.calculate = 'false';
        const length = this.pdfSaleFields.push(this.pdfSaleField);
        this.id = this.id + 1;
        if (!isWithData && !isEmptyForm) {
          fieldToSet.setText(name.toString());
        }
      }
    });
  //  console.log(this.pdfSaleFields);
    /** TODO: FiNISH TESTING ITEM TABLE of data
     *  FILLING here with Item Data
     */
    if (this.inventoryData && this.pdfSaleFields.length > 0) {
    this.inventoryData.fillPdfFieldsWithDAta(item, this.pdfSaleFields)
      .subscribe((data) => {
        this.pdfSaleFieldsWithDAta = data;
      });
    // fill form with values from data base
    if (isWithData && !isEmptyForm) {
      //  console.log(this.pdfSaleFieldsWithDAta);
      this.pdfSaleFieldsWithDAta.forEach((element) => {
        try {
          fieldToSet = form.getTextField(element.field);
          fieldToSet.setText(element.value);
        } catch (error) {
         // console.log(error);
        }
        // console.log(element.value);
      });
    }
  }
    // fill empty form
    if (isEmptyForm) {
      this.pdfSaleFieldsWithDAta.forEach((element) => {
        fieldToSet = form.getTextField(element.field);
        fieldToSet.setText('');
      });
    }
    const u8 = await pdfDoc.save();
    const n64 = await pdfDoc.saveAsBase64();
    this.pdfService.changeUint8ByteArray(u8);
    this.pdfService.changeBase64Array(n64);
    /** initialize to empty array */
    this.pdfSaleFields = [];
    return await pdfDoc.save();
  }
 /** parses all PdfFieldNameTypes for 'cst' pdfFieldNamettype
  * returns the named 'name' of a PdfFieldName
  * often used to add calculation or isCalculated to Field of pdf in a Div of html.
  * @param name name has numbers removed within this function
  */
  getDivCalculationForCstFields(name: string): Observable<PdfFIeldName>{
   // const pdfFieldNameTypes = this.pdfService.pdfFieldNameTypesCached;
   name = name.replace(/[0-9]/g, '');
    const pdfFieldNameTypes = this.pdfService.pdfFieldNameTypesCached;
    const pdfFieldNameType = pdfFieldNameTypes.find(
      (x) => x.parseBy === 'cst'
    );

    const pdfFieldName = pdfFieldNameType.pdfFieldNames.find(
      (x) => x.value === name
      );
      this.cachePdfFieldName = pdfFieldName;
      return of(pdfFieldName);
  }

  /** used in LoadDataToDocument to create array
   * @param value load local variable with model array used in LoadDataToDocument
   */
  setFieldValues(value: PdfSaleFields) {
    const length = this.pdfSaleFields.push(value);
  }
}
