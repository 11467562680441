import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.css']
})
export class AdminPanelComponent implements OnInit {
  isUsers = true;
  isDefaults = false;
  selectedCompany1: UserAssignedToCompany;
  constructor(private authService: AuthService, private router: Router) {
    this.authService.company.subscribe((data) => {
      this.selectedCompany1 = data;
    });
  }

  ngOnInit(): void {
  }

  tabClicked(e) {
    this.isUsers = false;
    this.isDefaults = false;

    if (e === 'isDashboard') {
      this.isUsers = true;
      // this.router.navigate([
      //   'inventorydashboard/inventorydashboard/' +
      //     this.selectedCompany1.parentGuid,
      // ]);
    }
    if (e === 'isDefaults') {
      this.isDefaults = true;
      // this.router.navigate([
      //   'inventorydashboard/repairs/' + this.selectedCompany1.parentGuid,
      // ]);
    }
  }

}
