<!-- <div class="modal fade" id="addPaymentModal" role="dialog" tabindex="-1"> -->
<!-- this is the ADD PAYMENT NOT VIEW PAYMENTS MODAL-->
  <!-- <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <img src="assets/images/payment-icon.png">
        <h4 class="modal-title">Payment</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body payments-form">
        <div class="payments-list-grid">
          <div class="row">
            <div class="col-md-4 cstm-col">
              <div class="rw">
                <label>Payment #</label>
                <input type="text" name="" value="1" class="cstm-input">
              </div>
              <div class="rw">
                <label>Receipt #</label>
                <input type="text" name="" value="42830011" class="cstm-input">
              </div>
              <div class="rw">
                <label>Date Paid</label>
                <input type="text" name="" value="04/10/2018" class="cstm-input">
                <button class="cstm-btn">...</button>
              </div>
            </div>
            <div class="col-md-4 cstm-col">
              <div class="rw">
                <label>Payment</label>
                <input type="text" name="" value="0.00" class="cstm-input without-border">
              </div>
              <div class="rw">
                <label>Last Date</label>
                <input type="text" name="" value="" class="cstm-input" disabled="">
              </div>
              <div class="rw">
                <label>Date Next</label>
                <input type="text" name="" value="04/10/2018" class="cstm-input">
                <button class="cstm-btn">...</button>
              </div>
            </div>
            <div class="col-md-4 cstm-col last-grid-col">
              <div class="rw">
                <label>Taxes</label>
                <input type="text" name="" value="364.54" class="cstm-input without-border">
              </div>
              <div class="rw">
                <label>Misc Charges</label>
                <input type="text" name="" value="0.00" class="cstm-input without-border">
              </div>
              <div class="rw">
                <label>PickUps</label>
                <input type="text" name="" value="0.00" class="cstm-input without-border">
              </div>
            </div>
          </div>
        </div>
        <div class="payments-tabs">
          <div class="inner">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">	<img src="assets/images/payment-icon.png">
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                  <img src="assets/images/notes-icon-1.jpg">
                </a>
              </li>
            </ul>
            <div class="tab-content" id="paymentTabContent">
              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="inner">
                  <div class="row">
                    <div class="col-md-4 cstm-col left-grid">
                      <div class="inner">
                        <h2 class="title">Today's</h2>
                        <div class="row">
                          <div class="col-md-12 custom-col">
                            <div class="cstm-rw">
                              <label class="amt-label">Amount Due</label>
                              <input type="text" name="" value="0.00" class="cstm-input amt-input">
                              <button class="amt-btn">Pay <span>Off</span></button>
                            </div>
                            <div class="cstm-rw">
                              <label>Principal</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Interest</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Taxes</label>
                              <input type="text" name="" value="-1.#IND" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Late Fee</label>
                              <input type="text" name="" value="0.00" class="cstm-input">
                            </div>
                            <div class="cstm-rw">
                              <label>Misc</label>
                              <input type="text" name="" value="0.00" class="cstm-input">
                            </div>
                            <div class="cstm-rw">
                              <label>PickUps</label>
                              <input type="text" name="" value="0.00" class="cstm-input">
                            </div>
                            <div class="cstm-rw">
                              <label>Amount Paid</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Pay Off</label>
                              <input type="text" name="" value="120.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Balance</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Method</label>

                            </div>
                            <div class="cstm-rw">
                              <input list="browsers" name="browser" class="cstm-select">
                              <datalist id="browsers">
                                <option value="Internet Explorer">
                                <option value="Firefox">
                                <option value="Chrome">
                                <option value="Opera">
                                <option value="Safari">
                              </datalist>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 cstm-col center-grid">
                      <div class="inner">
                        <h2 class="title">Total Paid</h2>
                        <div class="row">
                          <div class="col-md-12 custom-col">
                            <div class="cstm-rw">
                              <label>Amount Due</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Principal</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Interest</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Taxes</label>
                              <input type="text" name="" value="-1.#IND" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Late Fee</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Misc</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>PickUps</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Amount Paid</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Pay Off</label>
                              <input type="text" name="" value="120.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Balance</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 cstm-col right-grid">
                      <div class="inner">
                        <h2 class="title">Balance (Unpaid)</h2>
                        <div class="row">
                          <div class="col-md-12 custom-col">
                            <div class="cstm-rw">
                              <label>Interest</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Taxes</label>
                              <input type="text" name="" value="364.54" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Misc</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>PickUps</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Today's Unpaid Interest</label>
                              <input type="text" name="" value="59.65" class="cstm-input" disabled="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">Pick Ups / Misc Charges</div>
              <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">Customer / Vehicle / Notes</div>
            </div>
          </div>
        </div>
        <div class="payments-foot">
          <div class="row">
            <div class="col-md-6 left-col">
              <div class="inner">
                <h3 class="title">Future Payoff</h3>
                <span>After</span>
                <input type="text" name="" value="10" class="af-day-input">
                <span>days</span>
                <span class="pay-off">Pay Off</span>
                <input type="text" name="" value="120.00" disabled="" class="payoff-input">
              </div>
            </div>
            <div class="col-md-6 right-col">
              <button class="cstm-btn">Reset</button>
              <button class="cstm-btn">OK</button>
              <button class="cstm-btn">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
<!-- </div> -->

<div class="modal-dialog" style="width: inherit;">
  <div class="modal-content">
    <div class="modal-header">
      <img src="assets/images/payment-icon.png">
      <h4 class="modal-title">Payment</h4>
      <button type="button" class="close" data-dismiss="modal">&times;</button>
    </div>
<div class="customer-form-sec" style="width: 100% !important;">
  <div class="container-fluid" style="width: 100% !important;">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div class="payments-form">
          <div class="payment-first-rw">
            <div class="input-sec">
              <span>Account</span>
              <input type="text" name="" value="1838" class="cstm-input">
            </div>
            <div class="payment-btn-sec">
              <ul>
                <li><button class="cstm-btn" data-toggle="modal" data-target="#addPaymentModal">Add Payment</button></li>
                <li><button class="cstm-btn">Edit Payment</button></li>
                <li><button class="cstm-btn">Delete Payment</button></li>
                <li class="recept-sec">
                  <button class="cstm-btn">
                    <img class="btn-icon" src="assets/images/recept-icon.png">
                    <div class="txt-sec">
                      <span>Receipt</span>
                      <span class="form-txt">Form</span>	
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="payments-tabs">
            <div class="inner">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Deal Details</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Pick Ups / Misc Charges</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Customer / Vehicle / Notes</a>
                </li>
              </ul>
              <div class="tab-content" id="paymentTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div class="inner">
                    <div class="row">
                      <div class="col-md-6 cstm-col left-grid">
                        <div class="inner">
                          <h2 class="title">Account Details</h2>
                          <div class="row">
                            <div class="col-md-6 custom-col">
                              <div class="cstm-rw">
                                <label>Principal</label>
                                <input type="text" name="" value="120.00" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Cash Down</label>
                                <input type="text" name="" value="6580.00" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>By Installment</label>
                                <input list="browsers" name="browser" class="cstm-input" disabled="">
                                <datalist id="browsers">
                                  <option value="Internet Explorer">
                                  <option value="Firefox">
                                  <option value="Chrome">
                                  <option value="Opera">
                                  <option value="Safari">
                                </datalist>
                              </div>
                              <div class="cstm-rw">
                                <label>APR</label>
                                <input type="text" name="" value="21.00" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Term</label>
                                <input type="text" name="" value="00.00" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>DTaxable Amt</label>
                                <input type="text" name="" value="6075.00" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>DTax</label>
                                <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                              </div>
                            </div>
                            <div class="col-md-6 custom-col">
                              <div class="cstm-rw">
                                <label>Sold Date</label>
                                <input type="text" name="" value="11/28/2015" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>First Due Date</label>
                                <input type="text" name="" value="11/28/2015" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Payments</label>
                                <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Interest</label>
                                <input type="text" name="" value="-120.00" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Deffered Tax</label>
                                <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Payment</label>
                                <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Tax On Down</label>
                                <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><!-- /.left-grid -->
                      <div class="col-md-6 cstm-col right-grid">
                        <div class="inner">
                          <h2 class="title">Total Paid</h2>
                          <div class="row">
                            <div class="col-md-6 custom-col">
                              <div class="cstm-rw">
                                <label>Term Paid</label>
                                <input type="text" name="" value="0.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Principal Paid</label>
                                <input type="text" name="" value="0.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Interest Paid</label>
                                <input type="text" name="" value="0.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Uneraned Interest</label>
                                <input type="text" name="" value="-120.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Unpaid Interest</label>
                                <input type="text" name="" value="00.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Total Due Paid</label>
                                <input type="text" name="" value="6075.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Total Amount Paid</label>
                                <input type="text" name="" value="0.00" class="cstm-input">
                              </div>
                            </div>
                            <div class="col-md-6 custom-col">
                              <div class="cstm-rw">
                                <label>Payoff</label>
                                <input type="text" name="" value="0.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Balance</label>
                                <input type="text" name="" value="0.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Last Paid Date</label>
                                <input type="text" name="" value="" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Current Due</label>
                                <input type="text" name="" value="0.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Next Due Date</label>
                                <input type="text" name="" value="" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Total Late Fee</label>
                                <input type="text" name="" value="0.00" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Unpaid / Balance</label>
                                <input type="text" name="" value="0.00" class="cstm-input">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><!-- /.right-grid -->
                    </div>
                  </div><!-- /.inner -->
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">Pick Ups / Misc Charges</div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">Customer / Vehicle / Notes</div>
              </div>
            </div>
          </div><!-- /.payments-tabs -->
          <div class="payment-table">
            <div class="inner-table">
              <!-- <table border="1" bordercolor="#ccc">
                <tr>
                  <th>R #</th>
                  <th>Date</th>
                  <th>Amount Paid</th>
                  <th>Principal Paid</th>
                  <th>Interest Paid</th>
                  <th>Tax Paid</th>
                  <th>Late Fee</th>
                  <th>Total Pmts</th>
                  <th>Total Principal</th>
                  <th>Total Interest</th>
                  <th>Demo Heading 1</th>
                  <th>Demo Heading 2</th>
                  <th>Demo Heading 3</th>
                  <th>Demo Heading 4</th>
                  <th>Demo Heading 5</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table> -->
              <table border="1" bordercolor="#ccc" class="tablescroll">
                <tr>
                  <th>PaymentNumber:</th>
                  <th>Date:</th>
                  <th>Payment Paid</th>
                  <th>Total Loan Payments</th>
                  <th>Principal Paid</th>
                  <th>Principle Balance</th>
                  <th>Total Principle</th>
                  <th>Today Interest Earned</th>
                  <th>Today Unpaid Interest</th>
                  <th>Unpaid Applied</th>
                  <th>Interest Paid</th>
                  <th>Total Interest Paid</th>
                  <th>Total Earned Interest</th>
                  <th>Non Amortized</th>
                  <th>Total Non Amortized</th>
                  <th>Late Fee</th>
                  <th>Total Late Fee</th>
                  <th>Misc Today</th>
                  <th>Total Misc</th>
                  <th>Deferred Down</th>
                  <th>TOtal Deferred</th>
                  <th>Total Today</th>
                  <th>Total Paid</th>
                 
                  <th>Total Unpaid Interest</th>
                  
                 
                  
                </tr>
                <tr *ngFor="let item of amoPayments| orderBy: '+paymentNumber'">
                  <td>{{item.paymentNumber}}</td>
                  <td>{{item.datePaid | date: 'shortDate'}}</td>
                  <td>{{item.todayLoanPaid | number:'1.2-2'}}</td>
                  <td>{{item?.totalLoanPaid | number:'1.2-2'}}</td>
                  <td>{{item.todayPrinciple | number:'1.2-2'}}</td>
                  <td>{{item?.principleBalance | number:'1.2-2'}}</td>
                  <td>{{item?.totalPrinciplePaid | number:'1.2-2'}}</td>
                  <td>{{item.todayInterestDue | number:'1.2-2'}}</td>
                  <td>{{item?.todayInterestUnpaid | number:'1.2-2'}}</td>
                  <td>{{item?.todayInterestUnpaidApplied | number:'1.2-2'}}</td>
                  <td>{{item.todayInterest | number:'1.2-2'}}</td>
                  <td>{{item?.totalInterestPaid | number:'1.2-2'}}</td>
                  <td>{{item?.totalInterestDue | number:'1.2-2'}}</td>
                  
                  <td>{{item.todayNonAmortizedPaid | number:'1.2-2'}}</td>
                  <td>{{item.totalNonAmortizedPaid | number:'1.2-2'}}</td>
                  <td>{{item.todayLateFee | number:'1.2-2'}}</td>
                  <td>{{item?.totalLateFeePaid | number:'1.2-2'}}</td>
                  <td>{{item?.todayMisc | number:'1.2-2'}}</td>
                  <td>{{item?.totalMisc | number:'1.2-2'}}</td>
                  <td>{{item?.todayDeferredDown | number:'1.2-2'}}</td>
                  <td>{{item?.totalDeferredDown | number:'1.2-2'}}</td>
                  <td>{{item?.sumPaidToday | number:'1.2-2'}}</td>
                  <td>{{item?.totalSumPaid | number:'1.2-2'}}</td>
                  
                  <td>{{item?.totalInterestUnpaid | number:'1.2-2'}}</td>
                  
                 
                </tr>
                
              </table>
            </div><!-- /.inner-table -->
          </div><!-- /.payment-table -->
        </div>
      </div>
     
    </div>
  </div>
</div>
</div>
</div>


<div class="modal fade lg" id="addPaymentModal" role="dialog" tabindex="-1"> 
  <!-- <app-loan-addpayment></app-loan-addpayment> -->
  </div>