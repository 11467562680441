import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';


@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() company: CompanyProfile;

  userAssignedToCompany: UserAssignedToCompany;
  companyGuid: string;
  constructor(private companyService: CompaniesService,
    private authService: AuthService,
    private alertify: ToastrService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(data => { this.userAssignedToCompany = data; });
  }
  onSubmit(e: CompanyProfile) {
    console.log(this.company);
    this.companyService.saveEditCompany(this.userAssignedToCompany.companyProfileId, this.company).subscribe(data => {
      console.log(data);
    }, error => { this.alertify.error(error); }, () => {

    });
    console.log(e);
  }

}
