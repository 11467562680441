import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanySeed } from 'src/app/_models/SeedCompany';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SeedService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getSeeds(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/allseeds/' + parentGuid);
  }
}
