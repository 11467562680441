import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { NewWebsiteMenuDto } from 'src/app/_models/DTOS/newWebsiteMenuDto';
import { MenuReturnWebsiteMenuParagraphDto } from 'src/app/_models/DTOS/WebsiteMenuParagraphReturndto';
import { ContentPhotos } from 'src/app/_models/websites/WebsiteContentPhotos';
import { WebsiteMenu } from 'src/app/_models/websites/WebsiteMenu';
import { WebsiteMenuParagraph } from 'src/app/_models/websites/WebsiteMenuParagraph ';
import { Website } from 'src/app/_models/websites/websites';
import { WebsiteMenuService } from 'src/app/_services/advertising/website-menu.service';
import { WebsiteService } from '../website.service';
import { EditmenuModalComponent } from './editmenu-modal/editmenu-modal.component';
import { WebsitemenuModalComponent } from './websitemenu-modal/websitemenu-modal.component';

@Component({
  selector: 'app-websitemenus',
  templateUrl: './websitemenus.component.html',
  styleUrls: ['./websitemenus.component.scss']
})
export class WebsitemenusComponent implements OnInit {

  constructor(private websiteService: WebsiteService,
              private modalService: BsModalService,
              private router: Router,
              private websiteMenuService: WebsiteMenuService,
              private toastR: ToastrService) { }
@Input()  website = {} as Website;
websiteMenus = [] as WebsiteMenu[];
websiteMenuParagrahps =[] as WebsiteMenuParagraph[];
websiteMenu = {} as WebsiteMenu;
contentPagePhotos = [] as ContentPhotos[];
contentBannerPhotos = [] as ContentPhotos[];
isImages = false;
bsModalRef: any;
isMenuContent = false;
  ngOnInit() {
    this.getImages();
    this.getWebsiteMenus();
  // console.log(this.website);
  }
  getWebsiteMenus() {
    this.websiteMenuService.getWebsiteMenus(this.website.id).subscribe(data => { 
      this.websiteMenus = data;
      this.website.websiteMenus = data;
    }  );
  }
  getImages() {
    this.websiteService.listImagesCategorized(this.websiteMenu.id).subscribe(data => {
      console.log(data.bannerImages);
      this.contentBannerPhotos = data.bannerImages;
      this.contentPagePhotos = data.pageImages;
    }, error => {
    }, () => {
    });
   }
  addMenu() {
    const config = {
      class: 'modal-lg',
      initialState: {
        website: this.website,
      }
    };
    this.bsModalRef = this.modalService.show(WebsitemenuModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values = {} as NewWebsiteMenuDto) => {
      console.log(values);
      this.websiteService.addWebsiteMenu(values, this.website.id).subscribe(data => {
          this.websiteMenus = data;
          this.website.websiteMenus = data;
          console.log(data);
      }, err => { this.toastR.error(err); }, () => {
        this.toastR.success('Menu added.');
      
      });
    });
  }
  deleteItem(e) {console.log(e);
                 this.websiteService.markMenuActive(e.id, this.website.id).subscribe(data => {
    this.website = data;
  }, error => { this.toastR.error(error); }, () => { this.toastR.success('Success'); });
  }

  editItem(e: WebsiteMenu) {console.log(e);
                            this.websiteMenu = e;
                            const config = {
      class: 'modal-lg',
      initialState: {
        entity: this.websiteMenu,
      }
    };
     this.bsModalRef = this.modalService.show(EditmenuModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values: WebsiteMenu) => {
      console.log(values);
      this.websiteService.editWebsiteMenu(values).subscribe(data => {
        //  this.website = data;
          console.log(data);
      }, err => { this.toastR.error(err); }, () => {
        this.toastR.success('Menu edited.');
      });
    });
  }

  editMenuImages(e: WebsiteMenu) {
      this.websiteService.getWebsiteMenuById(e.id).subscribe(data => {
        this.websiteMenu = data;
        this.websiteService.listImagesCategorized(this.websiteMenu.id).subscribe(data1 => {
          this.contentBannerPhotos = data1.bannerImages;
          this.contentPagePhotos = data1.pageImages;
          this.websiteService.contentPagePhotos = data1.bannerImages;
          this.websiteService.contentBannerPhotos = data1.pageImages;
        }, error => {
        }, () => {
        });
      }, error => { this.toastR.error(error);
      }, () => {
        this.isImages = !this.isImages; });

   //  this.router.navigateByUrl('admin/websitemenuimages/' + e.id);
  }
  returnToMenu() {
      this.isImages = false;
      this.isMenuContent = false;
  }
  editMenuContent(e: WebsiteMenu) {
    this.websiteService.getWebsiteMenuById(e.id).subscribe(data1 => {
      this.websiteMenuParagrahps = data1.websiteMenuParagraphs;
      this.websiteMenu = data1;
    }, error => {
      console.log(error);
    }, () => {
      this.isMenuContent = true;
    this.isImages = false;
    });
  }

  updateWebsite(e: MenuReturnWebsiteMenuParagraphDto) {
    console.log(e);
    this.website = e.website; this.websiteMenus = e.website.websiteMenus; this.websiteMenu = e.websiteMenu, console.log(this.website); 
  }
}
