

<div style="margin-top: 10px;" class="container-fluid customer-tabs-sec">

  <app-date-filter (calenderOutput) = "updateDates($event)" ></app-date-filter>

  <ul class="nav nav-tabs">
    <li>
      <a
        data-toggle="tab"
        [ngClass] = "isAllSales ? 'active show' : ''"
        (click)="filter('isAllSales')"
        >All Sales</a
      >
    </li>
    <!-- *appHasRole="['Admin']"  -->
    <li  >
      <a
        data-toggle="tab"
        [ngClass] = "isSalesTax ? 'active show' : ''"
        (click)="filter('isSalesTax')"
        >Sales Tax Report</a
      >
    </li>
    <li  >
      <a
        data-toggle="tab"
        [ngClass] = "isSaleJournal ? 'active show' : ''"
        (click)="filter('isSaleJournal')"
        >Sales Journal</a
      >
    </li>
    <li  >
      <a
        data-toggle="tab"
        [ngClass] = "isVehicleSoldList ? 'active show' : ''"
        (click)="filter('isVehicleSoldList')"
        >Repossessed</a
      >
    </li>
   
	<div class="d-flex justify-content-right">
		<pagination
      [boundaryLinks]="true"
      pageBtnClass = "nav nav-tabs"
		  [(ngModel)]="pagination.currentPage"
		  [totalItems]="pagination.totalItems"
		  (pageChanged)="pageChanged($event)"
		  [itemsPerPage]="pagination.itemsPerPage"
		  previousText="&lsaquo;"
		  nextText="&rsaquo;"
		  firstText="&laquo;"
		  lastText="&raquo;"
		>
		</pagination>
	  </div>
  
</ul>
<!-- <div class="d-flex justify-content-right">
  <app-deletedactive-option (isDeletedClick) = "isChildActiveDeletedClick($event)"></app-deletedactive-option>
</div> -->
</div>

<div style="margin-top: 80px; height: 100%;" class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <div class="row">
                  <div class="col-md-11 table-sec">
                   
                    <div id="printableTable">
                     
                      <!-- <div class="col-md-12">
                        <div *ngIf="isExpandedSearch" class="row">
                         
                          <div class="col-md-4">
                            <input (keyup.enter)="onSearch()" class="form-control mr-2" ng-model="searchValue" #search style="width: 300px;" type="text" placeholder="search">
                          </div>
                          <div class="col-md-4">
                            <select   [(ngModel)]="selectedOption"  class="form-control mr-2"  style="width: 300px;" (change)="changeValue($event.target.value)">
                              <option *ngFor="let item of selectedOptionList" [value]="item.value">{{item.name}}</option>
                            </select>
                          </div>
                          <div class="col-md-4">
                            <button (click)="onSearch()"  class="btn btn-outline-primary my-2" >Search</button>
                          </div>
                        </div>
                      </div> -->
                   
                     <div class="col-md-12">
                       <div class="row">
                         <div class="col-md-3">
                          <div class="d-flex ">
                            <app-deletedactive-option (isDeletedClick) = "isChildActiveDeletedClick($event)"></app-deletedactive-option>
                          </div>
                         </div>
                         <div class="col-md-9">
                          <div class="d-flex ">
                            <div *ngIf="isExpandedSearch" class="row">
                           
                              <div class="col-md-4">
                                <input (keyup.enter)="onSearch()" class="form-control mr-2" ng-model="searchValue" #search style="width: 200px;" type="text" placeholder="search">
                              </div>
                              <div class="col-md-3">
                                <select   [(ngModel)]="selectedOption"  class="form-control mr-2"  style="width: 140px;" (change)="changeValue($event.target.value)">
                                  <option *ngFor="let item of selectedOptionList" [value]="item.value">{{item.name}}</option>
                                </select>
                              </div>
                              <div class="col-md-3">
                                <button (click)="onSearch()"  class="btn btn-outline-primary float-right " >Search</button>
                              </div>
                            </div>
                          </div>
                         </div>
                       </div>
                     </div>
                     
                    <table   borderColor="#ccc"  >
                      <tr>
                      <th style="width: 350px;">
                       
                      </th>
                      <th style="width: 1000px;">
                       
                      </th>
                    </tr>
                      <tr>
						<th  (click)="sort('id')">Id &nbsp;
							<i [ngClass]="isIdAscending ? 'up': 'down'"></i> </th>
                        <th (click)="sort('date')">Date &nbsp;
                          <i [ngClass]="isDateAscending ? 'up': 'down'"></i></th>
                          <th (click)= "sort('invoiceNumber')">Invoice # &nbsp;
                            <i [ngClass]="isInvoiceAscending ? 'up': 'down'"></i></th>
                          <th>Phone </th>
                        <th (click)= "sort('customer')">Customer &nbsp;
                          <i [ngClass]="isCustomerAscending ? 'up': 'down'"></i></th>
                 
                        <th  *ngIf="isLoanAmount"  (click)= "sort('loanAmount')">Loan Amount &nbsp;
                          <i [ngClass]="isLoanAmountAscending ? 'up': 'down'"></i></th>
                        
                        <th (click)= "sort('saleType')">Sale Type&nbsp;
                          <i [ngClass]="isSaleTypeAscending ? 'up': 'down'"></i></th>
                        <th (click)= "sort('saleStatus')">Sale Status &nbsp;
                          <i [ngClass]="isSaleSTatusAscending ? 'up': 'down'"></i></th>
                        <th (click)= "sort('vehicle')">Vehicle&nbsp; <i [ngClass]="isVehicleAscending ? 'up': 'down'"></i></th>
                        <th >Vin&nbsp; </th>
                      </tr>
                      <tr [hidden]="isPrint" id="hideRow" class="hidden-print" >
                        <td class="hidden-print"  style="width: 76px "><input (keyup)="setSearchBy('searchId', $event.target.value)" ></td>
                        <td style="width: 76px"><button class="btn-success" (click)="showDatesTrue()">Filter</button></td>  
                        <td><input style="width: 55px;" (keyup)="setSearchBy('searchInvoiceNumber', $event.target.value)"></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchPhone', $event.target.value)"></td>  
                        <td *ngIf="isLoanAmount"  ><input style="width: 96px;" (keyup)="setSearchBy('searchCustomer', $event.target.value)"></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchLoanAmount', $event.target.value)"></td>  
                        <td><select  
                          [(ngModel)] ="selectType" (change) ="selectedTypeChanged($event.target.value)"
                          name="vehicleBodyClassTypes"
                          onmousedown = "value = '';" 
                          #vehicleBodyClassTypes="ngModel">
                          <option ngValue= "null">--All--</option>
                          <option *ngFor="let summary of chartInvoiceSaleTypes" value="{{summary.id}}">
                            {{summary.value}}
                          </option>
                      </select></td>  
                       
                        <td><select  
                          [(ngModel)] ="selectStatus" (change) ="selectedStatusChanged($event.target.value)"
                          name="chartInvoiceStatusess"
                          onmousedown = "value = '';" 
                          #chartInvoiceStatusess="ngModel">
                          <option ngValue= "null">--All--</option>
                          <option *ngFor="let summary of chartInvoiceStatuses" value="{{summary.id}}">
                            {{summary.value}}
                          </option>
                      </select>
                    </td>  
                    
                      </tr>
                   
                      <tbody> <!-- *ngIf=" isLoanAmount"  GRID DATA *ngIf="i != sales.length - 1" --> 
                      <tr class="td-row" *ngFor="let item of sales; let i=index">
                        <td *ngIf="i != sales.length - 1">{{item?.id}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.soldDate | date: 'shortDate'}}</td>
						            <td *ngIf="i != sales.length - 1">{{item?.acct}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.buyer?.phone}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.buyer?.firstName}}&nbsp; {{item?.buyer?.lastName}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.amountFinanced | number : '1.2'}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.saleTypes?.value}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.saleStatus?.value}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.vehiclePurchased?.vehicle?.year + " " + item?.vehiclePurchased?.vehicle?.make + " " + item?.vehiclePurchased?.vehicle?.model}}</td>
                        <td *ngIf="i != sales.length - 1"></td> 
                        <td *ngIf="i != sales.length - 1">
                          <a
                          [ngClass]="{'btn btn-danger' : item.deletedDate , 'btn btn-success' : !item.dateDeleted}"
                        
                          (click)="delete(item)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Is Active"
                          ><i  class="fa fa-fw fa-trash"></i
                        ></a>
                      </td>

                        <!-- {{item?.itemAmountSums.repairs}} -->
                        <td [hidden]="isPrint" *ngIf="i != sales.length - 1">
                          <a
                            class="btn btn-success"
                            (click)="navigateInvoice(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Order Detail"
                            ><i class="fa fa-fw fa-eye"></i
                          ></a>
                         
                        </td>

                      </tr>
                      <!--FOOTER OF GRID-->
                      <tr  align="right" class="border_top" *ngFor="let item of sales; let i=index; last as isLast">
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">Sub Totals:</td>
                        <td *ngIf="isLast && i !==0">{{item?.amountFinanced | number : '1.2'}}</td>

                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>

                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <!-- <td style="font-weight: bold;">{{getAmtTaxed3('amttaxed3') }}</td>
                        <td></td>
                        <td style="font-weight: bold;">{{getTax3()}}</td> -->
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                    </tr>
                  </tbody>
                    </table>
                  </div>
                  </div>
                  <!-- /.table-sec -->

                  <div class="customer-sidebar sidebar-with-btns col-md-1">
                    <div class="inner-sidebar">
                      <ul>
                        <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
                        <li >
                          <button  class="cstm-btn" (click) = "exportAsXLSX()">
                            <img src="assets/images/icons8-microsoft-excel-48.png" />
                            <span>Export Excel</span>
                          </button>
                        </li>
                        <li>
                          <button (click) = "printDiv()" class="cstm-btn">
                            <img src="assets/images/ap-icon-6.png" />
                            <span>Print Report</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
 
    </div>
  </div>
</div>
<iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>

