import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { CalculateFederalAprDto } from 'src/app/_models/DTOS/CalculateFederalAprDto';
import { CalculateTermDto } from 'src/app/_models/DTOS/CalculateTermDto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmoLoanService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

        getPaymentByTerm(calculateTermDto: CalculateTermDto): Observable<CalculateTermDto>{
          return this.http.put<CalculateTermDto>(this.baseUrl + 'amoloan/calculatebyterm/', calculateTermDto);
        }
        getDateMathForPaymentByTerm(calculateTermDto: CalculateTermDto): Observable<CalculateTermDto>{
          return this.http.put<CalculateTermDto>(this.baseUrl + 'amoloan/datemathforterm/', calculateTermDto);
        }
        saveAmortisation(saleId, parentGuid, amo: AmoAmortization[], loanId) {
          return this.http.put<AmoAmortization>(this.baseUrl + 'amoloan/saveamortisation/' + loanId + '/' + parentGuid + '/' +  saleId, amo);
        }
        getAmortisation(loanId): Observable<AmoAmortization[]> {
          return this.http.get<AmoAmortization[]>(this.baseUrl + 'amoloan/getamortization/' + loanId);
        }
        getFederalApr(loanDto): Observable<CalculateFederalAprDto> {
          return this.http.put<CalculateFederalAprDto>(this.baseUrl + 'amoloan/calculatefederalapr/', loanDto);
        }

        // getPaymentByTerm(calculateTermDto): Observable<CalculateTermDto> {
        //   return this.http.put<CalculateTermDto>(this.baseUrl + 'amoloan/calculatebyterm/', calculateTermDto);
        // }

        getTermByPaymentAndApr(calculateTermDto): Observable<CalculateTermDto> {
          return this.http.put<CalculateTermDto>(this.baseUrl + 'amoloan/calculategetterm/', calculateTermDto);
        }

        takePayment(calculateTermDto): Observable<any> {
          return this.http.put<any>(this.baseUrl + 'amoloan/takepayment/', calculateTermDto);
        }

        editPayment(calculateTermDto, id): Observable<any> {
          return this.http.put<any>(this.baseUrl + 'amoloan/editpayment/' + id, calculateTermDto);
        }
        getPaymentDueToday(calculateTermDto): Observable<any> {
          return this.http.put<any>(this.baseUrl + 'amoloan/preparepayment/', calculateTermDto);
        }

        calculateCurrentDues(calculateCurrentDueDto): Observable<any> {
          return this.http.get<any>(this.baseUrl + 'amoloan/findcurrentdues/', calculateCurrentDueDto);
        }
}
