
  <div class="customer-form-sec">
    <div class="container-fluid">
  <div class="row">
      <tabset class="member-tabset">
          <tab heading="Edit Personal">
    <div class="form-sec tab-content col-md-12">
     <div  class="card" style="background-color:#f0f0f0 !important;">
  
        <div class="col-sm-12">
            <div *ngIf="editForm.dirty" class="alert alert-info">
              <strong>Information:</strong> You have made changes.  Any unsaved changes will be lost!
            </div>
          </div>
       <form  #editForm="ngForm" id="editForm">
         <div class="row"> 
         <div class="col-8">  
              <div class="row">
          <!--FIRST NAME-->
                <div class="col-sm">
                  <div class="form-group" [ngClass]="{
                    'has-success': firstName?.valid && (firstName?.touched  || firstName?.dirty),
                    'has-danger': firstName?.invalid && (firstName?.touched  || firstName?.dirty)
                }">
                    <label>First Name</label>
                    <div class="input-group">
                    <input type="text" class="form-control" [ngModel]="person?.firstName" (ngModel)="person.firstName=$event" name="firstName"
                      #firstName="ngModel" required>
                      <div *ngIf="!firstName.errors && (firstName?.touched  || firstName?.dirty)" class="input-group-prepend">
                          <button type="button" class="btn btn-outline-success">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                        </div>
                      </div>
                      <div class="form-group-feedback" *ngIf="firstName.errors && (firstName?.touched  || firstName?.dirty)">
                          <p style="color:red" *ngIf="firstName?.errors.required">First Name Is Required</p>
                        </div>
                  </div>
                </div>   
                
                <!--last NAME-->
                <div class="col-sm">
                    <div class="form-group" [ngClass]="{
                      'has-success': lastName?.valid && (lastName?.touched  || lastName?.dirty),
                      'has-danger': lastName?.invalid && (lastName?.touched  || lastName?.dirty)
                  }">
                      <label>Last Name</label>
                      <div class="input-group">
                      <input type="text" class="form-control" [ngModel]="person?.lastName" (ngModel)="person.lastName=$event" name="lastName"
                        #lastName="ngModel" required>
                        <div *ngIf="!lastName.errors && (lastName?.touched  || lastName?.dirty)" class="input-group-prepend">
                            <button type="button" class="btn btn-outline-success">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </button>
                          </div>
                        </div>
                        <div class="form-group-feedback" *ngIf="lastName.errors && (lastName?.touched  || lastName?.dirty)">
                            <p style="color:red" *ngIf="lastName?.errors.required">Last Name Is Required</p>
                          </div>
                    </div>
                  </div>     
              </div>
  
              <div class="row">
                 <!--PHONE-->
                <div class="col">
                  <div class="form-group" [ngClass]="{
                                      'has-success': phone?.valid && (phone?.touched  || phone?.dirty),
                                      'has-danger': phone?.invalid && (phone?.touched  || phone?.dirty)
                                  }">
                    <label>Phone</label>
                    <div class="input-group"> 
                    <input type="text" class="form-control" [ngModel]="person?.phone" (ngModel)="person.phone=$event" name="phone"
                      #phone="ngModel" required>
                      <div *ngIf="!phone.errors && (phone?.touched  || phone?.dirty)" class="input-group-prepend">
                          <button type="button" class="btn btn-outline-success">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                        </div>
                      </div>
                    <div class="form-group-feedback" *ngIf="phone.errors && (phone?.touched  || phone?.dirty)">
                      <p style="color:red" *ngIf="phone?.errors.required">Phone Is Required</p>
        
                    </div>
                  </div>
                </div>
        <!--eMail-->
                <div class="col">
                    <div class="form-group" [ngClass]="{
                                        'has-success': email?.valid && (email?.touched  || email?.dirty),
                                        'has-danger': email?.invalid && (email?.touched  || email?.dirty)
                                    }">
                      <label>Email</label>
                      <div class="input-group"> 
                      <input type="text" class="form-control" [ngModel]="person?.email" (ngModel)="person.email=$event" name="email"
                        #email="ngModel" required>
                        <div *ngIf="!email.errors && (email?.touched  || email?.dirty)" class="input-group-prepend">
                            <button type="button" class="btn btn-outline-success">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </button>
                          </div>
                        </div>
                      <div class="form-group-feedback" *ngIf="email.errors && (email?.touched  || email?.dirty)">
                        <p style="color:red" *ngIf="email?.errors.required">Email Is Required</p>
          
                      </div>
                    </div>
                  </div>
              </div>
  
              <fieldset ngModelGroup="addressDetail">
                <div class="form-group" [ngClass]="{
                                          'has-success': street?.valid && (street?.touched  || street?.dirty),
                                          'has-danger': street?.invalid && (street?.touched  || street?.dirty)
                                      }">
                  <label>Street</label>
                  <div class="input-group">
                  <input type="text" class="form-control" [ngModel]="person?.addressDetail?.street" (ngModel)="person.addressDetail.street=$event"
                    name="street" #street="ngModel" required>
                    <div *ngIf="!street.errors && (street?.touched  || street?.dirty)" class="input-group-prepend">
                        <button type="button" class="btn btn-outline-success">
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </button>
                      </div>
                    </div>
                  <div class="form-group-feedback" *ngIf="street.errors && (street?.touched  || street?.dirty)">
                    <p style="color:red" *ngIf="street?.errors.required">Street Is Required</p>
        
                  </div>
                </div>
        
                <div class="row">
                  <div class="col">
                    <div class="form-group" [ngClass]="{
                                          'has-success': street?.valid && (street?.touched  || street?.dirty),
                                          'has-danger': street?.invalid && (street?.touched  || street?.dirty)
                                      }">
                      <label>City</label>
                      <div class="input-group"> 
                      <input type="text" class="form-control" [ngModel]="person?.addressDetail?.city" (ngModel)="person.addressDetail.city=$event"
                        name="city" #city="ngModel" required>
                        <div *ngIf="!city.errors && (city?.touched  || city?.dirty)" class="input-group-prepend">
                            <button type="button" class="btn btn-outline-success">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </button>
                          </div>
                        </div>
                      <div class="form-group-feedback" *ngIf="city.errors && (city?.touched  || city?.dirty)">
                        <p style="color:red" *ngIf="city?.errors.required">City Is Required</p>
        
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group" [ngClass]="{
                                          'has-success': state?.valid && (state?.touched  || state?.dirty),
                                          'has-danger': state?.invalid && (state?.touched  || state?.dirty)
                                      }">
                      <label>State</label>
                      <div class="input-group">
                      <input type="text" class="form-control" [ngModel]="person?.addressDetail?.state" (ngModel)="person.addressDetail.state=$event"
                        name="state" #state="ngModel" required>
                        <div *ngIf="!state.errors && (state?.touched  || state?.dirty)" class="input-group-prepend">
                            <button type="button" class="btn btn-outline-success">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </button>
                          </div>
                        </div>
                      <div class="form-group-feedback" *ngIf="state.errors && (state?.touched  || state?.dirty)">
                        <p style="color:red" *ngIf="state?.errors.required">State Is Required</p>
        
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group" [ngClass]="{
                                          'has-success': zip?.valid && (zip?.touched  || zip?.dirty),
                                          'has-danger': zip?.invalid && (zip?.touched  || zip?.dirty)
                                      }">
                      <label>Zip</label>
                      <div class="input-group">
                      <input type="text" class="form-control" [ngModel]="person?.addressDetail?.zip" (ngModel)="person.addressDetail.zip=$event"
                        name="zip" #zip="ngModel" minlength="2" required>
                        <div *ngIf="!zip.errors && (zip?.touched  || zip?.dirty)" class="input-group-prepend">
                        <button type="button" class="btn btn-outline-success">
                            <i class="fa fa-check" aria-hidden="true"></i>
                          </button>
                      </div>
                    </div>
                      <div class="form-group-feedback" *ngIf="zip.errors && (zip?.touched  || zip?.dirty)">
                        <p style="color:red" *ngIf="zip?.errors.required">Zip Is Required</p>
                        <p style="color:red" *ngIf="zip?.errors.minlength">Zip requires at least 2 characters</p>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group" [ngClass]="{
                                          'has-success': county?.valid && (county?.touched  || county?.dirty),
                                          'has-danger': county?.invalid && (county?.touched  || county?.dirty)
                                      }">
                      <label>County</label>
                      <div class="input-group"> 
                      <input type="text" class="form-control" [ngModel]="person?.addressDetail?.county" (ngModel)="person.addressDetail.county=$event"
                        name="county" #county="ngModel" required>
                        <div *ngIf="!county.errors && (county?.touched  || county?.dirty)" class="input-group-prepend">
                            <button type="button" class="btn btn-outline-success">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </button>
                          </div>
                        </div>
                      <div class="form-group-feedback" *ngIf="county.errors && (county?.touched  || county?.dirty)">
                        <p style="color:red" *ngIf="county?.errors.required">County Is Required</p>
                      </div>
                    </div>
                  </div>
        
                  <div class="col">
 
                  </div>
                </div>
              </fieldset>
            </div>
  
            <div  class="col-4">
                <!-- More info -->
                <!--Middle Name-->
                <div class="col-sm">
                    <div class="form-group" [ngClass]="{
                      'has-success': middleName?.valid && (middleName?.touched  || middleName?.dirty),
                      'has-danger': middleName?.invalid && (lastName?.touched  || middleName?.dirty)
                  }">
                      <label>Middle Name</label>
                      <div class="input-group">
                      <input type="text" class="form-control" [ngModel]="person?.middleName" (ngModel)="person.middleName=$event" name="middleName"
                        #middleName="ngModel" required>
                        <div *ngIf="!middleName.errors && (middleName?.touched  || middleName?.dirty)" class="input-group-prepend">
                            <button type="button" class="btn btn-outline-success">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </button>
                          </div>
                        </div>
                        <div class="form-group-feedback" *ngIf="middleName.errors && (middleName?.touched  || middleName?.dirty)">
                            <p style="color:red" *ngIf="middleName?.errors.required">Middle Name Is Required</p>
                          </div>
                    </div>
                  </div>     
                           <!-- Date Of Birth -->
                          <div class="form-group" [ngClass]="{
                                               'has-success': dateOfBirth?.valid && (dateOfBirth?.touched  || dateOfBirth?.dirty),
                                               'has-danger': dateOfBirth?.invalid && (dateOfBirth?.touched  || dateOfBirth?.dirty)
                                           }">
                                                       <label>Date Of Birth</label>
                                                       <div class="input-group"> 
                                                       <input type="date" class="form-control" [ngModel]="person?.dateOfBirth | date: 'yyyy-MM-dd'"  (ngModel)="person.dateOfBirth=$event"
                                                       name="dateOfBirth" #dateOfBirth="ngModel" >
                                                       <div *ngIf="!dateOfBirth.errors && (dateOfBirth?.touched  || dateOfBirth?.dirty)" class="input-group-prepend">
                                                       <button type="button" class="btn btn-outline-success">
                                                       <i class="fa fa-check" aria-hidden="true"></i>
                                                       </button>
                                                       </div>
                                                       </div>
                                                       
                          </div>
                           <!-- INSURANCE Policy # -->
                           <div class="form-group" [ngClass]="{
                            'has-success': insuranceExpires?.valid && (insuranceExpires?.touched  || insuranceExpires?.dirty),
                            'has-danger': insuranceExpires?.invalid && (insuranceExpires?.touched  || insuranceExpires?.dirty)
                        }">
                                    <label>Insurance Policy #</label>
                                    <div class="input-group"> 
                                    <input type="text" class="form-control" [ngModel]="person?.insuranceExpires " (ngModel)="person.insuranceExpires=$event"
                                    name="insuranceExpires" #insuranceExpires="ngModel" >
                                    <div *ngIf="!insuranceExpires.errors && (insuranceExpires?.touched  || insuranceExpires?.dirty)" class="input-group-prepend">
                                    <button type="button" class="btn btn-outline-success">
                                    <i class="fa fa-check" aria-hidden="true"></i>
                                    </button>
                                    </div>
                                    </div>
                                    
                            </div>
                            <!--Insurance Expiration-->
                            <div class="form-group" [ngClass]="{
                              'has-success': insuranceExpires?.valid && (insuranceExpires.touched  || insuranceExpires?.dirty),
                              'has-danger': insuranceExpires?.invalid && (insuranceExpires?.touched  || insuranceExpires?.dirty)
                          }">
                                      <label>Insurance Expiration </label>
                                      <div class="input-group"> 
                                      <input type="date" class="form-control" [ngModel]="person?.insuranceExpires | date: 'yyyy-MM-dd'" (ngModel)="person.insuranceExpires=$event"
                                      name="insuranceExpires" #insuranceExpires="ngModel" >
                                      <div *ngIf="!insuranceExpires.errors && (insuranceExpires?.touched  || insuranceExpires?.dirty)" class="input-group-prepend">
                                      <button type="button" class="btn btn-outline-success">
                                      <i class="fa fa-check" aria-hidden="true"></i>
                                      </button>
                                      </div>
                                      </div> 
                              </div>
                   <!-- end col 4--->                                
             </div>
  
    
    </div>
    </form>
     </div>
    </div>
  </tab>
  <tab heading="Avatar">
      <div class="col-md-12">
          <div class="container">
              <div class="row">
                <div class="col-sm-12 img-wrapper" *ngFor="let photo of photos">
                  <img src="{{person.photoUrl}}" class="img-thumbnail p-1" alt="">
              
                  <div class="text-center img-text" >
                      <span class="text-danger">Awaiting approval</span>
                  </div>
              
                  <div class="text-center">
                    <button type="button" class="btn btn-sm mr-1"
                      [ngClass]="photo.isMain ? 'btn-success active' : 'btn-secondary'"
                    >Main</button>
                    <button type="button" class="btn btn-sm btn-danger"
                      (click) = "deletePhoto(photo.id)"><i class="fa fa-trash-o"></i></button>
                  </div>
                </div>
              </div>
              
              <div class="row mt-2">
              
                  <div class="col-md-3">
              
                      <h3>Add Photos</h3>
              
                      <div ng2FileDrop
                           [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                           (fileOver)="fileOverBase($event)"
                           [uploader]="uploader"
                           class="card bg-faded p-3 text-center mb-3 my-drop-zone">
                           <i class="fa fa-upload fa-3x"></i>
                          Drop Photos Here
                      </div>
              
                      Multiple
                      <input type="file" ng2FileSelect [uploader]="uploader" multiple  /><br/>
              
                      Single
                      <input type="file" ng2FileSelect [uploader]="uploader" />
                  </div>
              
                  <div class="col-md-9" style="margin-bottom: 40px" *ngIf="uploader?.queue?.length">
              
                      <h3>Upload queue</h3>
                      <p>Queue length: {{ uploader?.queue?.length }}</p>
              
                      <table class="table">
                          <thead>
                          <tr>
                              <th width="50%">Name</th>
                              <th>Size</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let item of uploader.queue">
                              <td><strong>{{ item?.file?.name }}</strong></td>
                              <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                          </tr>
                          </tbody>
                      </table>
              
                      <div>
                          <div>
                              Queue progress:
                              <div class="progress mb-4">
                                  <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                              </div>
                          </div>
                          <button type="button" class="btn btn-success btn-s"
                                  (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                              <span class="fa fa-upload"></span> Upload
                          </button>
                          <button type="button" class="btn btn-warning btn-s"
                                  (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                              <span class="fa fa-ban"></span> Cancel
                          </button>
                          <button type="button" class="btn btn-danger btn-s"
                                  (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                              <span class="fa fa-trash"></span> Remove
                          </button>
                      </div>
              
                  </div>
              
              </div>
              </div>
    </div>
  </tab>
  </tabset>

    </div>  
    </div>
    </div>

