import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from '../_guards/admin.guard';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';




const routes: Routes = [
    // {path: 'vehiclelist/:parrentGuid', component: InventoryGridComponent},
    // {path: 'addvehicle/:parrentGuid', component: InventoryDeskComponent},
    {path: 'admin', component: AdminPanelComponent, canActivate: [AdminGuard]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule {}

