
<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-11">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">          
                
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <div id="printableTable">
<table border="1" borderColor="#ccc">
  <tr >
      <td></td>
      <td></td>
      <td>Pmt #</td>
      <td>Date Paid</td>
      <td>Today Paid</td>
      <td>Deferred Today</td>
      <td>Late Fee</td>
      <td>Principle</td>
      <td>Interest</td>
      <td>Taxes</td>
      <td>Balance</td>
      <td>Edit</td>
  </tr>
  <tr  (dblclick)="editItem(data)" align="right" class="td-row" *ngFor="let data of sale.amoLoans.amoPayments; let i=index" >
      <td></td>
      <td></td>
    <td> {{ data.paymentNumber}} </td>
    <td> {{ data.datePaid | date: "shortDate" }} </td>
    <td> {{ data.sumPaidToday | number:'1.2-2' }} </td>
    <td> {{ data.todayDeferredDown | number: '1.2-2' }}</td>
    <td> {{ data.todayLateFee | number:'1.2-2' }} </td>
    <td> {{ data.todayPrinciple | number:'1.2-2' }} </td>
    <td> {{ data.todayInterest | number:'1.2-2' }} </td>
    <td> {{ data.todayNonAmortizedPaid | number:'1.2-2' }} </td>
    <td> {{ data.principleBalance | number:'1.2-2' }} </td>
    <td> {{ data.principleBalance | number:'1.2-2' }} </td>   <td  align="left" style="cursor: pointer;" (click)="editPayment(data)"><i style="color: rgb(0, 184, 0);" class="fa fa-plus"></i></td>

  </tr>
</table>
</div>
</div>
<!-- /.table-sec -->
</div>
</div>
</div>
</div>
<!-- /.row -->
</div>
</div>



<div class="customer-sidebar sidebar-with-btns col-md-1">
  <div class="inner-sidebar">
    <ul>
<!-- <li>
  <button class="cstm-btn img-blk-btn">
    <img src="assets/images/ap-icon-1.png" />
    <span>Add Vehicle</span>
  </button>
</li> -->
<li *appHasRole="['Admin']">
  <button class="cstm-btn" (click) = "exportAsXLSX()">
    <img src="assets/images/icons8-microsoft-excel-48.png" />
    <span>Export Excel</span> 
  </button>
</li>
<li>
  <button (click) = "printDiv()" class="cstm-btn">
    <img src="assets/images/ap-icon-6.png" />
    <span>Print Report</span>
  </button>
</li>
    </ul>
  </div>
</div>
</div>
</div>
</div>
<iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>