
  
  <!-- <div class="customer-header" (contextmenu)="onRightClick($event)"> -->


    <!-- *ngIf="navService.visible === true" -->
    <div  class="customer-header">
    <nav  class="navbar navbar-expand-lg navbar-light customer-navbar" style="position:fixed;  left:5px; top:0px; z-index: 800; width: 100%;" >
      <img src="assets/images/menu-icon.jpg">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div  class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item"><a></a></li>
           <!--LIST-->
           <li  *ngIf="loggedIn()"  class="nav-item dropdown">
            <div  *appHasRole="['Admin']" class="dropdown" dropdown>
                <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                 List
                </a>
                <div style="background-color: rgb(228, 245, 225) !important;" class="dropdown-menu" *dropdownMenu> 
                  <a class="dropdown-item" [routerLink]="['/seedcompany']" ><i class="fa fa-sign-out"></i>&nbsp; Data Seeds</a>
                  <div class="dropdown-divider"></div>                
                  <a class="dropdown-item" [routerLink]="['/list/status', selectedCompany1.parentGuid]"><i class="fa fa-sign-out"></i>&nbsp; Sale Status</a>
                  <a class="dropdown-item" [routerLink]="['/list/saletypes', selectedCompany1.parentGuid]"><i class="fa fa-sign-out"></i>&nbsp; Sale Types</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" [routerLink]="['/list/paymenttypegrid', selectedCompany1.parentGuid]"><i class="fa fa-user"></i>&nbsp; Payment Types</a>
                  <!-- <a class="dropdown-item" [routerLink]="['/list/paymentmethodgrid', selectedCompany1.parentGuid]"><i class="fa fa-user"></i>&nbsp; Payment Methods</a> -->
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" [routerLink]="['/list/vehiclemileagecodes', selectedCompany1.parentGuid]"><i class="fa fa-user"></i>&nbsp; Mileage Codes</a>
                  <a class="dropdown-item" [routerLink]="['/list/vehiclebodytypes', selectedCompany1.parentGuid]"><i class="fa fa-sign-out"></i>&nbsp; Vehicle Category/Body-Types</a>
                  <!-- <div class="dropdown-divider"></div> -->
                  <!-- <a class="dropdown-item" [routerLink]="['/list/chartofaccountgrid', selectedCompany1.parentGuid]" ><i class="fa fa-sign-out"></i>&nbsp; Chart Accounts</a> -->
                  <div class="dropdown-divider"></div>
                  <!-- <a class="dropdown-item" [routerLink]="['/list/formnamelist', selectedCompany1.parentGuid]"><i class="fa fa-user"></i>&nbsp; Label List</a>
                  <div class="dropdown-divider"></div> -->
                  <!-- <a class="dropdown-item" [routerLink]="['/list/companydepartmenttypes', selectedCompany1.parentGuid]"><i class="fa fa-user"></i>&nbsp; Company Department Types</a> -->
                </div>
              </div>
        </li>  
          
          <!--sales-->
         <li *ngIf="loggedIn()" class="nav-item dropdown">
            <div *ngIf="loggedIn()" class="dropdown" dropdown>
              <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                Sales
              </a>
                <div class="dropdown-menu" *dropdownMenu>
                  <a class="dropdown-item" (click) = "saleGridChange('salesman')"><i class="fa fa-user"></i>&nbsp; Sales</a>
                  <div class="dropdown-divider"></div>
                  <a *appHasRole="['Admin']" class="dropdown-item" (click) = "saleGridChange('salesjournal')"><i class="fa fa-user"></i>&nbsp; Sales Journal</a>
                  <a *appHasRole="['Admin']" class="dropdown-item" (click) = "saleGridChange('advancetax')"><i class="fa fa-user"></i>&nbsp; Sales Tax</a>
                </div>
              </div>
            </li>
            <!--INVENtoRY-->
          <li class="nav-item dropdown">
              <div *ngIf="loggedIn()"  class="dropdown" dropdown>
                  <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                    Inventory
                  </a>
                  <div style="background-color: rgb(228, 245, 225) !important;" class="dropdown-menu" *dropdownMenu>
                    <a class="dropdown-item" *ngIf="loggedIn()" [routerLink]="['buyersguide/home', selectedCompany1?.parentGuid]"><i class="fa fa-sign-out"></i>&nbsp; Buyers Guide</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item"  [routerLink]="['vehicledashboard/productlist/', selectedCompany1?.parentGuid]"><i class="fa fa-user" ></i>&nbsp; Advertising</a>
                    <a *appHasRole="['Admin']" class="dropdown-item"  [routerLink]="['vehicledashboard/aging/', selectedCompany1?.parentGuid]"><i class="fa fa-user" ></i>&nbsp; Aging List</a>
                    <!-- <a *appHasRole="['Admin']" class="dropdown-item"  [routerLink]="['productlist/vit', selectedCompany1?.parentGuid]"><i class="fa fa-user" ></i>&nbsp; VIT TAX</a>
                    <a class="dropdown-item" (click) = "inventory('sold')"><i class="fa fa-user"></i>&nbsp; Sold</a> -->
                    <!-- <a class="dropdown-item" (click) = "inventory('publishedSold')"><i class="fa fa-user"></i>&nbsp; Published Sold</a>
                    <a class="dropdown-item" (click) = "inventory('active')"><i class="fa fa-user"></i>&nbsp; Active</a>
                    <a class="dropdown-item" (click) = "inventory('nonPublishedActive')"><i class="fa fa-user"></i>&nbsp; Non Published Active</a>
                    <a class="dropdown-item" (click) = "inventory('published')"><i class="fa fa-user"></i>&nbsp;All Published</a>
                    <a class="dropdown-item" (click) = "inventory('publishedneedsimages')"><i class="fa fa-user"></i>&nbsp; Published Need Images</a>
                   
                    <a class="dropdown-item" (click) = "inventory('nonpublishedactive')"><i class="fa fa-user"></i>&nbsp; Non Published Active</a> -->
                    <div class="dropdown-divider"></div>
                    <!-- <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Inventory Defaults</a>
                    <div class="dropdown-divider"></div>                
                    <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Help</a> -->
                  </div>
                </div>
          </li>
          <!-- <li *ngIf="loggedIn()" class="nav-item dropdown">
              <div *ngIf="loggedIn()" class="dropdown" dropdown>
                  <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                    Tools
                  </a>

                  <div style="background-color: rgb(228, 245, 225) !important;" class="dropdown-menu" *dropdownMenu>
                    <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; View All</a>
                    <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Active</a>
                    <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Sold</a>
                    <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Published</a>
                    <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Non Published Active</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Inventory Defaults</a>
                    <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Help</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Compose Buyers Guide</a>
                  </div>
                  <div style="background-color: rgb(228, 245, 225) !important;" class="dropdown-menu" *dropdownMenu>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; View All</a>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Active</a>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Sold</a>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Published</a>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Non Published Active</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Inventory Defaults</a>
                      <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Help</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Compose Buyers Guide</a>
                    </div>
                </div>
          </li> -->
          <!-- <li *ngIf="loggedIn()" class="nav-item"><a class="nav-link" > Window</a></li>
          <li *ngIf="loggedIn()" class="nav-item"><a class="nav-link" > Help</a></li>
          <li *ngIf="loggedIn()" class="nav-item"><a class="nav-link" > New User</a></li> -->
          <!-- <li *ngIf="loggedIn()" class="nav-item"><a class="nav-link" >Order Now</a></li> -->
          <li class="nav-item dropdown">
            <div *ngIf="loggedIn()"  class="dropdown" dropdown>
                <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                  Videos
                </a>
          <div class="dropdown-menu" *dropdownMenu>
            <!-- <a class="dropdown-item" ><i class="fa fa-user"></i>&nbsp; Sales</a> -->
            <div class="dropdown-divider"></div>
            <a *appHasRole="['Admin']" class="dropdown-item" [routerLink]="['video/list']" ><i class="fa fa-user"></i>&nbsp;  Video List</a>
          </div>
          
        </div>
          </li>
        </ul>
        <form *ngIf="!loggedIn() && !isPasswordReset" #loginForm="ngForm" class="form-inline my-2 my-lg-0" (ngSubmit)="login()">
            <input class="form-control mr-sm-2" type="text" name="email" placeholder="Enter Email" required [(ngModel)]="model.email">
            <input class="form-control mr-sm-2" type="password" name="password" placeholder="Password" required [(ngModel)]="model.password">
            <button [disabled]="!loginForm.valid" class="btn btn-success my-2 my-sm-0" type="submit">Login</button>
           
          </form>
          <form *ngIf="!loggedIn() && isPasswordReset" #passwordForm="ngForm" class="form-inline my-2 my-lg-0" >
            <input class="form-control mr-sm-2" type="text" name="email" placeholder="Enter Email" required [(ngModel)]="emailStartPwReset.email">
           
          </form>
          <div *ngIf="!loggedIn() && !isPasswordReset" class="form-inline my-2 my-lg-0" >
            <button  class="btn btn-success my-2 my-sm-0"  (click)="onPasswordRest()"   type="submit">Reset Password</button>
          </div>
          <div *ngIf="!loggedIn() && isPasswordReset" class="form-inline my-2 my-lg-0" >
            <button  class="btn btn-success my-2 my-sm-0" (click) = "onRequestNewPassword()" type="submit">Request Password Reset</button>
            <button  class="btn btn-alert my-2 my-sm-0" (click) = "onPasswordRest()" type="submit">Cancel</button>
          </div>
          <!-- *ngIf="isNewSupport"  -->
          <span [class.iso-nline] = "(presence.onlineUsers$ | async).includes(member?.knownAs)" class="float: right; margin-right: 15;" style="cursor: pointer; margin-right: 10px;" >
            <i class="fa fa-heart" style="font-size:28px;color:rgb(234, 245, 236)"></i>
          </span>
          <div style="float: right; margin-right: 5;"  *ngIf="loggedIn()" class="dropdown mr-2" dropdown>
           
              <span class="mr-1">
                <img class="imgavatar" src="{{photoUrl || '../../assets/user.png'}}" alt="">
              </span>
              <a class="dropdown-toggle text-light" dropdownToggle>
                Welcome {{user?.knownAs| titlecase}} you are working on
              </a>
        
              <div class="dropdown-menu mt-3" *dropdownMenu>
                <a class="dropdown-item" [routerLink]="['/member/edit']">
                  <i class="fa fa-user"></i> Edit Profile</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()">
                  <i class="fa fa-sign-out"></i> Logout</a>
              </div>
            </div>
            <div style="float: right; margin-right: 5;" *ngIf="loggedIn()" class="dropdown" dropdown>
              <a *ngIf="selectedCompany1" class="dropdown-toggle text-light" dropdownToggle>
                Selected company: {{selectedCompany1?.companyName}}
              </a>
              <div class="dropdown-menu mt-3" *dropdownMenu >
                <div *ngFor="let userAssignedToCompanys of userAssignedToCompany">
                <a (click)="selectedCompany(userAssignedToCompanys)"  class="dropdown-item" >
                  <i class="fa fa-edit"></i>&nbsp;{{userAssignedToCompanys?.companyName}}</a>
                </div>
              </div>
            </div>
      </div>
 
    </nav>


    <div style="position: fixed;  left:5px; top:45px;" class="icons-header ">
      <div class="container-fluid">
        <ul class="main-menu">
          <li [class.active] = "isSales" >
            <a   (click)="isClick('sales')" *ngIf="loggedIn()" [routerLink]="['saledashboard/saledashboard', selectedCompany1?.parentGuid]" href="javascript:void(0)">
              <img src="assets/images/icon-2.jpg">
              <span>Sales</span>
            </a>
          </li>
          <li [class.active] = "isAddVehicle">
						<a href="javascript:void(0)" (click) = "clickAddCar()"  *ngIf="loggedIn()" >
							<img src="assets/images/icon-4.jpg">
							<span>Add Item</span>
						</a>
					</li>
          <li [class.active] = "isInventory" >
            <a (click)="isClick('isInventory')" *ngIf="loggedIn()" [routerLink]="['vehicledashboard/productlist', selectedCompany1?.parentGuid]"  href="javascript:void(0)">
              <img src="assets/images/icon-5.jpg">
              <span>Inventory</span>
            </a>
          </li>
          <!-- <li [class.active] = "isBuyersGuid">
            <a *ngIf="loggedIn()" (click) = "clickBuyersGuid()" data-toggle="modal" data-target="#buyer-guide-model">
              <img src="assets/images/icon-7.jpg">
              <span>Buyer Guide</span>
            </a>
          </li> -->
          <!-- <li [class.active] = "isService" *ngIf="loggedIn()" >
            <a *appHasRole="['Admin', 'Realestate']" (click)="isClick('isService')" [ngClass]="isService ? 'acitve' : ''"  [routerLink]="['service/servicegrid']"  href="javascript:void(0)" >
              <img src="assets/images/realestate.jpg">
              <span>Service</span>
            </a>
          </li> -->
          <li *ngIf="loggedIn()" [class.active] = "isAdvertising"  >
              <a  *appHasRole="['Admin', 'Website']" (click) = "clickAdvertising()" [ngClass]="isAdvertising ? 'acitve' : ''"   href="javascript:void(0)">
                <img src="assets/images/web.png">
                <span>Advertising</span>
              </a>
            </li>
          <!-- <li *ngIf="loggedIn()" [class.active] = "isCredit">
            <a (click)="isClick('isCredit')" *ngIf="loggedIn()" href="javascript:void(0)" [routerLink]="['creditbureaus', selectedCompany1?.parentGuid]"  >
              <img src="assets/images/icon-6.jpg">
              <span>Credit</span>
            </a>
          </li>
          
           
          <li>
              <li *ngIf="loggedIn()" (click)="isClick('isRealEstate')" [class.active] = "isRealEstate">
                  <a *appHasRole="['Admin', 'Realestate']"   [routerLink]="['realestate/list', selectedCompany1?.parentGuid]"  href="javascript:void(0)" >
                    <img src="assets/images/realestate.jpg">
                    <span>Real Estate</span>
                  </a>
                </li> -->
            
          <li *ngIf="loggedIn()" [class.active] = "isReports"> 
            <a (click)="isClick('isReports')"   [routerLink]="['/reportsmanager/reportsmanager', selectedCompany1?.parentGuid]"  *ngIf="loggedIn()" href="javascript:void(0)">
              <img src="assets/images/icon-8.jpg">
              <span>Forms</span>
            </a>
          </li>
          <!-- <li *ngIf="loggedIn()" [class.active] = "isAccounting">
            <a (click)="isClick('isAccounting')" *appHasRole="['Admin']"   [routerLink]="['/accountingdashboard/generalledger', selectedCompany1?.parentGuid]"  href="javascript:void(0)">
              <img src="assets/images/icon-9.jpg">
              <span>Reports</span>
            </a>
          </li> -->
          <li *ngIf="loggedIn()" [class.active] = "isPhoneBook">
            <a (click)="isClick('isPhoneBook')" *ngIf="loggedIn()" [routerLink]="['/dashboardpeople/list/', selectedCompany1?.parentGuid]"  href="javascript:void(0)">
              <img src="assets/images/icon-10.jpg">
              <span>People</span>
            </a>
          </li>
          <!-- addPaymentModal -->
          <!-- <li *ngIf="loggedIn()"  [class.active] = "isPayments">
            <a  data-toggle="modal" data-target="#paymentScreenModal" (click)="isClick('isPayments')" *ngIf="loggedIn()"  href="javascript:void(0)">
              <img src="assets/images/icon-11.jpg">
              <span>Payments</span>
            </a>
          </li> -->
          <li  [class.active] = "isLoan">
            <a (click)="isClick('isLoan')" *ngIf="loggedIn()" [routerLink]="['dashboardloans/loanlist/', selectedCompany1?.parentGuid]" href="javascript:void(0)">
              <img src="assets/images/icon-14.jpg">
              <span>Recievables</span>
            </a>
          </li>
         
          <!-- <li  [class.active] = "isCalculator">
            <a (click)="isClick('isCalculator')" *ngIf="loggedIn()" href="javascript:void(0)" (click)="openCalculator()">
              <img src="assets/images/icon-13.jpg">
              <span>Payables</span>
            </a>
          </li> -->
          <!-- <li  [class.active] = "isCalculator">
            <a (click)="isClick('isCalculator')" *ngIf="loggedIn()" href="javascript:void(0)" (click)="openCalculator()">
              <img src="assets/images/icon-13.jpg">
              <span>POS</span>
            </a>
          </li> -->
          <li [class.active] = "isSupport" *ngIf="loggedIn()">
              <a (click)="isClick('isSupport')" [routerLink]="['/supportdashboard/supporttickets']"  href="javascript:void(0)">
                <img src="assets/images/icon-14.jpg">
                <span>Support</span>
              </a>
            </li>
          <li [class.active] = "isAdmin" *ngIf="loggedIn()" >
              <a (click)="isClick('isAdmin')" *appHasRole="['Admin']"  [routerLink]="['/admindashboard/admin']"  href="javascript:void(0)">
                <img src="assets/images/icon-14.jpg">
                <span>Admin</span>
              </a>
            </li>
            <li [class.active] = "isCompanyProfile" *ngIf="loggedIn()" >
              <a (click)="isClick('isCompanyProfile')" *appHasRole="['Admin']"  [routerLink]="['companyprofiledashboard/home/', selectedCompany1?.parentGuid]"  href="javascript:void(0)">
                <img src="assets/images/icon-14.jpg">
                <span>Company</span>
              </a>
            </li>
            <li *ngIf="loggedIn()" style="margin-left: 20px;">
                <a (click)="logout()" href="javascript:void(0)">
                  <img src="assets/images/icon-14.jpg">
                  <span>LogOut</span>
                </a>
              </li>
        </ul>
      </div>

    </div>


       <div *ngIf="isRoute != undefined && isRoute.includes('admin2')" class="container-fluid customer-tabs-sec">
        <ul class="nav nav-tabs">
          <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   routerLink='admin/dashboard' >Home Admin</a></li>
          <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" routerLink='admin/listcomp' >Companies</a></li> 
          <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" routerLink='admin/userlist' >User Info</a></li>
          <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" routerLink='admin/rolemanager' >Permissions</a></li>
        </ul>
      </div>
      <div *ngIf="isRoute != undefined && isRoute.includes('estatedashboard')" class="container-fluid customer-tabs-sec">
          <ul class="nav nav-tabs">
            <!-- <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   routerLink='compedit/edit/company.id' >Edit Company</a></li> -->
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" [routerLink]="['editweb/companyinfo/', selectedCompany1.parentGuid]"   >Company Info</a></li>
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" [routerLink]="['editweb/socialmedia/', selectedCompany1.parentGuid]" >Projections</a></li>
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" routerLink='editweb/content' >Contribution Shares</a></li>
          </ul>
        </div>
      <div *ngIf="isRoute != undefined && isRoute.includes('editweb')" class="container-fluid customer-tabs-sec">
        <ul class="nav nav-tabs">
          <!-- <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   routerLink='compedit/edit/company.id' >Edit Company</a></li> -->
          <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" [routerLink]="['editweb/companyinfo/', selectedCompany1.parentGuid]"   >Company Info</a></li>
          <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" [routerLink]="['editweb/socialmedia/', selectedCompany1.parentGuid]" >Website Info</a></li>
          <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" routerLink='editweb/content' >Content</a></li>
        </ul>
      </div>
      <div *ngIf="isRoute != undefined && isRoute.includes('compedit')" class="container-fluid customer-tabs-sec">
          <ul class="nav nav-tabs">
            <!-- <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   routerLink='compedit/edit/company.id' >Edit Company</a></li> -->
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" routerLink='admin/listcomp' >Defaults</a></li>
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" routerLink='admin/userlist' >User List</a></li>
          </ul>
        </div>
      <div *ngIf="isRoute != undefined && isRoute.includes('realestate')" class="container-fluid customer-tabs-sec">
          <ul class="nav nav-tabs">
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   routerLink='realestate/estateadd' >House Details</a></li>
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" routerLink='realestate/estaterepairs' >House Repairs</a></li>
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" routerLink='realestate/estatehouseincome' >Rental Income</a></li>
          </ul>
        </div> 
  </div> 
  
  <div *ngIf="isRoute != undefined && !isRoute.includes('productlist') " class="xtra-div">

  <!-- <mat-progress-bar style="width: 100%; min-height: 15px; color: blue;" color="accent" *ngIf="loading"  mode="indeterminate"></mat-progress-bar> -->
</div>

<div class="modal fade" id="buyer-guide-model" role="dialog" tabindex="-1">
  <app-inventory-buyersguide></app-inventory-buyersguide>
</div>
<div class="modal fade" id="paymentScreenModal" role="dialog" tabindex="-1">
<!-- <app-loan-payments></app-loan-payments> -->
</div>
