import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StoreHour } from 'src/app/_models/StoreHour';
import { Observable } from 'rxjs';
import { ServiceOffer } from 'src/app/_models/serviceoffer';
import { Website } from 'src/app/_models/website';
import { SubServiceOffer } from 'src/app/_models/websites/SubServiceOffer';

@Injectable({
  providedIn: 'root'
})
export class ServiceOffersService {
  baseUrl = environment.apiUrl;
  companyId: number;
constructor(private http: HttpClient) { }

add(entity: ServiceOffer): Observable<Website> {
    return this.http.post<Website>(this.baseUrl + 'websiteserviceoffers/postserviceoffer/', entity);
  }
  addSubServiceOffer(entity: SubServiceOffer): Observable<Website> {
    return this.http.post<Website>(this.baseUrl + 'websiteserviceoffers/postsubserviceoffer/', entity);
  }
edit(entity: ServiceOffer): Observable<Website> {
  return this.http.post<Website>(this.baseUrl + 'websiteserviceoffers/editserviceoffer/', entity);
  }
}
