import { Component, OnInit } from '@angular/core';
import { SupportSubMenuPdf } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/SupportSubMenuPdf';
import { SupportSubMenus } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/SupportSubMenus';
import { SupportSubMenuVideo } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/SupportSubMenuVideo';
import { SupportMenu } from 'src/app/_models/SupportMenus/SupportMenu';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { VideoService } from '../video.service';

@Component({
  selector: 'app-videoview',
  templateUrl: './videoview.component.html',
  styleUrls: ['./videoview.component.scss']
})
export class VideoviewComponent implements OnInit {
  spanishLabel: string;
  userAssignedToCompany: UserAssignedToCompany;
  supportSubMenuVideos = [] as SupportSubMenuVideo[];
  subMenu =[] as SupportSubMenus[];
  supportVideo = {} as SupportMenu;
  selectedIndex: any;
  isSpanish = false;
  baseUrl = 'https://www.youtube.com/embed/';
  constructor(private videoService: VideoService, private authService: AuthService) { }

  ngOnInit() {
    this.authService.company.subscribe(data =>  { this.userAssignedToCompany = data; this.getMenus(); });
  }
  getMenus() {
    this.videoService.getVideosMenus(this.userAssignedToCompany.parentGuid).subscribe((data: SupportSubMenus[]) => { 
      this.subMenu = data; console.log(data);
    }, error => {
      console.log(error);
    }, () => {

    });
  }

  setIndex(index: number, item: SupportSubMenus) {
    this.selectedIndex = index;
    this.supportSubMenuVideos = item.supportSubMenuVideos;
 }
 filterSpanish() {
  this.isSpanish = !this.isSpanish;
  if (!this.isSpanish) { this.spanishLabel = 'Spanish Videos'; }
  if (this.isSpanish) { this.spanishLabel = 'English Videos'; }
  this.supportSubMenuVideos = this.supportSubMenuVideos.filter(
    video => video.spanish === this.isSpanish);
    console.log(this.isSpanish);
    console.log(this.supportSubMenuVideos);
}
 getSubMenusByLanguage(){
  // this.videoService.getSubMenuById(2).subscribe(data => {
  // //  this.subMenu = data;
  //   this.supportSubMenuVideos = data[this.selectedIndex].supportSubMenuVideos;
  //   console.log(data[this.selectedIndex].supportSubMenuVideos);
  //  // this.videoService.supportSubMenusCache = data;
  // }, error => {}, () => {
  //   this.filterSpanish();
  // });
}
}
