<div style="margin-top: 10px;" class="container-fluid customer-tabs-sec">
<app-date-filter (calenderOutput)="updateDates($event)"></app-date-filter> 
  <ul class="nav nav-tabs" style="cursor: pointer;">
    <li>
      <a
        data-toggle="tab"
        [ngClass]="isAllSales ? 'active show' : ''"
        (click)="filter('isAllSales')"
        >All Sales</a
      >
    </li>
    <li *appHasRole="['Admin']">
      <a
        data-toggle="tab"
        [ngClass]="isSalesTax ? 'active show' : ''"
        (click)="filter('isSalesTax')"
        >Sales Tax Report</a
      >
    </li>
    <li *appHasRole="['Admin']">
      <a
        data-toggle="tab"
        [ngClass]="isSaleJournal ? 'active show' : ''"
        (click)="filter('isSaleJournal')"
        >Sales Journal</a
      >
    </li>
    <li *appHasRole="['Admin']">
      <a
        data-toggle="tab"
        [ngClass]="isVehicleSoldList ? 'active show' : ''"
        (click)="filter('isVehicleSoldList')"
        >Vehicle Sold List</a
      >
    </li>
    <div class="d-flex justify-content-right">
      <pagination
        [boundaryLinks]="true"
        pageBtnClass="nav nav-tabs"
        [(ngModel)]="pagination.currentPage"
        [totalItems]="pagination.totalItems"
        (pageChanged)="pageChanged($event)"
        [itemsPerPage]="pagination.itemsPerPage"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="&laquo;"
        lastText="&raquo;"
      >
      </pagination>
    </div>
  </ul>
</div>

<div style="margin-top: 30px;" class="customer-form-sec">
  <br><br>
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <div class="row">
                  <div class="col-md-11 table-sec">
                    <br />
                    <div  id="printableTable">
                    <table  borderColor="#ccc">
                      <tr>
                        <th  style="font-size:x-small;" (click)="sort('id')">
                          Id &nbsp;
                          <i [ngClass]="isIdAscending ? 'up' : 'down'"></i>
                        </th>
                        <th  style="font-size:small;" (click)="sort('saleType')">
                          Sale Type&nbsp;
                          <i
                            [ngClass]="isSaleTypeAscending ? 'up' : 'down'"
                          ></i>
                        </th>
                        <th style="font-size:small;" (click)="sort('date')">
                          Date &nbsp;
                          <i [ngClass]="isDateAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('invoiceNumber')">
                          Invoice # &nbsp;
                          <i [ngClass]="isInvoiceAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('vehicle')">
                          Vehicle&nbsp;
                          <i [ngClass]="isVehicleAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('customer')">
                          Customer &nbsp;
                          <i
                            [ngClass]="isCustomerAscending ? 'up' : 'down'"
                          ></i>
                        </th>
                        <th (click)="sort('isTax')">
                          Tax &nbsp; <i [ngClass]="isTax ? 'up' : 'down'"></i>
                        </th>

                        <th (click)="sort('isSellilngPriceAscending')">
                          Selling Price &nbsp;
                          <i [ngClass]="isSellilngPriceAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('isPredeliveryAscending')">
                          Dealer Fee &nbsp;
                          <i [ngClass]="isPredeliveryAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('isTitleAscending')">
                          Title &nbsp;
                          <i [ngClass]="isTitleAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('isTagAscending')">
                          Tag &nbsp; <i [ngClass]="isTagAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('isRegistrationAscending')">
                          Registration &nbsp;
                          <i [ngClass]="isRegistrationAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('isWarrantyAscending')">
                          Warranty &nbsp;
                          <i [ngClass]="isWarrantyAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('isTradeLienAscending')">
                          Trade Lien &nbsp;
                          <i [ngClass]="isTradeLienAscending ? 'up' : 'down'"></i>
                        </th>

                        <th (click)="sort('isTradeAllowAscending')">
                          TradeAllo &nbsp;
                          <i [ngClass]="isTradeAllowAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('isPurchasePriceAscending')">
                          Cost &nbsp;
                          <i [ngClass]="isPurchasePriceAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('isRepairsAscending')">
                          Repairs &nbsp;
                          <i [ngClass]="isRepairsAscending ? 'up' : 'down'"></i>
                        </th>
                        <th (click)="sort('isTotalCostAscending')">
                          Ttl Cost &nbsp;
                          <i [ngClass]="isTotalCostAscending ? 'up' : 'down'"></i>
                        </th>
                      </tr>

                      <tr [hidden]="isPrint">
                        <td >
                          <input  style="width: 26px;" (keyup)="setSearchBy('searchId', $event.target.value)"/>
                        </td>

                        <td>
                          <select
                            [(ngModel)]="selectStatus"
                            (change)="
                              selectedStatusChanged($event.target.value)
                            "
                            name="chartInvoiceStatusess"
                            onmousedown="value = '';"
                            #chartInvoiceStatusess="ngModel"
                          >
                            <option ngValue="null">--All--</option>
                            <option
                              *ngFor="let summary of chartInvoiceStatuses"
                              value="{{ summary.id }}"
                            >
                              {{ summary.value }}
                            </option>
                          </select>
                        </td>
                        <td></td>
                        <td>
                          <input
                            style="width: 55px;"
                            (keyup)="
                              setSearchBy(
                                'searchInvoiceNumber',
                                $event.target.value
                              )
                            "
                          />
                        </td>
                        <td></td>
                        <td>
                          <input
                            style="width: 96px;"
                            (keyup)="
                              setSearchBy('searchCustomer', $event.target.value)
                            "
                          />
                        </td>
                        <td>
                          <input
                            style="width: 96px;"
                            (keyup)="
                              setSearchBy('searchCounty', $event.target.value)
                            "
                          />
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <ng-container  *ngFor="let item of sales; let i = index">
                      <tr  align="right"  class="td-row">
                        <td *ngIf="i != sales.length - 1">{{ item?.id }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.saleStatus?.value }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.soldDate | date: "shortDate" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.acct }}</td>
                        <td (click)="findDetails(item, 'vehicle')"  style="cursor: grab !important;" align="left" *ngIf="i != sales.length - 1">
                          {{
                            item?.vehiclePurchased?.vehicle?.year + " " + item?.vehiclePurchased?.vehicle?.make + " " + item?.vehiclePurchased?.vehicle?.model
                          }}
                        </td>
                        <td (click)="findDetails(item, 'person')" style="cursor: grab !important;" align="left" *ngIf="i != sales.length - 1">
                          {{ item?.buyer?.firstName }}&nbsp; {{ item?.buyer?.lastName }}
                        </td>
                        <td *ngIf="i != sales.length - 1">{{ item?.tax | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.sellingPrice | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.predelivery | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.title | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.tag | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.registration | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.warranty | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.tradeLien | number: "1.2" }}</td>

                        <td *ngIf="i != sales.length - 1">{{ item?.tradeAllow | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.vehiclePurchased?.itemAmountSums?.purchasedPrice  | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.vehiclePurchased?.itemAmountSums?.sumFees  | number: "1.2" }}</td>
                        <td *ngIf="i != sales.length - 1">{{ item?.vehiclePurchased?.itemAmountSums?.totalValue | number: "1.2" }}</td>
                        <td [hidden]="isPrint" *ngIf="i != sales.length - 1">
                          <a
                            class="btn btn-success"
                            (click)="navigateInvoice(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Order Detail"
                            ><i class="fa fa-fw fa-eye"></i
                          ></a>
                        </td>
                      </tr>
                      <ng-container *ngIf="item?.id === itemId && this.isPerson">
                        <tr [@myAnimation]>
                          <td></td>
                          <td></td>
                          <td colspan="17">
                            <app-person-grid-card  [@myAnimation] [selectedCompany] = "this.selectedCompany1" [parentGuid] = "this.selectedCompany1.parentGuid" [person] = "person"></app-person-grid-card>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </ng-container>
                      <ng-container *ngIf="item?.id === itemId && this.isVehicle" [@myAnimation]>
                        <tr [@myAnimation] >
                          <td></td>
                          <td></td>
                          <td colspan = "7">
                            
                            <app-vehicle-card-grid [@myAnimation] [selectedCompany] = "this.selectedCompany1"  [chartItem] = "chartItem"></app-vehicle-card-grid>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>

                        </tr>
                      </ng-container>
                    </ng-container>
                      <tr  align="right" class="border_top" *ngFor="let item of sales; let i=index; last as isLast">
                    
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">Sub Totals:</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.tax | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.sellingPrice | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.predelivery | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.title | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.tag | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.registration | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.warranty | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.tradeLien | number: "1.2" }}</td>

                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.tradeAllowance | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{  item?.vehiclePurchased?.itemAmountSums?.purchasedPrice | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.vehiclePurchased?.itemAmountSums?.sumFees | number: "1.2" }}</td>
                        <td style="font: bold;" *ngIf="isLast && i !==0">{{ item?.vehiclePurchased?.itemAmountSums?.totalValue | number: "1.2" }}</td>
                      </tr>
                    </table>
                  </div>
                  </div>
                  <!-- /.table-sec -->

                  <div class="customer-sidebar sidebar-with-btns col-md-1">
                    <div class="inner-sidebar">
                      <ul>
                        <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->

                        <li>
                          <button class="cstm-btn" (click)="exportAsXLSX()">
                            <img
                              src="assets/images/icons8-microsoft-excel-48.png"
                            />
                            <span>Export Excel</span>
                          </button>
                        </li>
                        <li>
                          <button  (click) = "printDiv()" class="cstm-btn">
                            <img src="assets/images/ap-icon-6.png" />
                            <span>Print Report</span>
                          </button>
                        </li>
                        <li>
                          <button (click) = "tabelViewClick()" class="cstm-btn img-blk-btn">
                            <img src="assets/images/ap-icon-3.png">
                            <span>View Sales</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
    </div>
  </div>
</div>
<iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>


