import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { User } from '../_models/user';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PresenceService } from './presence.service';
import { AuthService } from './auth.service';
import { LoginResetPasswordDto } from '../_models/DTOS/LoginResetPasswordDto';
import { SupportUser } from '../_models/supportUser';
import { UserAssignedToCompany } from '../_models/userAssignedToCompany';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  baseUrl = environment.apiUrl;
  userAssignedToCompany = {} as UserAssignedToCompany;
  supportUser = {} as SupportUser;
  private currentUserSource = new ReplaySubject<User>(1);
  currentUser$ = this.currentUserSource.asObservable();

  private currentSupportUserSource = new ReplaySubject<SupportUser>(1);
  currentSupportUser$ = this.currentSupportUserSource.asObservable();
  constructor(private http: HttpClient, private presence: PresenceService, private authService: AuthService) {
    this.authService.company.subscribe(data => { this.userAssignedToCompany = data; })

   }

  login(model: any) {
    return this.http.post(this.baseUrl + 'account/login', model).pipe(
      map((response: SupportUser) => {
        const user = response;
        this.supportUser = response;
        console.log(response);
        if (user) {
          localStorage.setItem('userAssignedToCompany', JSON.stringify(user.userAssignedToCompany));
          this.authService.changeParentUserAssignedCompanies(user.userAssignedToCompany);
          localStorage.setItem('company', JSON.stringify(user.userAssignedToCompany[0]));
               this.authService.changeCompany(user.userAssignedToCompany[0]);
          this.setCurrentUser(user);
          this.setCurrentSupportUser(this.supportUser);
          this.presence.createHubConnection(user);
        }
      })
    );
  }

  register(model: any) {
    model.userAssignedToCompany = this.userAssignedToCompany;
    console.log(model);
    return this.http.post(this.baseUrl + 'account/register', model).pipe(
      map((user: User) => {
        if (user) {
         this.setCurrentUser(user);
         this.presence.createHubConnection(user);
        }
      })
    );
  }
// "changepassword/{userId}/{codetoverify}/{password}"
  resetPassword( codetoverify: any, password: any, userId?: any ) {
    return this.http.get(this.baseUrl + 'person/changepassword/' + codetoverify + '/' + password );
  }
  startResetPassword(userEmail) {
    return this.http.get<any>(this.baseUrl + 'person/passwordresethasbegun/' + userEmail);
  }
  verifyGuid(guid: any): Observable<any>{
     return this.http.get<any>(this.baseUrl + 'person/verifypasswordresetguid/' + guid);
  }

  setCurrentUser(user: User) {
    user.roles = [];
    const roles = this.getDecodedToken(user.token).role;
    Array.isArray(roles) ? user.roles = roles : user.roles.push(roles);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', JSON.stringify(user.token));
    this.currentUserSource.next(user);
  }
  setCurrentSupportUser(user: SupportUser) {
    localStorage.setItem('supportUser', JSON.stringify(user));
    this.currentSupportUserSource.next(user);
  }

  logout() {
    localStorage.removeItem('user');
    this.currentUserSource.next(null);
    this.presence.stopHubConnection();
  }

  getDecodedToken(token) {
    return JSON.parse(atob(token.split('.')[1]));
  }
}
