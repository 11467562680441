import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebsiteCompany } from 'src/app/_models/company';
import { CompanyDepartmentType } from 'src/app/_models/CompanyDepartmentType';
import { Website } from 'src/app/_models/website';
import { CompanySettingsService } from 'src/app/_services/advertising/company-settings.service';
import { WebsiteService } from 'src/app/_services/advertising/website.service';
import { CompanyDepartmentTypesService } from 'src/app/_services/companydepartmenttypes/company-department-types.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-website-dashboard',
  templateUrl: './website-dashboard.component.html',
  styleUrls: ['./website-dashboard.component.css']
})
export class WebsiteDashboardComponent implements OnInit {
  company = {} as WebsiteCompany;
  companyDepartmentTypes = [] as CompanyDepartmentType[];
  website = {} as Website;
  websiteguid = '' as string;
  isCompany = false;
  isDepartments = false;
  isSettings = false;
  isStoreHours = false;
  isSocial = false;
  isCashDown = false;
  isEmployee = false;
  isMenus = false;
  constructor( private websiteService: WebsiteService,
    private companyDepartmentTypesService: CompanyDepartmentTypesService,
    
    private alertify: ToastrService,
    private router: Router,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.subscribe(event => {
      this.websiteguid = event.websiteguid;
     });
     this.getCompanyDepartMentTypes();
    this.getWebiste(this.websiteguid);
  }
  getCompanyDepartMentTypes() {
    this.companyDepartmentTypesService.getPluralByWebsiteGuid(this.websiteguid).subscribe(data => {
      this.companyDepartmentTypes = data;
    //  console.log(data);
    }, error => { this.alertify.error(error); });
  }
  tabClicked(e) {
    this.isCompany = false;
    this.isDepartments = false;
    this.isStoreHours = false;
    this.isSocial = false;
    this.isSettings = false;
    this.isCashDown = false;
    this.isEmployee = false;
    this.isMenus = false;
    if (e === 'isEmployee') { this.isEmployee = true; }
    if (e === 'isSettings') { this.isSettings = true; }
    if (e === 'isCashDown') { this.isCashDown = true; }
    if (e === 'isCompany') { this.isCompany = true; }
    if (e === 'isDepartments') { this.isDepartments = true; }
    if (e === 'isStoreHours') { this.isStoreHours = true; }
    if (e === 'isSocial') { this.isSocial = true; }
    if (e === 'isMenus') { this.isMenus = true; }
  }

  getWebiste(id) {
    this.websiteService.getWebsite(id).subscribe(data => {
      this.website = data;
      this.websiteService.changeWebsite(data);
      this.alertify.success('Website Retrieved.');
    }, error => {
      this.alertify.error(error);
    }, () => {
      this.alertify.success('Website Retrieved.');
      this.isCompany = true;
    } );
  }
}
