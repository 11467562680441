import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Vehicle } from 'src/app/_models/vehicle';
import { VehicleService } from 'src/app/_services/vehicleserv/vehicle.service';

import { Pagination, PaginatedResult } from 'src/app/_models/pagination';
import { VehicleBodyClassTypes } from 'src/app/_models/VehicleBodyClassTypes';
import { InventoryVehicleGridToReturnDto } from 'src/app/_models/DTOS/inventoryvehiclegriddto';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MessageService } from 'src/app/_services/message.service';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { Item } from 'src/app/_models/Item';
import { AddVehicleVinModalComponent } from 'src/app/modals/add-vehicle-vin-modal/add-vehicle-vin-modal.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-aging-list',
  templateUrl: './aging-list.component.html',
  styleUrls: ['./aging-list.component.css']
})
export class AgingListComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;
  chartItems: InventoryVehicleGridToReturnDto[];
  chartItemsToDownload: InventoryVehicleGridToReturnDto[];
  bsModalRef: BsModalRef;
  bodyTypes: VehicleBodyClassTypes;
  isPublished: boolean;
  vehicle: Vehicle;
  pagination: Pagination;
  isAscending = true;
  isMakeAscending = true;
  isCategoryAscending = true;
  isStockAscending = true;
  isModelAscending = true;
  isBodyAscending = true;
  isPriceAscending = true;
  isPublishedAscending = true;
  isSoldDateAscending = true;
  isPickCountAscending = true;
  isYearAscending = true;
  isDaysInStockAscending = true;
  isPurchaseDate = true;
  isTotalCostAscending = true;
  isCostAscending = true;
  isRepairsAscending = true;
   isProfitReport = false;
  userParams = {} as any;
  selectedCategory;
  selectedSearchPhrase;
  isPrint = false;
  selectedRowIndex: any;
  constructor(private router: Router,
    private vehicleService: VehicleService,
    private modalService: BsModalService,
    private route: ActivatedRoute, private authService: AuthService,
    private chartService: VehicleService,
    private messageService: MessageService,
    private alertify: ToastrService, private excelService: ExcelService) {
      this.userParams = this.vehicleService.getUserParams();
     }

  ngOnInit() {
     this.isProfitReport = true;
    this.selectedCategory = 0;
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      this.bodyTypes = data.bodyTypes;
      this.chartItems = data.chartItems.result;
      this.pagination = data.chartItems.pagination;
    });
    this.userParams.returnByBodyTypeId = undefined;
  }
  selectedCategoryChanged(e) {
    this.userParams.returnByBodyTypeId = '';
    console.log(this.selectedCategory);
    if (this.selectedCategory === 0) { this.userParams.returnByBodyTypeId = undefined; }
    if (this.selectedCategory === null) { this.userParams.returnByBodyTypeId = undefined; }
    if (e > 0) {this.userParams.returnByBodyTypeId = e; }

    this.loadVehicles();
  }
  filter(e) {
    console.log(e);
    this.userParams.filter = e;
    this.vehicleService.setUserParams(this.userParams);
    this.loadVehicles();
  }
  sort(e) {
    this.isMakeAscending = true;
    this.isCategoryAscending = true;
    this.isStockAscending = true;
    this.isModelAscending = true;
    this.isBodyAscending = true;
    this.isPriceAscending = true;
    this.isPublishedAscending = true;
    this.isSoldDateAscending = true;
    this.isPickCountAscending = true;
    this.isYearAscending = true;
    this.isDaysInStockAscending = true;
    this.isPurchaseDate = true;
    this.isTotalCostAscending = true;
    this.isCostAscending = true;
    this.isRepairsAscending = true;
    this.userParams.orderBy = e;

    this.isAscending = !this.isAscending;
    this.userParams.isAscending = this.isAscending;
    this.userParams.itemsPerPage = 50;
    console.log(e);
    this.loadVehicles();
    if (e === 'make') {this.isMakeAscending = this.isAscending; }
    if (e === 'model') {this.isModelAscending = this.isAscending; }
    if (e === 'category') {this.isCategoryAscending = this.isAscending; }
    if (e === 'stock') {this.isStockAscending = this.isAscending; }
    if (e === 'year') {this.isYearAscending = this.isAscending; }
    if (e === 'body') {this.isBodyAscending = this.isAscending; }
    if (e === 'price') {this.isPriceAscending = this.isAscending; }
    if (e === 'solddate') {this.isSoldDateAscending = this.isAscending; }
    if (e === 'pickcount') {this.isPickCountAscending = this.isAscending; }

    if (e === 'published') {this.isPublishedAscending = this.isAscending; }

    if (e === 'isPurchaseDate') {this.isPurchaseDate = this.isAscending; }
    if (e === 'isDaysInStockAscending') {this.isDaysInStockAscending = this.isAscending; }
    if (e === 'isTotalCostAscending') {this.isTotalCostAscending = this.isAscending; }
    if (e === 'isCostAscending') {this.isCostAscending = this.isAscending; }
    if (e === 'isRepairsAscending') {this.isRepairsAscending = this.isAscending; }

  }
  async setSearchBy(e, event) {
    console.log(e);
    setTimeout(() => {
      console.log(event);
      console.log(e);
      this.userParams.searchBy = e;
      this.userParams.searchFor = event;
      this.loadVehicles();
    }, 2000);
  }
  async print() {
    
    const newLocal = 'print_frame';
    window.frames[newLocal].document.body.innerHTML = document.getElementById('printableTable').innerHTML;
    // tslint:disable-next-line:no-string-literal
    window.frames['print_frame'].window.focus();
    // tslint:disable-next-line:no-string-literal
    window.frames['print_frame'].window.print();
    this.isPrint = false;
  }
  async printDiv() {
    await this.hideDiv().then(() => {
     setTimeout(() => {
       this.print();
     }, 2000);
      });
   }
   async hideDiv() {
     this.isPrint = true;
   }
  pageChanged(event: any): void {
    console.log('pageChanged');
  //  this.pagination.currentPage = event.page;

    this.userParams.pageNumber = event.page;
    this.loadVehicles();
  }
   loadVehicles() {
     this.vehicleService.setUserParams(this.userParams);
     console.log(this.userParams);
    this.chartService.getVehicleListGrid(this.selectedCompany1.parentGuid, this.userParams)
    .subscribe((data: PaginatedResult <InventoryVehicleGridToReturnDto[]>) => {
      this.chartItems = data.result;
      this.pagination = data.pagination;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Vehicles Retrieved.');
    });
   }
  addItem() {
    this.router.navigate(['productlist/additem']);
  }
  editVehicle(item: Item) {
   // this.messageService.sendMessage(item, '');
    this.router.navigate(['vehicledashboard/editvehicle/' +   this.selectedCompany1.parentGuid + '/' + item.id]);
  }
  addVehicle() {
      this.bsModalRef = this.modalService.show(AddVehicleVinModalComponent);
      this.bsModalRef.content.updateVehicle.subscribe((values) => {
        console.log(values);
        this.vehicleService.addVehicle(values).subscribe(data => {
         // this.chartItems = data.chartItems;
         this.router.navigate(['vehicledashboard/editvehicle/' + this.selectedCompany1.parentGuid + '/' + data.vehicleChartItem.id]);
        });
      });
  }


  exportAsXLSX(): void {
//     this.pagination.itemsPerPage = 1000000;
//     this.chartService.getVehicleListGrid(this.selectedCompany1.parentGuid, this.pagination.currentPage,
//       this.pagination.itemsPerPage, this.userParams)
//     .subscribe((data: PaginatedResult <InventoryVehicleGridToReturnDto[]>) => {
//       this.chartItemsToDownload = data.result;
//       this.excelService.exportAsExcelFile(data.result, 'Aging List');
//   //    this.pagination = data.pagination;
//       this.pagination.itemsPerPage = 15;
//     }, error => { this.alertify.error(error); }, () => { this.alertify.success('Your Export is Ready'); } );
 }

}


