
<div class="customer-form-sec">
    <div class="container-fluid">
<div class="row">
   
    <!-- style="background-color:#f0f0f0 !important;" -->
    <div class="form-sec tab-content col-md-11">
     <div  class="card" style="background-color:#f0f0f0 !important;">
        <div class="col-sm-12">

            <div *ngIf="editForm.dirty" class="alert alert-info">
              <strong>Information:</strong> You have made changes.  Any unsaved changes will be lost!
            </div>
          </div>
       <form #editForm="ngForm" id="editForm">
        <div class="form-group form-inline">
          <label>Map Url</label>
          <input style="min-width: 65%;" type="text" class="form-control" [ngModel]="gpsMaps?.googleMapUrl" (ngModel)="gpsMaps.googleMapUrl=$event"
            name="googleMapUrl" #googleMapUrl="ngModel">
        </div>
              <div class="form-group form-inline">
                <label>Height</label>
                <input type="text" class="form-control" [ngModel]="gpsMaps?.mapHeight" (ngModel)="gpsMaps.mapHeight=$event"
                  name="mapHeight" #mapHeight="ngModel">
              </div>
              <div class="form-group  form-inline">
                  <label>Width</label>
                  <input type="text" class="form-control" [ngModel]="gpsMaps?.mapWidth" (ngModel)="gpsMaps.mapWidth=$event"
                    name="mapWidth" #mapWidth="ngModel">
                </div>
    </form>
     </div>
    </div>
    <div class="customer-sidebar sidebar-with-btns col-md-1">
        <div class="inner-sidebar">
          <ul>
            <li *ngIf="editForm.dirty">
              <button (click) = "onSubmit(editForm)" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>Save</span>
              </button>
            </li>
           
          </ul>
        </div>
      </div>
      </div>
      </div>
</div>


