import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../_modules/shared.module';
import { AddServiceComponent } from './add-service/add-service.component';
import { ServiceGridComponent } from './service-grid/service-grid.component';
import { ServiceShopRoutingModule } from './serviceshop-routing.module';


@NgModule({
  declarations: [
AddServiceComponent,
ServiceGridComponent
],
  entryComponents: [
    AddServiceComponent,
],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ServiceShopRoutingModule
  ]
})
export class ServiceShopModule { }
