import { Component, OnInit } from '@angular/core';
import { CompanySeed } from '../_models/SeedCompany';
import { UserAssignedToCompany } from '../_models/userAssignedToCompany';
import { AuthService } from '../_services/auth.service';
import { SeedService } from '../_services/seeds/seed.service';

@Component({
  selector: 'app-company-seeds',
  templateUrl: './company-seeds.component.html',
  styleUrls: ['./company-seeds.component.css']
})
export class CompanySeedsComponent implements OnInit {
selectedCompany1 = {} as UserAssignedToCompany;
companySeeds = {} as CompanySeed;
  constructor(private seedService: SeedService, private authService: AuthService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
    this.seedService.getSeeds(this.selectedCompany1.parentGuid).subscribe(data => { this.companySeeds = data; console.log(data); }, error => {
      console.log(error);
    });
  }

}
