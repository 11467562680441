
<div class="customer-form-sec">
  <div class="container-fluid">
    <br><br>
    <div class="row">
          <div class="form-sec tab-content col-md-12">
            <div id="depreciate-costs" class="tab-pane fade in active show">
              <div class="row">
                <div class="col-md-12">
                  <div class="inner-row account-payable-row">          
                  
                
                    <div class="row">
            
                      <div class="col-md-12 table-sec" >
                        <div id="printableTable">
                        <table  borderColor="#ccc">
                          <!-- header -->
                          <tr>
                <th>ID</th>
                <th >Frequency </th>
                <th >Name </th>
                <th >Description
                </th>
                <th >Amount
                </th>
      
                <td></td>
                <td></td>
                          </tr>
                          <!-- results -->
                          <tr   align="left" class="td-row" *ngFor="let item of subscriptions; let i=index">
                            <td  >{{ item.id }}</td>
                            <td>{{ item?.userSubscriptionPlanFrequency?.frequency}}</td>
                            <td >{{ item?.name }}</td>
                            <td >{{ item?.description }}</td>
                            <td align="right">{{ item?.amount | number: "1.2" }}</td>
                            <td><button (click)= "orderSoftware(item)">Order</button></td>
                        </table>
                        </div>
                      </div>
                      <!-- /.table-sec -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.row -->
            </div>
          </div>
      

    </div>
  </div>
</div>