<!-- <div class="modal fade" id="addPaymentModal" role="dialog" tabindex="-1">  -->
<!-- this is the ADD PAYMENT NOT VIEW PAYMENTS MODAL-->
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <img src="assets/images/payment-icon.png">
        <h4 class="modal-title">Payment</h4>
        <button type="button" class="close" onclick="$('#addPaymentModal').modal('hide');">&times;</button>
      </div>
      <div class="modal-body payments-form">
        <div class="payments-list-grid">
          <div class="row">
            <div class="col-md-4 cstm-col">
              <div class="rw">
                <label>Payment #</label>
                <input type="text" name="" value="1" class="cstm-input">
              </div>
              <div class="rw">
                <label>Receipt #</label>
                <input type="text" name="" value="42830011" class="cstm-input">
              </div>
              <div class="rw">
                <label>Date Paid</label>
                <input type="text" name="" value="04/10/2018" class="cstm-input">
                <button class="cstm-btn">...</button>
              </div>
            </div>
            <div class="col-md-4 cstm-col">
              <div class="rw">
                <label>Payment</label>
                <input type="text" name="" value="0.00" class="cstm-input without-border">
              </div>
              <div class="rw">
                <label>Last Date</label>
                <input type="text" name="" value="" class="cstm-input" disabled="">
              </div>
              <div class="rw">
                <label>Date Next</label>
                <input type="text" name="" value="04/10/2018" class="cstm-input">
                <button class="cstm-btn">...</button>
              </div>
            </div>
            <div class="col-md-4 cstm-col last-grid-col">
              <div class="rw">
                <label>Taxes</label>
                <input type="text" name="" value="364.54" class="cstm-input without-border">
              </div>
              <div class="rw">
                <label>Misc Charges</label>
                <input type="text" name="" value="0.00" class="cstm-input without-border">
              </div>
              <div class="rw">
                <label>PickUps</label>
                <input type="text" name="" value="0.00" class="cstm-input without-border">
              </div>
            </div>
          </div>
        </div>
        <div class="payments-tabs">
          <div class="inner">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">	<img src="assets/images/payment-icon.png">
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                  <img src="assets/images/notes-icon-1.jpg">
                </a>
              </li>
            </ul>
            <div class="tab-content" id="paymentTabContent">
              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="inner">
                  <div class="row">
                    <div class="col-md-4 cstm-col left-grid">
                      <div class="inner">
                        <h2 class="title">Today's</h2>
                        <div class="row">
                          <div class="col-md-12 custom-col">
                            <div class="cstm-rw">
                              <label class="amt-label">Amount Due</label>
                              <input type="text" name="" value="0.00" class="cstm-input amt-input">
                              <button class="amt-btn">Pay <span>Off</span></button>
                            </div>
                            <div class="cstm-rw">
                              <label>Principal</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Interest</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Taxes</label>
                              <input type="text" name="" value="-1.#IND" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Late Fee</label>
                              <input type="text" name="" value="0.00" class="cstm-input">
                            </div>
                            <div class="cstm-rw">
                              <label>Misc</label>
                              <input type="text" name="" value="0.00" class="cstm-input">
                            </div>
                            <div class="cstm-rw">
                              <label>PickUps</label>
                              <input type="text" name="" value="0.00" class="cstm-input">
                            </div>
                            <div class="cstm-rw">
                              <label>Amount Paid</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Pay Off</label>
                              <input type="text" name="" value="120.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Balance</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Method</label>

                            </div>
                            <div class="cstm-rw">
                              <input list="browsers" name="browser" class="cstm-select">
                              <datalist id="browsers">
                                <option value="Internet Explorer">
                                <option value="Firefox">
                                <option value="Chrome">
                                <option value="Opera">
                                <option value="Safari">
                              </datalist>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 cstm-col center-grid">
                      <div class="inner">
                        <h2 class="title">Total Paid</h2>
                        <div class="row">
                          <div class="col-md-12 custom-col">
                            <div class="cstm-rw">
                              <label>Amount Due</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Principal</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Interest</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Taxes</label>
                              <input type="text" name="" value="-1.#IND" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Late Fee</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Misc</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>PickUps</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Amount Paid</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Pay Off</label>
                              <input type="text" name="" value="120.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Balance</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 cstm-col right-grid">
                      <div class="inner">
                        <h2 class="title">Balance (Unpaid)</h2>
                        <div class="row">
                          <div class="col-md-12 custom-col">
                            <div class="cstm-rw">
                              <label>Interest</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Taxes</label>
                              <input type="text" name="" value="364.54" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Misc</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>PickUps</label>
                              <input type="text" name="" value="0.00" class="cstm-input" disabled="">
                            </div>
                            <div class="cstm-rw">
                              <label>Today's Unpaid Interest</label>
                              <input type="text" name="" value="59.65" class="cstm-input" disabled="">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">Pick Ups / Misc Charges</div>
              <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">Customer / Vehicle / Notes</div>
            </div>
          </div>
        </div>
        <div class="payments-foot">
          <div class="row">
            <div class="col-md-6 left-col">
              <div class="inner">
                <h3 class="title">Future Payoff</h3>
                <span>After</span>
                <input type="text" name="" value="10" class="af-day-input">
                <span>days</span>
                <span class="pay-off">Pay Off</span>
                <input type="text" name="" value="120.00" disabled="" class="payoff-input">
              </div>
            </div>
            <div class="col-md-6 right-col">
              <button class="cstm-btn">Reset</button>
              <button class="cstm-btn">OK</button>
              <button class="cstm-btn">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
 <!-- </div> -->