import { SelectCellEditor } from '@ag-grid-community/all-modules';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { CompanyProfileSubscription } from 'src/app/_models/CompanyProfileSubscription';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { UserCart } from 'src/app/_models/UserCart';
import { UserSubscriptionPlan } from 'src/app/_models/UserSubscriptionPlan';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';
import { UserCartService } from 'src/app/_services/userpaymentservices/User-Cart.service';
declare var paypal;
@Component({
  selector: 'app-payme-individual',
  templateUrl: './payme-individual.component.html',
  styleUrls: ['./payme-individual.component.scss']
})
export class PaymeIndividualComponent implements OnInit {
  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;
  companyProfile = {} as CompanyProfile;
 userSubscriptionPlan = {} as UserSubscriptionPlan;
  userAssignedToCompany = {} as UserAssignedToCompany;
  companyProfilePayment = {} as CompanyProfileSubscription;
  paidFor = false;
  amount = 0;
  description = 'None Selected';
  orderPlaced = {} as any;
  constructor(private userCartService: UserCartService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private companiesService: CompaniesService,
    private alertify: ToastrService) { }

    ngOnInit() {
      this.authService.currentCompany.subscribe(data => {this.userAssignedToCompany = data; });
      this.userCartService.currentuserSubscriptionPlan.subscribe(data => { this.userSubscriptionPlan = data;
      if (data != null) {
        this.amount = this.userSubscriptionPlan.amount;
        this.description = this.userSubscriptionPlan.description + ' ' + this.userSubscriptionPlan.userSubscriptionPlanFrequency.verbage;
      } });
      console.log(this.userSubscriptionPlan);
      this.getCompanyProfile();
      this.paypalLoad();
    }
    paypalLoad() {
      paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.description,
                  amount: {
                    currency_code: 'USD',
                    value: this.amount
                  }
                }
              ]
            });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          this.paidFor = true;
        //  console.log(order);
          this.orderPlaced = order;
          this.updateDatabase(order);
          this.calculateDates(order);
          this.companyProfilePayment.amountPaid = this.amount;
          this.companyProfilePayment.datePaid = new Date(this.datePipe.transform(Date.now() , 'yyyy-MM-dd'));
        }, onError: err => {
          console.log(err);
        }
    })
      .render(this.paypalElement.nativeElement);
    }
    calculateDates(orderPlaced) {
      if (!this.companyProfile.expirationDate) {
        let dateToStart = '';
        dateToStart = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');
        console.log(dateToStart);
        const currentExpirationDate = new Date(this.datePipe.transform(Date.now(), 'yyyy-MM-dd'));
        this.companyProfile.expirationDate = new Date(new Date(currentExpirationDate).setMonth(currentExpirationDate.getMonth() + this.userSubscriptionPlan.userSubscriptionPlanFrequency.paymentsPerYear ));
        dateToStart = this.datePipe.transform(currentExpirationDate, 'yyyy-MM-dd');
        console.log( this.companyProfile.expirationDate);
      } else {
        let dateToStart = '';
        dateToStart = this.datePipe.transform(this.companyProfile.expirationDate , 'yyyy-MM-dd');
        const currentExpirationDate = new Date(this.datePipe.transform(this.companyProfile.expirationDate , 'yyyy-MM-dd'));
        this.companyProfile.expirationDate = new Date(new Date(currentExpirationDate).setMonth(currentExpirationDate.getMonth() + this.userSubscriptionPlan.userSubscriptionPlanFrequency.paymentsPerYear ));
        dateToStart = this.datePipe.transform(currentExpirationDate, 'yyyy-MM-dd');
        console.log( this.companyProfile.expirationDate);
      }
      this.companyProfilePayment.expirationDate = this.companyProfile.expirationDate;
      this.companyProfilePayment.companyProfileId = this.userAssignedToCompany.companyProfileId;
      this.companyProfilePayment.paidNumberMonths = this.userSubscriptionPlan.userSubscriptionPlanFrequency.paymentsPerYear;
      console.log(this.companyProfilePayment);

      this.companiesService.applyCompanyPayment(this.userAssignedToCompany.companyProfileId, this.companyProfilePayment).
      subscribe(data => {
        console.log(data);
      }, error => {
        console.log(error);
      }, () => { 
        this.alertify.success('Payment Applied. ');
      this.userSubscriptionPlan = null;
    });
    }
    getCompanyProfile() {
      this.companiesService.getCompanyByCompanyGuid(this.userAssignedToCompany.parentGuid).subscribe(data => {
        this.companyProfile = data;
        console.log(data);
      });
    }
    updateDatabase(orderPlaced) {
      console.log(orderPlaced);
      console.log(this.userSubscriptionPlan);
    }
  }
