<form [formGroup]='registerForm' (ngSubmit)="registerForm.valid && register()" autocomplete="off">
    <h2 class="text-center text-primary">Sign up</h2>
    <hr>

    <div class="form-group">
        <!-- <label class="control-label" style="margin-right: 10px;">I am a: </label>
        <label class="radio-inline">
            <input type="radio" value='male' formControlName='gender'> Male
        </label>
        <label class="radio-inline">
            <input type="radio" value='female' formControlName='gender' class="ml-3"> Female
        </label> -->
    </div>

    <app-text-input [formControl]='registerForm.controls["email"]' 
        [label]='"Email"'></app-text-input>
    <app-text-input [formControl]='registerForm.controls["phone"]' 
        [label]='"Phone"'></app-text-input>
    <!-- <app-date-input [formControl]='registerForm.controls["dateOfBirth"]' 
        [label]='"Date Of Birth"' [maxDate]='maxDate'></app-date-input> -->
    <app-text-input [formControl]='registerForm.controls["knownAs"]' 
        [label]='"First Name"'></app-text-input>
   <!--  <app-text-input [formControl]='registerForm.controls["country"]' 
        [label]='"Country"'></app-text-input> -->
    <app-text-input [formControl]='registerForm.controls["password"]' 
        [label]='"Password"' [type]='"password"'>
    </app-text-input>
    <app-text-input [formControl]='registerForm.controls["confirmPassword"]' 
        [label]='"Confirm Password"'
        [type]='"password"'></app-text-input>

    <div class="row" *ngIf = "validationErrors.length > 0">
        <ul class="text-danger">
            <li *ngFor="let error of validationErrors">
                {{error}}
            </li>
        </ul>
    </div>

    <div class="form-group text-center">
        <button [disabled]='!registerForm.valid' class="btn btn-success mr-2" type="submit">Register</button>
        <button class="btn btn-default mr-2" (click)="cancel()" type="button">Cancel</button>
    </div>
</form>