import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import moment from 'moment';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { Item } from 'src/app/_models/item';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Person } from 'src/app/_models/person';
import { Sale } from 'src/app/_models/sale';
@Injectable({ providedIn: 'root' })
export class SaleDataVehicles {
    moment = require('moment');
    convertDate(date) {
        const momentDate = moment().format('L').toString();
        return momentDate;
}
    fnz02 = (num) => num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
        })

          fnz04 = (num) => num.toLocaleString('en-US', {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
            useGrouping: false,
            })
            fnz0 = (num) => num.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
                })
                stringLeft(input: string, position: number)
                {
                    return input.substr(position, Math.min(input.length, 1));
                }
      fillPdfFieldsWithVehicles(item: Item, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
      let  field = '';
      let newpdfField = {} as PdfSaleFields;
     // let i = 0;
      const newPdfFields = [] as PdfSaleFields[];
      pdfFields.forEach(element => {
        field = element.field;
        newpdfField.groupBy = groupByField;
        const str = field;
        const matches = str.match(/\d+/g);
        let i;
        if (groupByField === 'VehicleTrade' && item == null) { return of(pdfFields); }
        if (groupByField === 'VehicleTrade2' && item == null) { return of(pdfFields); }
        if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
        field = field.replace(i.toString(), '');
            switch (field) {
                case prefix + 'SoldDate': {
                    newpdfField  = {
                        field: prefix + 'SoldDate' + i,
                        value: this.convertDate(item?.soldDate),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                
                case prefix + 'Vin': {
                    newpdfField  = {
                        field: prefix + 'Vin' + i,
                        value: item.vehicle.vin,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Year': {
                    newpdfField  = {
                        field: prefix + 'Year' + i,
                        value: item.vehicle.year.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Make': {
                    newpdfField  = {
                        field: prefix + 'Make' + i,
                        value: item.vehicle.make.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Model': {
                    newpdfField  = {
                        field: prefix + 'Model' + i,
                        value: item.vehicle.model.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Series': {
                    newpdfField  = {
                        field: prefix + 'Series' + i,
                        value: item?.vehicle?.series.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Trim': {
                    newpdfField  = {
                        field: prefix + 'Trim' + i,
                        value: item?.vehicle?.trim.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Body': {
                    newpdfField  = {
                        field: prefix + 'Body' + i,
                        value: item?.vehicle?.vehicleBodyClassTypes?.nameFriendly.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Mileage': {
                    newpdfField  = {
                        field: prefix + 'Mileage' + i,
                        value: item?.itemVehicleTransientDetails?.mileage.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'TitleNumber': {
                    newpdfField  = {
                        field: prefix + 'TitleNumber' + i,
                        value: item?.itemVehicleTransientDetails?.titleNumber.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'PreviousTitleNumber': {
                    newpdfField  = {
                        field: prefix + 'PreviousTitleNumber' + i,
                        value: item?.itemVehicleTransientDetails?.previousTitleNumber.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Tag': {
                    newpdfField  = {
                        field: prefix + 'Tag' + i,
                        value: item?.itemVehicleTransientDetails?.tag.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'TagExpires': {
                    newpdfField  = {
                        field: prefix + 'TagExpires' + i,
                        value: this.convertDate(item?.itemVehicleTransientDetails?.tagExpires),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Stock': {
                    newpdfField  = {
                        field: prefix + 'Stock' + i,
                        value: item?.itemVehicleTransientDetails?.stockNumber.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'DatePurchased': {
                    newpdfField  = {
                        field: prefix + 'DatePurchased' + i,
                        value: this.convertDate(item?.itemVehicleTransientDetails?.datePurchased),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'MileageCode': {
                    newpdfField  = {
                        field: prefix + 'MileageCode' + i,
                        value: item?.itemVehicleTransientDetails?.vehicleMileageCodes?.value.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'PreviousState': {
                    newpdfField  = {
                        field: prefix + 'PreviousState' + i,
                        value: item.itemVehicleTransientDetails.previousState.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'DecalNumber': {
                    newpdfField  = {
                        field: prefix + 'DecalNumber' + i,
                        value: item.itemVehicleTransientDetails.decalNumber.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'PurchasedAs': {
                    newpdfField  = {
                        field: prefix + 'PurchasedAs' + i,
                        value: item.itemVehicleTransientDetails.purchasedAs.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'SoldToAccount': {
                    newpdfField  = {
                        field: prefix + 'SoldToAccount' + i,
                        value: item?.itemVehicleTransientDetails?.soldToAccount.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Weight': {
                    newpdfField  = {
                        field: prefix + 'Weight' + i,
                        value: item?.vehicle?.weight.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'IntColor': {
                    newpdfField  = {
                        field: prefix + 'IntColor' + i,
                        value: item?.vehicle?.interiorColor.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Color': {
                    newpdfField  = {
                        field: prefix + 'Color' + i,
                        value: item?.vehicle?.exteriorColor1.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Colorr': {
                    newpdfField  = {
                        field: prefix + 'Colorr' + i,
                        value: item?.vehicle?.exteriorColor2.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Cylinders': {
                    newpdfField  = {
                        field: prefix + 'Cylinders' + i,
                        value: item?.vehicle?.vehicleEngines?.engineCylinders,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'CountyCode': {
                    newpdfField  = {
                        field: prefix + 'CountyCode' + i,
                        value: item?.itemVehicleTransientDetails?.countyCode,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vina': {
                    newpdfField  = {
                        field: prefix + 'Vina' + i,
                        value: this.stringLeft(item.vehicle.vin, 0),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinb': {
                    newpdfField  = {
                        field: prefix + 'Vinb' + i,
                        value: this.stringLeft(item.vehicle.vin, 1),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinc': {
                    newpdfField  = {
                        field: prefix + 'Vinc' + i,
                        value: this.stringLeft(item.vehicle.vin, 2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vind': {
                    newpdfField  = {
                        field: prefix + 'Vind' + i,
                        value: this.stringLeft(item.vehicle.vin, 3),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vine': {
                    newpdfField  = {
                        field: prefix + 'Vine' + i,
                        value: this.stringLeft(item.vehicle.vin, 4),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinf': {
                    newpdfField  = {
                        field: prefix + 'Vinf' + i,
                        value: this.stringLeft(item.vehicle.vin, 5),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Ving': {
                    newpdfField  = {
                        field: prefix + 'Ving' + i,
                        value: this.stringLeft(item.vehicle.vin, 6),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinh': {
                    newpdfField  = {
                        field: prefix + 'Vinh' + i,
                        value: this.stringLeft(item.vehicle.vin, 7),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vini': {
                    newpdfField  = {
                        field: prefix + 'Vini' + i,
                        value: this.stringLeft(item.vehicle.vin, 8),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinj': {
                    newpdfField  = {
                        field: prefix + 'Vinj' + i,
                        value: this.stringLeft(item.vehicle.vin, 9),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vink': {
                    newpdfField  = {
                        field: prefix + 'Vink' + i,
                        value: this.stringLeft(item.vehicle.vin, 10),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinl': {
                    newpdfField  = {
                        field: prefix + 'Vinl' + i,
                        value: this.stringLeft(item.vehicle.vin, 11),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinm': {
                    newpdfField  = {
                        field: prefix + 'Vinm' + i,
                        value: this.stringLeft(item.vehicle.vin, 12),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinn': {
                    newpdfField  = {
                        field: prefix + 'Vinn' + i,
                        value: this.stringLeft(item.vehicle.vin, 13),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vino': {
                    newpdfField  = {
                        field: prefix + 'Vino' + i,
                        value: this.stringLeft(item.vehicle.vin, 14),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinp': {
                    newpdfField  = {
                        field: prefix + 'Vinp' + i,
                        value: this.stringLeft(item.vehicle.vin, 15),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Vinq': {
                    newpdfField  = {
                        field: prefix + 'Vinq' + i,
                        value: this.stringLeft(item.vehicle.vin, 16),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
            }
        });
         return of(newPdfFields);
      }
}

