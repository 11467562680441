import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {  VehicleToEditResolver } from '../_resolvers/vehicletoedit.resolver';
import { LabelVehicleDetailsResolver } from '../_resolvers/label-vehicledetails.resolver';
import { InventoryChartComponent } from './inventory-chart/inventory-chart.component';
import { InventoryDeskComponent } from './inventory-desk/inventory-desk.component';
import { InventoryEditComponent } from './inventory-edit/inventory-edit.component';
import { AgingListComponent } from './aging-list/aging-list.component';
import { VehicleBodyClassTypesResolver } from '../_resolvers/vehicle-bodytype-categories.resolver';
import { VehicleGridResolver } from '../_resolvers/product-list-vehiclegrid.resolver';
import { VehicleOptionsResolver } from '../_resolvers/vehicle-options.resolver';
import { ItemVehicleToEditDollarsResolver } from '../_resolvers/item-dollars-vehicleedit.resolver';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductListResolver } from '../_resolvers/product-list.resolver';






const routes: Routes = [
    // {path: 'vehiclecharts/:parrentGuid', component: InventoryGridComponent},
    {path: 'aging/:parentGuid', component: AgingListComponent,
    resolve: { bodyTypes: VehicleBodyClassTypesResolver, chartItems: VehicleGridResolver
    }},
    {path: 'productlist/:parentGuid', component: ProductListComponent,
    resolve: {chartItems: ProductListResolver, bodyTypes: VehicleBodyClassTypesResolver
    }},
    {path: 'addvehicle/:parrentGuid', component: InventoryDeskComponent},
    {path: 'editvehicle/:parentGuid/:itemId', component: InventoryDeskComponent, resolve: {
        item: VehicleToEditResolver, dollars: ItemVehicleToEditDollarsResolver, vehicleOptions: VehicleOptionsResolver}},

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InentoryRoutingModule {}

// {path: 'productlist/aging/:parentGuid', component: AgingListComponent,
// resolve: {chartItems: VehicleGridResolver, bodyTypes: VehicleBodyClassTypesResolver
// }},
