
	<div class="customer-form-sec deal-info-form-sec vehicle-2-sec">
		<div class="container-fluid">
      <br><br>
			<div class="row">
				<div class="form-sec tab-content col-md-10">
					<div id="vehicle-2" class="tab-pane fade in active show">
						<div class="row">
							
							<div class="col-md-6 right-side-deal-info">
								<div class="pickup-sec sold-veh-sec">
									<div class="col-md-6">
										<div class="row">
									<h2 class="pick-title">Sold Vehicle</h2>
									<div class="sv-rw">
										<label>Quote Only</label>
										<input type="checkbox" name="" class="quote-check">
									</div>
									  <!--STOCK #-->
									  <div class="sv-rw col-md-12 cstm-col">
										<div class="inline-blk"
										[ngClass]="{
										  'has-success': stockNumber?.valid && (stockNumber?.touched || stockNumber?.dirty),
										  'has-danger': stockNumber?.invalid && (stockNumber?.touched || stockNumber?.dirty)
										}">
										  <label   (dblclick)="toggleValue2('StockNumber')"  >{{labelStock}}</label>
										  <input class="cstm-input" value=""
										  type="text"
										  autocomplete="off"
										  [ngModel]="sale?.vehiclePurchased?.itemVehicleTransientDetails?.stockNumber"
										  (ngModel)="sale.vehiclePurchased.itemVehicleTransientDetails.stockNumber = $event"
										  (ngModelChange)="sale.vehiclePurchased.itemVehicleTransientDetails.stockNumber = $event"
										  name="stockNumber"
										  #stockNumber="ngModel"
										  required>
										</div>
										
										<div
										*ngIf="stockNumber.errors && (stockNumber?.touched || stockNumber?.dirty)">
										<p *ngIf="stockNumber?.errors?.required">Stock Is Required</p>
									  </div>
									  </div>
									<!-- <div class="sv-rw">
										<label>Stock #</label>
										<input type="text" name="" class="stock-input">
										<button class="cstm-btn"><img src="assets/images/car-icon.jpg"></button>
									</div> -->
									  <!-- CATEGORY -->
									  <div class="col-md-12 cstm-col">
										<label (dblclick)="toggleValue2('Category')" >{{labelCategory}}</label>
										  <input  list="category" 
										  [ngModel]="sale?.vehiclePurchased?.vehicle?.vehicleBodyClassTypes?.nameFriendly"
										(ngModel)="sale.vehiclePurchased.vehicle.vehicleBodyClassTypes.nameFriendly = $event"
										(ngModelChange)="sale.vehiclePurchased.vehicle.vehicleBodyClassTypes.nameFriendly = $event"
										name="vehicleBodyClassTypes"
										#vehicleBodyClassTypes="ngModel"/>
										  <datalist  id="category"  class="select-input">
											<option   *ngFor="let item of bodyTypes" [selected]="item.id === sale.vehiclePurchased.vehicle.vehicleBodyClassTypeId"  value="{{item.nameFriendly}}">
												</option>
										  </datalist>
									  </div>
									  <!--VIN-->
									  <div class="col-md-12 cstm-col">
										<div class="inline-blk"
										[ngClass]="{
										  'has-success': vin?.valid && (vin?.touched || vin?.dirty),
										  'has-danger': vin?.invalid && (vin?.touched || vin?.dirty)
										}">
										<label (dblclick)="toggleValue2('VIN')" >{{labelVin}}</label>
										  <input class="cstm-input" value=""
										  type="text"
										  autocomplete="off"
										  [ngModel]="sale?.vehiclePurchased?.vehicle?.vin"
										  (ngModel)="sale.vehiclePurchased.vehicle.vin = $event"
										  (ngModelChange)="sale.vehiclePurchased.vehicle.vin = $event"
										  name="vin"
										  #vin="ngModel"
										  required>
										</div>
										<div
										*ngIf="vin.errors && (vin?.touched || vin?.dirty)">
										<p *ngIf="vin?.errors?.required">VIN Is Required</p>
									  </div>
									  </div>
									 <!--Year-->
									 <div class="col-md-12 cstm-col">
										<label (dblclick)="toggleValue2('Year')" >{{labelYear}}</label>
										<input autocomplete="Year-name" list="year" name="state-choice" 
										[ngModel]="sale?.vehiclePurchased?.vehicle?.year"
										(ngModel)="sale.vehiclePurchased.vehicle.year = $event"
										(ngModelChange)="sale.vehiclePurchased.vehicle.year = $event"
										name="year"
										#year="ngModel"/>
										  <datalist autocomplete="Year-name" id="year" class="select-input">
											<option *ngFor="let item of vehicleYears" value="{{item.value}}">
										  </datalist>
									  </div>
									  <!--Make-->
									  <div class="col-md-12 cstm-col">
										<label (dblclick)="toggleValue2('Make')" >{{labelMake}}</label>
										<input autocomplete="make-name" list="make" name="state-choice" 
										[ngModel]="sale?.vehiclePurchased?.vehicle?.make"
										(ngModel)="sale.vehiclePurchased.vehicle.make = $event"
										(ngModelChange)="sale.vehiclePurchased.vehicle.make = $event"
										name="make"
										#make="ngModel"/>
										  <datalist autocomplete="off" id="make" style="max-height: 20px !important;" class="select-input">
											<option *ngFor="let item of vehicleMakes" value="{{item.make_Name}}">
										  </datalist>
									  </div>
								<!--Model-->
								<div class="col-md-12 cstm-col">
									<label (dblclick)="toggleValue2('Model')" >{{labelModel}}</label>
									<input autocomplete="model-name" list="model" name="state-choice" 
									[ngModel]="sale?.vehiclePurchased?.vehicle?.model"
									(ngModel)="sale.vehiclePurchased.vehicle.model = $event"
									(ngModelChange)="sale.vehiclePurchased.vehicle.model = $event"
									name="model"
									#model="ngModel"/>
								  </div>
									 <!--Exterior Color 1-->
									 <div class="col-md-12 cstm-col">
										<label (dblclick)="toggleValue2('Ext Color 1')" >{{labelExertiorColor1}}</label>
										<input autocomplete="puchasedAs-name"  list="extColor" name="state-choice" 
										[ngModel]="sale?.vehiclePurchased?.vehicle?.exteriorColor1"
										(ngModel)="sale.vehiclePurchased.vehicle.exteriorColor1 = $event"
										(ngModelChange)="sale.vehiclePurchased.vehicle.exteriorColor1 = $event"
										name="exteriorColor1"
										#exteriorColor1="ngModel"/>
										  <datalist id="extColor" class="select-input">
											<option value="Silver">
											<option value="Black">
										  </datalist>
									  </div>
									 <!--SERIES-->
									 <div class="col-md-12 cstm-col">
										<label (dblclick)="toggleValue2('Style')" >{{labelStyle}}</label>
										<input autocomplete="v-name"  list="style" name="state-choice" 
										  [ngModel]="sale?.vehiclePurchased?.vehicle?.series"
										  (ngModel)="sale.vehiclePurchased.vehicle.series = $event"
										  (ngModelChange)="sale.vehiclePurchased.vehicle.series = $event"
										  name="series"
										  #series="ngModel"/>
										  <datalist id="style" class="select-input">
											<option value="2 Door">
											<option value="4 Door">
										  </datalist>
									  </div>
								  <!--Displacement-->
								  <div class="col-md-12 cstm-col">
									<label (dblclick)="toggleValue2('Displacement')" >{{labelDisplacement}}</label>
									<input 
									  [ngModel]="sale?.vehiclePurchased?.vehicle?.vehicleEngines?.displacementCC"
									  (ngModel)="sale.vehiclePurchased.vehicle.vehicleEngines.displacementCC = $event"
									  (ngModelChange)="sale.vehiclePurchased.vehicle.vehicleEngines.displacementCC = $event"
									  name="displacementCC"
									  #displacementCC="ngModel"/>
									  
								  </div>
								 <!--Cylinders-->
								 <div class="col-md-12 cstm-col">
									<label (dblclick)="toggleValue2('Cylinders')" >{{labelCylinders}}</label>
									<input autocomplete="v-name"   name="state-choice" 
									[ngModel]="sale?.vehiclePurchased?.vehicle?.vehicleEngines?.engineCylinders"
									  (ngModel)="sale.vehiclePurchased.vehicle.vehicleEngines.engineCylinders = $event"
									  (ngModelChange)="sale.vehiclePurchased.vehicle.vehicleEngines.engineCylinders = $event"
									  name="engineCylinders"
									  #engineCylinders="ngModel"/>
								  </div>
									   <!--DECAL NUMBER-->
									   <div class="col-md-12 cstm-col">
										<label (dblclick)="toggleValue2('Decal Number')" >{{labelDecalNumber}}</label>
										<input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstm-input" 
										[ngModel]="sale?.vehiclePurchased?.itemVehicleTransientDetails?.decalNumber"
										(ngModel)="sale.vehiclePurchased.itemVehicleTransientDetails.decalNumber = $event"
										(ngModelChange)="sale.vehiclePurchased.itemVehicleTransientDetails.decalNumber = $event"
										name="decalNumber"
										#decalNumber="ngModel">
									  </div>
									 <!--tag-->
									 <div class="col-md-12 cstm-col">
										<label (dblclick)="toggleValue2('Tag')" >{{labelTag}}</label>
										<input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstm-input" 
										[ngModel]="sale?.vehiclePurchased?.itemVehicleTransientDetails?.tag"
										(ngModel)="sale.vehiclePurchased.itemVehicleTransientDetails.tag = $event"
										(ngModelChange)="sale.vehiclePurchased.itemVehicleTransientDetails.tag = $event"
										name="tag"
										#tag="ngModel">
									  </div>
								  <!--Tag Expires-->
								  <div class="col-md-12 cstm-col">
									<label (dblclick)="toggleValue2('Tag Expires')" >{{labelTagExpires}}</label>
									<input autocomplete="puchasedAs-name" type="date" value="" class="cstm-input" 
									[ngModel]="sale?.vehiclePurchased?.itemVehicleTransientDetails?.tagExpires | date:'yyyy-MM-dd'"
									(ngModel)="sale.vehiclePurchased.itemVehicleTransientDetails.tagExpires = $event"
									(ngModelChange)="sale.vehiclePurchased.itemVehicleTransientDetails.tagExpires = $event"
									name="tagExpires"
									#tagExpires="ngModel">
								  </div>
								  <!--MILEAGE-->
									<div class="sv-rw">
										<label (dblclick)="toggleValue2('Mileage')" >{{labelMileage}}</label>
										<input autocomplete="puchasedAs-name" type="number" value="0" class="cstmnumeric-input" 
										[ngModel]="sale?.vehiclePurchased?.itemVehicleTransientDetails?.mileage || 0"
										(ngModel)="sale.vehiclePurchased.itemVehicleTransientDetails.mileage = $event"
										(ngModelChange)="sale.vehiclePurchased.itemVehicleTransientDetails.mileage = $event"
										name="mileage"
										#mileage="ngModel">
									</div>
									<div class="sv-rw">
										<label (dblclick)="toggleValue2('Weight')" >{{labelWeight}}</label>
										<input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstmnumeric-input" 
										[ngModel]="sale?.vehiclePurchased?.vehicle?.weight || 0"
										(ngModel)="sale.vehiclePurchased.vehicle.weight = $event"
										(ngModelChange)="sale.vehiclePurchased.vehicle.weight = $event"
										name="weight"
										#weight="ngModel">
									</div>
										</div></div>
									
								</div>
							</div>

							<div class="col-md-6 right-side-deal-info">
								<div class="pickup-sec">
									<h2 class="pick-title" [hidden] ="!isTrade1" >Trade One Vehicle</h2>
									<h2 class="pick-title" [hidden] ="!isTrade2" >Trade Two Vehicle</h2>
									<div class="sv-rw trade-btn-rw">
										<label></label>
										<button (click)="trade1Click()" class="v-btn vb-1">Vehicle 1</button>
										<button [hidden] ="!isTrade1" (click)="trade2Click()"  class="v-btn vb-2">Vehicle 2</button>
									</div>
									<app-trade-vehicle [sale] = "sale" [hidden] ="!isTrade1" ></app-trade-vehicle>
									<app-tradein2-vehicle [sale] = "sale" [hidden] ="!isTrade2" ></app-tradein2-vehicle>
								</div>
							
							</div>
							
						</div>
					</div>
				</div>
				<div class="customer-sidebar col-md-2">
					<div class="inner-sidebar">
						<ul>
					
							<li>
								<a href="javascript:void(0)" (click)="update()" >
									<img src="assets/images/sidebar-icon-2.jpg">
									<span>Save</span>
								</a>
							</li>
							<li>
								<a href="javascript:void(0)" (click)="tabelViewClick()">
								  <img src="assets/images/sidebar-icon-9.jpg">
								  <span>Table View</span>
								</a>
							  </li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	
