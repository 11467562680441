import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SupportSubMenus } from '../_models/DTOS/pdfdtos/pdfimportpdfsdtos/SupportSubMenus';
import { SupportMenu } from '../_models/SupportMenus/SupportMenu';
import { SupportRequest } from '../_models/SupportRequest';



@Injectable({
  providedIn: 'root'
})
export class VideoService {
  baseUrl = environment.apiUrl;
  supportUrl = environment.supportUrl;
  private currentSupportRequest = new BehaviorSubject<SupportRequest>(null);
  supportRequest$ = this.currentSupportRequest.asObservable();
constructor(private http: HttpClient) { }
// http://localhost:5000/api/techsupport/supportrequest

getVideosMenus(parentGuid: any): Observable<SupportSubMenus[]> {
 return this.http.get<SupportSubMenus[]>(this.baseUrl + 'videoimport/getsupportsubmenusbymenutypestring/' + parentGuid + '/' + 'CloudVideos');
}

changeCurrentSupportRequest(t: SupportRequest) {
  this.currentSupportRequest.next(t);
}
getSubMenuById(menuId) {

}
// getSubMenuById(menuid: number): Observable<SupportSubMenus[]> {
//     if (this.supportSubMenusCache.length > 1) { console.log('returned cached sub menus'); return of(this.supportSubMenusCache);   }
//     return this.http.get<SupportSubMenus[]>(this.baseUrl + 'supportmenus/submenuvideoitems/' + this.authApi + '/' + menuid);
//   }

}
