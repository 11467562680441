import { Component, OnInit } from '@angular/core';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.css']
})
export class NavListComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;

  constructor(private authService: AuthService
   ) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
  }
  buttonClick() {
    console.log('button click');
  }
}
