<p *ngIf = "!isGuidVerified && isStartVerificationTrue"> 
  {{passwordResetGuid}}
</p>
<div *ngIf = "isStartVerificationTrue">
  <p> Please check your email for a verification link.</p>
</div>

<form *ngIf = "isGuidVerified" #editForm="ngForm" id="editForm" (ngSubmit)="editForm.valid && register()" autocomplete="off">
  <h2 class="text-center text-primary">Reset password</h2>
  <hr>

  <div class="form-inline">

    <label for="city">New password</label>
    <input
    [ngModel]="user?.newPassword"
    (ngModel)="user.newPassword = $event"
    (ngModelChange) = "user.newPassword = $event; "
    type="text" name="newPassword" class="form-control mx-2">
    </div>

 <div class="form-inline">
    <label for="city">Comfirm New Password </label>
    <input 
    [ngModel]="user?.comfirmPassword"
    (ngModel)="user.comfirmPassword = $event"
    (ngModelChange) = "user.comfirmPassword = $event; "
    type="text" name="comfirmPassword" 
    class="form-control mx-2">
  </div>

  <div class="form-group text-center">
      <button [disabled]='!editForm.valid' (click) = "register()" class="btn btn-success mr-2" type="submit">Reset Password</button>
  </div>
</form>