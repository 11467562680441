import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from './_models/user';
import { AccountService } from './_services/account.service';
import { PresenceService } from './_services/presence.service';
import { AuthService } from './_services/auth.service';
import { UserAssignedToCompany } from './_models/userAssignedToCompany';
import { VendorService } from './_services/vendorservices/vendor.service';
import { ToastrService } from 'ngx-toastr';
import { FormNameLabelsService } from './_services/formLabelservices/form-name-labels.service';
import { FormName } from './_models/formName';
import { SupportUser } from './_models/supportUser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Carlot Manager Software';
  users: any;

  constructor(private accountService: AccountService,
    private presence: PresenceService,
    private vendorService: VendorService,
    private authService: AuthService,
    private alertify: ToastrService) {}

  ngOnInit() {
    const selectedLabels: FormName[] = JSON.parse(localStorage.getItem('formsLabels'));
      if (selectedLabels) {
      this.authService.changeFormLabels(selectedLabels);
      }
    this.setCurrentUser();
  }
  getDropDowns() {

}
  setCurrentUser() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    if (user) {
      this.accountService.setCurrentUser(user);
      this.presence.createHubConnection(user);
    }
      const supportUser: SupportUser = JSON.parse(localStorage.getItem('supportUser'));
      if (user) {
        this.accountService.setCurrentSupportUser(supportUser);
      }
    if (localStorage.getItem('company') != null) {
      const selectedCompany: UserAssignedToCompany = JSON.parse(localStorage.getItem('company'));
      if (selectedCompany) {
      this.authService.changeCompany(selectedCompany);
      }
    }
    if (localStorage.getItem('userAssignedToCompany') != null){
      const userAssignedToComp: UserAssignedToCompany[] = JSON.parse(localStorage.getItem('userAssignedToCompany'));
      this.authService.changeParentUserAssignedCompanies(userAssignedToComp);
    }

  }
}
