import { Component, OnInit, ElementRef, ViewChild, Output, EventEmitter, OnChanges, Input  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { PdfForms } from 'src/app/_models/PdfForms';
import { ToastrService } from 'ngx-toastr';
import { PdfFormTypes } from 'src/app/_models/PdfFormTypes';
import { WindowRef } from 'src/app/_services/PdfSErvices/WIndowREF';
import { PdfFormPackages } from 'src/app/_models/pdfformpackages';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';
import { PdfPackageService } from 'src/app/_services/PdfSErvices/pdf-package.service';
import { PdfMergingComponent } from '../pdf-merging/pdf-merging.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PdfnewuploadnameModalComponent } from 'src/app/modals/pdfnewuploadname-modal/pdfnewuploadname-modal.component';
import { ConfirmService } from 'src/app/_services/confirm.service';
import printJS from 'print-js';
import { ConvertPdfToArraysAndBuffers } from 'src/app/_classes/pdffielddatats/convertpdf-toarrays';
import { SaleService } from 'src/app/_services/saleservices/sale.service';
import { Sale } from 'src/app/_models/sale';
import { PdfMergePdfs } from 'src/app/_classes/pdffielddatats/merge-pdfdocuments';
import { PdfFieldNameType } from 'src/app/_models/PdfFieldNameType';
import { PDFCreateNew } from 'src/app/_classes/pdfCreate/PdfCreateNew';
import { PdfForCreationCustom } from 'src/app/_models/DTOS/PdfForCreationCustom';
import { PdfViewSizedComponent } from '../Pdf-View-Sized/Pdf-View-Sized.component';
import { PdfFIeldName } from 'src/app/_models/PdfFiledName';
import { PackageButtonLocations } from 'src/app/_models/PackageButtonLocations';
import { ListService } from 'src/app/_services/listServices/list.service';
import { PdfFormTypesCategory } from 'src/app/_models/PDFentites/PdfFormTypesCategory';
import { PdfStateList } from 'src/app/_models/PDFentites/PdfStateList';
import { animate, style, transition, trigger } from '@angular/animations';
import { SalesListComponent } from './selectiddatagrids/sales-list/sales-list.component';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Item } from 'src/app/_models/item';
import { PdfEntityTypeForDataType } from 'src/app/_classes/pdfdataclasses/pdfEntityModelTypesDataRetrieve';
import { PdfMergeInventoryPdfs } from 'src/app/_classes/pdffielddatats/merge-inventorydocuments';
import { InventorylistComponent } from './selectiddatagrids/inventorylist/inventorylist.component';
import { NewNameModel } from '../pdf-grid/pdfnewuploadnamemodalmodel';
import { PdfMergingitemsComponent } from '../pdf-mergingitems/pdf-mergingitems.component';
@Component({
  selector: 'app-resports-manager',
  templateUrl: './resports-manager.component.html',
  styleUrls: ['./resports-manager.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('1500ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('1500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class ResportsManagerComponent implements OnInit, OnChanges {
  @ViewChild('navdrop') navdrop: ElementRef; // Angular Version 8
  id: any;
  // @ViewChild('navdrop') navdrop: ElementRef; // Angular Version < 8
  selectedCompany1: UserAssignedToCompany;
  isWithData = true;
  pdfForms = [] as PdfForms[];
  stateTexasPdfForms = [] as PdfForms[];
  stateKyPdfForms =  [] as PdfForms[];
  pdfForm = {} as PdfForms;
  formTypes = [] as PdfFormTypes[];
  formTypesCache = [] as PdfFormTypes[];
  formUrl: string;
  selectedItem: PdfForms;
  selectedType = {} as PdfFormTypes;
  selectedTypeId: any;
  selectedPdfCategoryType = {} as PdfFormTypesCategory;
  selectedPdfCategoryTypeId: any;
  isCategorySelected = false;
  isCollapsedCategory = false;
  selectedItems: PdfFormTypes;
  ifFormSelected = false;
  isEditMode = false;
  show: any;
  item = {} as Item;
  sale = {} as Sale;
  // salePackages = [] as PdfFormPackage[];
  // inventoryPackages = [] as PdfFormPackage[];
  hideme = {} as any;
  isMerging = false;
  typeIsFocus = false;
  bsModalRef: any;
  isUpload = false;
  base64: any;
  isTreeInventory = false;
  isTreeRealEstate = false;
  uint8array: any;
  formNameToUpload = '' as string;
  @ViewChild(PdfMergingComponent) child: PdfMergingComponent;
  @ViewChild(PdfMergingitemsComponent) childItems: PdfMergingitemsComponent;
  // @ViewChild(InventorylistComponent) childVehicle: InventorylistComponent;
  @ViewChild(PdfViewSizedComponent) viewer: PdfViewSizedComponent;
  // @ViewChild(SalesListComponent) saleListChild: SalesListComponent;
  // isCollapsed = true;
  showMenu = '';
  urLList = [];
  isCustomEdit = false;
  i = 1 as number;
  isNavOpen = false;
  isEmptyForm = false;
  public isCollapsed = false;
  isCustom = false;
  isBuyer = false;
  isCobuyer = false;
  isSoldVehice = false;
  isTrade1 = false;
  isTree = true;
  isTrade2 = false;
  isDealInfo = false;
  isTaxes = false;
  isLoan = false;
  isLienHolder = false;
  isDates = false;
  isCashDown = false;
  isDealInfoDollars = false;
  isDealer = false;
  isActiveVehicle = false;
  @Output() isNewFormTypeClick: boolean;
  isNewFormTypeClickLabel = 'Create Form Type.';
  isCreateFormClickDivideVisible = false;
  file: File;
  saleId: number;
  pdfToUpload: PdfForCreationCustom;
  personType = {} as any;
   pdfFieldNameTypes: PdfFieldNameType[];
   pdfFormTypesCategorys = [] as PdfFormTypesCategory[];
   pdfFormTypesCategorysDefault = {} as PdfFormTypesCategory;
   pdfFormTypesCategorysId = 0;
  person = {} as PdfFieldNameType;
  isNotTree = true;
  packageButtonLocations = [] as PackageButtonLocations[];
  editLabel = 'Edit Pdf';
  stateName: any;
  pdfStateList = [] as PdfStateList[];
  selectedStateListId: any;
  selectStateList: any;
  pdfStateListDefault = {} as PdfStateList;
 isUploadPhotoButtonLive = false;
 isVehicleGrid = false;
 isSaleGrid = false;
 sales = [] as Sale[];
 isNewPdfFormPackage = false;
  constructor(private winRef: WindowRef,
    private alertify: ToastrService,
    private pdfEntityTypeForDataType: PdfEntityTypeForDataType,
    private saleService: SaleService,
    private pdfPackageService: PdfPackageService,
    private pdfService: PdfService,
    private pdfMergePdfs: PdfMergePdfs,
    private pdfMergeVehicles: PdfMergeInventoryPdfs,
    private convertPdfToArraysAndBuffers: ConvertPdfToArraysAndBuffers,
    private router: Router,
    private confirmService: ConfirmService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private pDFCreateNew: PDFCreateNew,
    private modalService: BsModalService,
    private listServices: ListService) {}

    isActive = false;

 ngOnChanges() {
  this.pdfService.emittedpdfFieldNameTypes.subscribe(data => {
    this.pdfFieldNameTypes = data;
    console.log(data);
    this.getPdfFieldNameTypes();
    this.tabClicked('isCustom'); });
 }
 toggleHamburger(x) {
  x.classList.toggle('change');
}
  ngOnInit() {
    this.route.data.subscribe(data => {
      this.sales = data.sales.result;
      this.pdfStateList = data.pdfStateList;
      data.pdfStateList.forEach((element: PdfStateList) => {
        if (element.isDefault) {
          this.pdfStateListDefault = element;
          this.selectedStateListId = element.id;
        }
      });
    });
    this.pdfStateList = this.pdfStateList.sort((a, b) => a.fullName > b.fullName ? 1 : -1);
   // this.pdfStateList.sort((a, b) => a.fullName.localeCompare(b.fullName));
    this.getPdfFieldNameTypes();
    this.pdfService.changeIsEmpty(this.isEmptyForm);
    this.pdfService.changeIsWithData(this.isWithData);
    this.saleId = this.sales[1].id;
    this.authService.company.subscribe(data => {this.selectedCompany1 = data;   this.getPdfBUttonLocations(data.parentGuid); } );
    this.getPdfForms();
    this.isMerging = false;
    this.pdfService.emittedpdfFieldNameTypes.subscribe(data => { this.pdfFieldNameTypes = data;
     if (this.isCustom) {  this.tabClicked('isCustom'); }
    });
    this.selectedStateChanged( this.pdfStateListDefault.id);
   // this.getFormPackagesSetDeleteCache();
  }

  /**
   * Submit Button Next to State List. Reloads all states.
   */
  getRefreshStateList() {
    this.pdfService.getPdfStateList(this.selectedCompany1.parentGuid).subscribe(data => {
      this.pdfStateList = data;
      data.forEach(element => {
        if (element.isDefault) {
          this.pdfStateListDefault = element;
          this.selectedStateChanged(element.id);
        }
      });
    }, error => {}, () => { this.alertify.success('States Refreshed.'); });
  }
   /**
    * Switch states inside functions and from select.
    * @param(id) the state of selected or sent id
    */
  selectedStateChanged(e?) {
    this.selectedStateListId = e;
    this.isNewFormTypeClick  = false;
    this.isCategorySelected = false;
    this.isUploadPhotoButtonLive = false;
      this.pdfService.getPdfFormTypeCategoryListByStateId(this.selectedCompany1.parentGuid, e).subscribe((data: PdfFormTypesCategory[]) => {
        this.pdfFormTypesCategorys = data;
         }, error => { this.alertify.error(error); console.log(error); }, () => {
           this.alertify.success('Pdfs Retrieved');
         });
  }
  createFormClickDivideVisible() { this.isCreateFormClickDivideVisible = !this.isCreateFormClickDivideVisible; }

  deleteFormType() {}
  getPdfBUttonLocations(parentGuid) {
    this.pdfPackageService.listPackageButtonLocations(parentGuid).subscribe(data => {
      this.packageButtonLocations = data;
      this.listServices.changePackageButtonLocations(data);
    }, error => {
      console.log(error);
      this.alertify.error(error);
    }, () => {});
  }
/**
 * GETS sale data of id selected for pdfs
 */
  getData(id?, vehicle?: Item) {
    console.log(vehicle);
    console.log(id);
    this.pdfService.getSaleAccountId().subscribe(data => {
      if (id > 0) {
        this.saleId = id;
      } else {
        if (this.saleId === 0 || this.saleId === undefined) {
          this.saleId = 34;
        }
      }
      this.saleId = data;
      console.log('saleID: ' + data);
      console.log(data);
    }, (error) => { this.alertify.error(error); console.log(error); });
    this.saleService.getSaleById(this.selectedCompany1.parentGuid,   this.saleId   ).subscribe(data => {
      this.sale = data;
      if (this.selectedItem != null && this.selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales') {
        // this.saleListPrintClick();
        this.isNewFormTypeClick  = true;
        this.isNewFormTypeClick = true;
        this.pdfMergePdfs.copyPages(this.sale, this.selectedItem.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm).then(
          data1 => {
           // this.alertify.success('Pdfs Populated.');
          });
      }
      if (vehicle != null) {
        // console.log('REACHED VEHICLE POPULATE');
        this.isNewFormTypeClick  = true;
        this.isNewFormTypeClick = true;
        this.pdfMergeVehicles.copyPages(vehicle, this.selectedItem.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm).then(
          data1 => {
            this.alertify.success('Pdfs Populated.');
          });
      }
    }, error =>  { console.log(error); }, () => {
      this.alertify.success('Sale Loaded');
      // if(this.pdfForm != null) {  this.saleListPrintClick(); }
    });
  }
  editPdf(e?) {
  //  this.tabClicked('isTree');
    //  this.isUpload = true;
    //  this.isMerging = true;
    //  this.isCustomEdit = false;
    console.log(this.isCustomEdit);
    if (this.isCustomEdit) {
      //  this.isUploadPhotoButtonLive = !this.isCustomEdit;
      //  this.pdfService.getPdfStateList(this.selectedCompany1.parentGuid).subscribe(data => {
        //  this.pdfStateList = data;
          this.editLabel = 'Edit Pdf';
     //     this.isCollapsedCategory = !this.isCollapsedCategory;
          this.isUploadPhotoButtonLive = false;
          this.isCategorySelected = false;
      //  });
        } else {
          this.editLabel = 'Close Pdf';
          this.isUpload = false;
          this.isMerging = false;
          this.isUploadPhotoButtonLive = false;
          this.isCategorySelected = false;
          // this.isEditMode = false;
         // this.isCustomEdit = true;
        // this.isCustomEdit = !this.isCustomEdit;
        }
        this.isCustomEdit = !this.isCustomEdit;
        console.log(this.isCustomEdit);
  }
  typeSelected(formType: PdfFormTypes) {
      this.typeIsFocus = true;
      this.selectedType = formType;
        this.isCollapsed = !this.isCollapsed;
        this.selectedType.id = formType.id;
        this.isCategorySelected = true;
        this.isUploadPhotoButtonLive = true;
        this.pdfService.changePdfFormType(formType);
    }
    typeCategorySelected(r: PdfFormTypesCategory) {
    //  this.isCategorySelected = false;
    this.typeIsFocus = false;
      if (this.pdfFormTypesCategorysId !== r.id) {
        this.isCategorySelected = true;
        this.isCollapsedCategory = !this.isCollapsedCategory;
        if (r.pdfEntityModelTypes.value === 'Sales' ) {
          this.isNewFormTypeClick = true;
        }
        //  && this.childVehicle
        if (r.pdfEntityModelTypes.value === 'Inventory') {
          this.isNewFormTypeClick = true;
        }
      }
      this.pdfFormTypesCategorysId = r.id;
      this.selectedPdfCategoryType = r;
      this.selectedPdfCategoryTypeId = r.id;
      this.formTypes = r.pdfFormTypes;
      this.isCollapsedCategory = !this.isCollapsedCategory;
      this.stateName = r?.pdfStateList?.fullName;
      this.isUploadPhotoButtonLive = false;
    //  this.isNewFormTypeClick = false;
    }
  dropClick() {
   // this.navdrop.nativeElement.classList.toggle('visibility');
  }
  packageClick() {
  this.isMerging = !this.isMerging;
    this.isEditMode = false;
  }
    // this.pdfMergePdfs.copyPages(this.sale, this.selectedItem.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm).then(
    //   data => {
    //     this.alertify.success('Pdfs Populated.');
    //   });
  /**
   * REPLACED OLD MEthod with glOBALS TO USE ANYWWHERE SEND THE ENTIRE PDFFORM TO GET ENTITY TYPE
   * ADding Switch in PDFFORMS, For Type, of data in old program we made INF file
   * this.pdfMergePdfs.copyPages(this.sale, this.selectedItem.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm).then(
   *   data => {
   *     this.alertify.success('Pdfs Populated.');
   *   });
   */
  copypagesFillData(sale: Sale, selectedItem: PdfForms) {
    let entity: any;
  if (sale.id > 0 && selectedItem.id > 0) {
    this.pdfService.changePdfForm(selectedItem);

    this.pdfEntityTypeForDataType.parsePdfEntityTypeForDataType(selectedItem, sale, this.item).subscribe(data => {
      entity = data;
      this.pdfEntityTypeForDataType.copyPagesPerAnyEntity(sale, selectedItem, this.urLList, this.isWithData, this.isEmptyForm)
      .subscribe(data1 => {
        if (data1) {
          this.alertify.success('Copy Pages works');
        }
      });
    });
    } else {
      // NEED MASTER DATA CLASS INSTEAD OF PER CALL.
      this.saleService.getSaleById(this.selectedCompany1.parentGuid,   this.saleId   ).subscribe(data => {
        this.sale = data;
        if (this.selectedItem != null) {
          // this.saleListPrintClick();
        }
      }, error => {}, () => {
        this.pdfEntityTypeForDataType.parsePdfEntityTypeForDataType(selectedItem, sale, this.item).subscribe(data => {
          entity = data;
          this.pdfEntityTypeForDataType.copyPagesPerAnyEntity(sale, selectedItem, this.urLList, this.isWithData, this.isEmptyForm)
          .subscribe(data1 => {
            if (data1) {
              this.alertify.success('Copy Pages works');
            }
          });
        });
      });
    }
  }
   /**
    * ADding Switch in PDFFORMS, For Type, of data in old program we made INF file for filters of data.source
    * here we simply say entity name sale/item/vehicle ... etc. PdfEntityModelTypes or PdfEntityModelTypesId
    * @param PdfEntityModelTypes.Value = Sales
    * @param PdfEntityModelTypes.Value = Inventory
    * @param PdfEntityModelTypes.Value = People
    * @param PdfEntityModelTypes.Value = Recievables
    * @param PdfEntityModelTypes.Value = Payables
    * @param PdfEntityModelTypes.Value = Company
    * @param PdfEntityModelTypes.Value = Real Estate
    * @param PdfEntityModelTypes.Value = Service
    * @param PdfEntityModelTypes.Value = POS
    * @param PdfEntityModelTypes.Value = EACH MODEL WILL BE A API branch
    * carlot manager to team work the larger cloud
    * @param take partial shot of api, push it to open source as directed
    * sets print up if form is slected after type
    * form must be selected to print etc... not just formtype
    * when user looses focus on form as well
    */
  async pdfSelectedTreeView(item: PdfForms) {
  if (!this.isCustomEdit && !this.isMerging ) {
    this.isNewFormTypeClick = true;
  }
  console.log(item);
  //  console.log(item.pdfEntityModelTypes.value);
    this.typeIsFocus = false;
    this.selectedItem = item;
    this.ifFormSelected = true;
   
    this.isEditMode = false;
    let entity: any;
    if (this.selectedPdfCategoryType.value === 'Sales') {
      this.pdfEntityTypeForDataType.parsePdfEntityTypeForDataType(item, this.sale, this.item).subscribe(data => {
        entity = data;
        if (item.id > 0 && data) {
          this.pdfEntityTypeForDataType.copyPagesPerAnyEntity(data, item, this.urLList, this.isWithData, this.isEmptyForm)
          .subscribe(data1 => {
            if (data1) {
              this.alertify.success('Copy Pages Complete!');
            }
          });
        }
      });
    }
    // this.pdfService.changePdfForm(item);
    //  if (this.sale.id > 0 ) {
    //   this.pdfMergePdfs.copyPages(entity, item.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm).then(
    //       data => {
    //     this.alertify.success('Pdfs Populated.');
    //   });
    //  }
    await this.pdfMergePdfs.convertPdfFormTOUintByteArray(entity, item).then(data => { this.pdfService.changeUint8ByteArray(data); });
    if (this.child !== undefined) {
      this.child.subscribeToPdfFormSelectedOnParent(item);
    }
    if (this.childItems !== undefined) {
      this.childItems.subscribeToPdfFormSelectedOnParent(item);
    }
  }
   /**
    * copy all array pages shopping cart PDF Files into searchable grid paged.
    */
  importPdfs() { console.log('importpdfreached');   this.router.navigate(['/reportsmanager/pdfimport']); }
  /**
   * Upon uploading a pdf is completing functions
   */
  getPdfFormsOnUploadComplete() {
    this.isUpload = false;
    this.getPdfForms();
  }
  sortBy(prop: string) {
    return this.pdfStateList.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
  setIsNewPdfFormPackageFromChild() {
    this.isNewPdfFormPackage =  !this.isNewPdfFormPackage;
  }
  /**
   * sets multiple items. Sets PdfStateListDefault
   * gets PdfStateList
   * gets pdfCategoryList by state id
   * sets pdfFormPackages
   */
  getPdfForms() {
    this.pdfService.getPdfStateList(this.selectedCompany1.parentGuid).subscribe(data => {
      this.pdfStateList = data;
      data.forEach(element => {
        if (element.isDefault) {
          this.pdfStateListDefault = element;
          this.selectedStateListId = element.id;
        }
      });
    });
   // this.pdfStateList = this.pdfStateList.sort((a, b) => a.fullName > b.fullName ? 1 : -1);
    this.pdfService.getPdfFormTypeCategoryListByStateId(this.selectedCompany1.parentGuid, this.pdfStateListDefault.id).subscribe(data => {
   this.pdfFormTypesCategorys = data;
    }, error => { this.alertify.error(error); console.log(error); }, () => {
      this.alertify.success('Pdfs Retrieved');
      
   //  this.getFormPackagesSetDeleteCache();
     });
  }
  /**
   * UNSURE IF USED TEST
   */
  editClicked(item?: any) {
  //  this.router.navigate(['/reportsmanager/saleformeditor/' + this.pdfForm.id]);
    if ( item !== undefined && this.isCustomEdit && !this.isUpload && !this.isEditMode && !this.isMerging) {
      this.pdfService.changePdfForm(item);
      this.pdfForm = item;
      this.viewer.changeOnReportClick(item);
    }
  }
  /**
   * UNSURE IF USED TEST
   */
  changeIsWithData(isempty?: any){
    if (isempty === 'emptyform') {
      this.isEmptyForm = true;
      this.pdfService.changeIsEmpty(true);
      // this.fillForm();
    }
    else {
      this.isWithData = !this.isWithData;
      if (this.isWithData) { this.getData(); }
      this.pdfService.changeIsWithData(this.isWithData);
      this.pdfService.changeIsEmpty(false);
      this.isEmptyForm = false;
    //  this.fillForm();
    }


  }
  /**
   * this.pdfService.getUint8ByteArray().subscribe(data => {
   *   this.uint8array = data;
   * });
   * Converts to Blob to view
   */
  viewPdf(e) {
    this.pdfService.getUint8ByteArray().subscribe(data => {
      this.uint8array = data;
    });
    const blob = new Blob([this.uint8array], {type: 'application/pdf'});
    const link = document.createElement('a');
   link.href = window.URL.createObjectURL(blob);
   const blobURL = URL.createObjectURL(blob);
    window.open(blobURL);
  }
   /**
    * this.pdfService.getUint8ByteArray().subscribe(data => {
    *   this.uint8array = data;
    * });
    * Converts to Blob to view
    */
  PrintPdf(e) {
    this.pdfService.getBase64Array().subscribe(data => {
     this.base64 = data;
    });
    printJS({printable: this.base64, type: 'pdf', showModal: true, base64: true});
  }
   /**
    * this.pdfService.getUint8ByteArray().subscribe(data => {
    * this.uint8array = data;
    * });
    * Converts to Blob to download
    */
  downloadPdf(e) {
    this.pdfService.getUint8ByteArray().subscribe(data => {
     this.uint8array = data;
    });
    const blob = new Blob([this.uint8array], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const blobURL = URL.createObjectURL(blob);

        const fileName =  this.selectedItem.name; //  this.selectPdf.name;
        link.download = fileName;
        link.click();

  }
/**
 * this.formNameToUpload = values.name;
 *         this.isUpload = true;
 * creates these in popup
 */
  uploadPdf() {
    this.pdfForm.pdfEntityModelTypesId = this.selectedPdfCategoryType.pdfEntityModelTypesId;
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
       sale: this.pdfForm,
       selectedType: this.selectedType,
       pdfFormTypes: this.formTypes,
       packageButtonLocations: this.packageButtonLocations
      }
    };
    this.bsModalRef = this.modalService.show(PdfnewuploadnameModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe(values => {
      this.formNameToUpload = values.name;
      if (values.name) {
        if (values.name !== '' || values.name !== undefined) {
          if (!this.selectedType && !values.name) {
            alert('Select a Folder');
            this.isUpload = false;
             } else {
               this.formNameToUpload = values.name;
              this.isUpload = true;
            }
        }
      }
    });
  }
/**
 * this.isEditMode = false;
 * this.isUpload = true;
 *  console.log('uploadcompete or started?');
 */
  privateIsUploadRemoteClick() {
    console.log('uploadcompete or started?');
    this.isUpload = true;
    this.isEditMode = false;
  }
/**
 * sets pdfForms.Deleted Date to date or null
 */
  deletePdf() {
    this.confirmService.confirm('Confirm delete message', 'Are you sure?').subscribe(result => {
      if (result) {
      this.pdfService.deleletePdf(this.selectedCompany1.parentGuid, this.selectedItem.id)
         .subscribe(data => {
         this.getPdfForms();
         }, error => { this.alertify.error(error);  }, () => {
           this.alertify.success('Pdf Deleted.');
         });
      }
        });
        this.isNewFormTypeClick  = false;
  }
/**
 * Grid Of All pdfs in client cloud
 */
  gridView() {
    this.router.navigate(['reportsmanager/gridview']);
  }
/**
 * Data fields selected sales, inventory, etc.
 */
  tabClicked(e) {
    this.isCustom = false;
    this.person = null;
    this.isActiveVehicle = false;
    this.personType = null;
    this.isBuyer = false;
    this.isCobuyer = false;
    this.isSoldVehice = false;
    this.isTrade1 = false;
    this.isTree = false;
    this.isTreeRealEstate = false;
    this.isTrade2 = false;
    this.isDealInfo = false;
    this.isLoan = false;
    this.isLienHolder = false;
    this.isDates = false;
    this.isTaxes = false;
    this.isDealInfoDollars = false;
    this.isCashDown = false;
    this.isDealer = false;
    // if (e === 'isTreeRealEstate') {
    //   this.isNotTree = true;
    //   this.isTreeRealEstate = true;
    //   this.isTreeInventory = false;
    //   this.formTypes =  this.formTypesCache.filter(x => x.pdfFormTypeCategorys?.value === 'RealEstate');
    // }
    // if (e === 'isTreeInventory') {
    //   this.isNotTree = true;
    //   this.isTreeInventory = true;
    //   this.formTypes =  this.formTypesCache.filter(x => x.pdfFormTypeCategorys?.value === 'Inventory');
    // }
    if (e === 'isTree') {
    //  this.formTypes =  this.formTypesCache.filter(x => x.pdfFormTypeCategorys?.value === 'Sales');
      this.isNotTree = true;
      this.isTreeInventory = false;
      this.isTree = true;
      if (this.isMerging || this.isUpload) {
        this.isCustomEdit = false;
      }
      this.isUpload = false;
      this.isMerging = false;
    //  this.isCustomEdit = false;
    }
    if (e === 'isActiveVehicle') {
      this.isActiveVehicle = true;
      this.isNotTree = false;
      this.personType = 'av';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
    }
    if (e === 'isBuyer') {
      this.isBuyer = true;
      this.isNotTree = false;
      this.personType = 'b';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
    }
    if (e === 'isCobuyer') {
      this.isNotTree = false;
      this.isCobuyer = true;
      this.personType = 'bb';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
    }
    if (e === 'isSoldVehice') {
      this.personType = 'sv';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isSoldVehice = true;
    }
    if (e === 'isTrade1') {
      this.personType = 't';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isTrade1 = true;
    }
    if (e === 'isTrade2') {
      this.personType = 'tt';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isTrade2 = true;
    }
    if (e === 'isDealInfo') {
      this.personType = 'di';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isDealInfo = true;
    }
    if (e === 'isTaxes') {
      this.personType = 'dt';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isTaxes = true;
    }
    if (e === 'isDealInfoDollars') {
      this.personType = 'did';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isDealInfoDollars = true;
    }
    if (e === 'isLoan') {
      this.personType = 'al';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isLoan = true;
    }
    if (e === 'isLienHolder') {
      this.personType = 'vsl';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isLienHolder = true;
    }
    if (e === 'isDates') {
      this.isNotTree = false;
      this.isTree = false;
      this.isDates = true;
    }
    if (e === 'isCashDown') {
      this.personType = 'dd';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isCashDown = true;
    }
    if (e === 'isDealer') {
      this.personType = 'co';
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isDealer = true;
    }
     if (e === 'isCustom') {
      this.personType = 'cst';
      this.pdfService.getpdfFieldCstCalculation().subscribe(data => {
        this.person = this.pdfFieldNameTypes.find(
          (x) => x.parseBy === this.personType
        );
        console.log(data);
      });
      this.person = this.pdfFieldNameTypes.find(
        (x) => x.parseBy === this.personType
      );
      this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
      this.isNotTree = false;
      this.isTree = false;
      this.isCustom = true;
    }
  }

  getPdfFieldNameTypes() {
    this.pdfService.getPdfFieldNameTypes().subscribe(
      (data) => {
        this.pdfFieldNameTypes = data;
        this.pdfService.pdfFieldNameTypesCached = data;
        this.pdfService.setPdfFieldNameType(data);
      },
      (error) => {
        this.alertify.error(error); console.log(error);
      },
      () => {
        this.isNotTree = true;
        this.isTree = true;
        this.personType = 'b';
        this.person = this.pdfFieldNameTypes.find(
          (x) => x.parseBy === this.personType
        );
        this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
        this.alertify.success('Fields Retrieved');
      }
    );
  }

   createPdf() {
 // await this.pDFCreateNew.createPdf('New Doc', 'DealInfoDollars.pdf').then(data => { this.file = data; });
  const config = {
    class: 'modal-dialog-centered',
    initialState: {
     sale: this.pdfForm,
     pdfFormTypesCategorys: this.pdfFormTypesCategorys
    }
  };
  this.bsModalRef = this.modalService.show(PdfnewuploadnameModalComponent, config);
  this.bsModalRef.content.emittedValues.subscribe((values) => {
    console.log(values.name);
     this.formNameToUpload = values.name;
     this.selectedTypeId = this.selectedType.id;
     this.isNewFormTypeClick  = false;
      // this.formTypes = this.formTypes.filter(data => data.id = values.pdfFormTypeId);
      // this.formTypes = this.formTypes.filter(data => data.id = values.pdfFormTypeId);
      // this.selectedType = this.formTypes[0];
    // this.selectedItem = values.callParentGetForms
    this.isUpload = true;
  });
  }

  mergePdf() {
    this.isUpload = false;
    this.isMerging = true;
    this.isCustomEdit = false;
  }

  inventoryListPrintClick(item?) {
    this.getData();
 // this.copypagesFillData(item, this.selectedItem);
  }

  saleListPrintClick(item) {
    console.log(item);
  //  this.getData(this.pdfForm, item);
 // this.copypagesFillData(item, this.selectedItem);
  }

  setStateList(e) {
    this.pdfStateList = e;
  }

  closeUploader(e) {
    this.isUpload = false;
  }

  createTypeClick(e?) {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
      }
    };
    this.bsModalRef = this.modalService.show(PdfnewuploadnameModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe(values => {
      this.formNameToUpload = values.name;
      this.pdfService.createPdfType(values.name, this.selectedPdfCategoryTypeId, this.selectedCompany1.parentGuid)
      .subscribe(data => {}, error => { console.log(error); }, () => { this.alertify.success('SUCCESS'); });
    });
  }

  editNameDblClickForm(e) {
    this.pdfForm = e;
    const config = {
      class: 'modal-lg',
      initialState: {
        pdfForm: e,
      }
    };
    this.bsModalRef = this.modalService.show(PdfnewuploadnameModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values: NewNameModel) => {
        this.pdfService.renameTreeView(values.name, this.pdfForm.id, values.isUseUniqueFileName).subscribe(data =>
          {}, error => { console.log(error); }, () => {
            this.alertify.success('Name Changed');
          });
    });
  }

  hamburgerClick() {
    console.log(this.selectedStateListId);
    this.pdfService.setDefaultStates(this.selectedCompany1.parentGuid, this.selectedStateListId).subscribe(data => {}, error => {}, () =>{});
  }
}
