<!-- <div class="customer-footer" (contextmenu)="onRightClick($event)" > -->
    <div class="container-fluid">
      <ul>
        <li><a href="javascript:void(0)">Hot Notes</a></li>
        <li ><a href="javascript:void(0)">Logged in as &nbsp;{{this.user.knownAs | titlecase}}</a></li>
        <li><a href="javascript:void(0)">{{today}}</a></li>
        <li><a href="javascript:void(0)">{{selectedCompany1?.companyName}}</a></li>
      </ul>
    </div>

