import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SaleChartsComponent } from './sale-charts/sale-charts.component';
import { SaleCustomerComponent } from './sale-customer/sale-customer.component';
import { LeftSidmenuComponent } from './left-sidmenu/left-sidmenu.component';
import { SaleDashboardComponent } from './sale-dashboard/sale-dashboard.component';
import { SaleGridComponent } from './sale-grid/sale-grid.component';
import { SaleDeskComponent } from './Sale-Desk/Sale-Desk.component';
import { SaleWashoutComponent } from './sale-washout/sale-washout.component';
import { SaleMiscinfoComponent } from './sale-miscinfo/sale-miscinfo.component';
import { SaleNotesComponent } from './sale-notes/sale-notes.component';
import { SaleVehicleComponent } from './sale-vehicle/sale-vehicle.component';
import { SaleNewComponent } from './sale-new/sale-new.component';
import { SaleRoutingModule } from './sale-routing.module';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { DealInforFormat } from '../_classes/dealinfoclasses/dealinfoformat';


import { DateFilterComponent } from '../shared/date-filter/date-filter.component';
import { SaleCobuyerComponent } from './sale-cobuyer/sale-cobuyer.component';
import { AddtaxdefaultsModalComponent } from '../modals/addtaxdefaults-modal/addtaxdefaults-modal.component';
import { WashoutGridComponent } from './washout-grid/washout-grid.component';
import { MiscchargesGridComponent } from './misccharges-grid/misccharges-grid.component';
import { Tradein2VehicleComponent } from './tradein2-vehicle/tradein2-vehicle.component';
import { TradeVehicleComponent } from './trade-vehicle/trade-vehicle.component';
import { SaleAmortizationComponent } from './sale-amortization/sale-amortization.component';
import { SaleamortisationModalComponent } from './sale-amortization/saleamortisation-modal/saleamortisation-modal.component';
import { SaleFormsComponent } from './sale-forms/sale-forms.component';
import { PrintPopupComponent } from './print-popup/print-popup.component';
import { SalesJournalComponent } from './sales-journal/sales-journal.component';
import { VehicleCardGridComponent } from '../shared/vehicle-card-grid/vehicle-card-grid.component';
import { AdvancedComponent } from './sale-grid/advanced/advanced.component';
import { SalewashoutPaymentsrecievedComponent } from './salewashout-paymentsrecieved/salewashout-paymentsrecieved.component';
import { ActivedeletedOrallmenuComponent } from '../menus/activedeleted-orallmenu/activedeleted-orallmenu.component';
import { DeletedactiveOptionComponent } from '../shared/deletedactive-option/deletedactive-option.component';





@NgModule({
  declarations: [
    SalewashoutPaymentsrecievedComponent,
    SaleDashboardComponent,
    SaleCustomerComponent,
    
    SaleChartsComponent,
    SaleGridComponent,
    SaleMiscinfoComponent,
    SaleNewComponent,
    SaleNotesComponent,
    SaleVehicleComponent,
    SaleWashoutComponent,
    SaleDeskComponent,
    LeftSidmenuComponent,
   // DateFilterComponent,
    VehicleCardGridComponent,
    SaleCobuyerComponent,
    WashoutGridComponent,
    MiscchargesGridComponent,
    Tradein2VehicleComponent,
    TradeVehicleComponent,
    SaleAmortizationComponent,
    SaleamortisationModalComponent,
    SaleFormsComponent,
    PrintPopupComponent,
    SalesJournalComponent,
    AdvancedComponent,
    ActivedeletedOrallmenuComponent,
   // DeletedactiveOptionComponent,
],
  entryComponents: [
    SaleamortisationModalComponent,
  //  DeletedactiveOptionComponent,
    SaleGridComponent,

    PrintPopupComponent
],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    AgChartsAngularModule,
    SaleRoutingModule,
    AgGridModule.withComponents([]),
  ]
})
export class SaleModule { }
