import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { RealEstateRoutingModule } from './realestate-routing.module';
import { RealestateListComponent } from './realestate-list/realestate-list.component';
import { AddHousemodalComponent } from './add-housemodal/add-housemodal.component';
import { EditRealpropertyComponent } from './edit-realproperty/edit-realproperty.component';
import { RealpropAddrepairComponent } from './realprop-addrepair/realprop-addrepair.component';
import { EdithouserepairModalComponent } from './edithouserepair-modal/edithouserepair-modal.component';
import { RepairListComponent } from './repair-list/repair-list.component';


@NgModule({
  declarations: [
    RealestateListComponent,
    RealpropAddrepairComponent,
    AddHousemodalComponent,
    EditRealpropertyComponent,
    EdithouserepairModalComponent,
    RepairListComponent
],
  entryComponents: [
    AddHousemodalComponent,
    RealpropAddrepairComponent,
    EdithouserepairModalComponent,
],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    AgChartsAngularModule,
    RealEstateRoutingModule,
    AgGridModule.withComponents([]),
  ]
})
export class RealEstateModule { }
