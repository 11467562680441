import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CalculateFederalAprDto } from 'src/app/_models/DTOS/CalculateFederalAprDto';
import { CalculateTermDto } from 'src/app/_models/DTOS/CalculateTermDto';


@Injectable({
  providedIn: 'root'
})
export class LoanService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;


  getFederalApr(loanDto): Observable<CalculateFederalAprDto> {
    return this.http.put<CalculateFederalAprDto>(this.baseUrl + 'amoloan/calculatefederalapr/', loanDto);
  }

  getPaymentByTerm(calculateTermDto): Observable<CalculateTermDto> {
    return this.http.put<CalculateTermDto>(this.baseUrl + 'amoloan/calculatebyterm/', calculateTermDto);
  }

  getTermByPaymentAndApr(calculateTermDto): Observable<CalculateTermDto> {
    return this.http.put<CalculateTermDto>(this.baseUrl + 'amoloan/calculategetterm/', calculateTermDto);
  }

  takePayment(calculateTermDto): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'amoloan/takepayment/', calculateTermDto);
  }

  getPaymentDueToday(calculateTermDto): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'amoloan/preparepayment/', calculateTermDto);
  }

  calculateCurrentDues(calculateCurrentDueDto): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'amoloan/findcurrentdues/', calculateCurrentDueDto);
  }

}
