import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { UserWebsiteToReturnDto } from 'src/app/_models/DTOS/UserWebsiteToReturnDto';
import { WebsiteListToReturnDto } from 'src/app/_models/DTOS/WebsiteListToReturnDto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { SocialMedia } from 'src/app/_models/socialMedia';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Website } from 'src/app/_models/website';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
  })

  export class WebsiteService {
    userAssignedToCompany: UserAssignedToCompany;
    website: Subject<Website> =  new BehaviorSubject<Website>(null);
  constructor(private http: HttpClient, private authService: AuthService) {
      this.authService.company.subscribe(data => { this.userAssignedToCompany = data; });
  }
  baseUrl = environment.apiUrl;

  getWebsites(): Observable<WebsiteListToReturnDto[]> {
    return this.http.get<WebsiteListToReturnDto[]>(this.baseUrl + 'website/getallwebsitesfordealer/' + this.userAssignedToCompany.parentGuid);
  }
  getWebsitesEmployees(guid: string): Observable<UserWebsiteToReturnDto[]> {
    return this.http.get<UserWebsiteToReturnDto[]>(this.baseUrl + 'person/pagedlistemployees/' + guid);
  }

  changeWebsite(website: Website) {
    this.website.next(website);
  }
  addNewWebsite(website: any): Observable<WebsiteListToReturnDto[]> {
    return this.http.post<WebsiteListToReturnDto[]>(this.baseUrl + 'website/createnewwebsite/', website);
  }
  getWebsite(id) {
    console.log(id);
       return  this.http.get<Website>(this.baseUrl + 'website/getwebsitebywebsiteguid/' + id );
  }
  getSocialMedia(id): Observable<SocialMedia> {
    console.log(id);
       return  this.http.get<SocialMedia>(this.baseUrl + 'websitesocialmedia/getsocialmediabyid/' + id );
  }
  getWebsiteByParentGuid(id) {
    console.log(id);
    return  this.http.get<Website>(this.baseUrl + 'website/getwebsitebyparentguid/' + id );
  }

  isEmployeePublished(websiteid: number, userAssignedId, ispublished: boolean): Observable<UserWebsiteToReturnDto[]> {
    console.log(this.baseUrl + 'website/employeepublished/'   + websiteid + '/' + userAssignedId + '/' + ispublished);
    return this.http.get<UserWebsiteToReturnDto[]>
    (this.baseUrl + 'website/employeepublished/'   + websiteid + '/' + userAssignedId + '/' + ispublished);
  }
  unpublishEmployee(websiteemployeeid, websiteguid): Observable<PaginatedResult<PersonLinqResultToReturnDto[]>> {
    const paginatedResult: PaginatedResult<PersonLinqResultToReturnDto[]> = new PaginatedResult<PersonLinqResultToReturnDto[]>();
    const params = {};
    // return this.http.get<UserWebsiteToReturnDto[]>
    // (this.baseUrl + 'websiteemployee/removewebsiteemployee/'   + websiteemployeeid + '/' + websiteguid);
    return this.http.get<PersonLinqResultToReturnDto[]>(this.baseUrl + 'websiteemployee/removewebsiteemployee/'   + websiteemployeeid + '/' + websiteguid, { observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  updateEmployeeDepartMentType(websiteemployeeid, websiteguid, departmenttypeid) {
    const paginatedResult: PaginatedResult<PersonLinqResultToReturnDto[]> = new PaginatedResult<PersonLinqResultToReturnDto[]>();
    const params = {};
    return this.http.get<PersonLinqResultToReturnDto[]>(this.baseUrl + 'websiteemployee/updatewebsiteemployeedepartment/'   + websiteemployeeid + '/' + websiteguid + '/' + departmenttypeid, { observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }
  addEmployee(websiteemployeeid, websiteguid) {
    const paginatedResult: PaginatedResult<PersonLinqResultToReturnDto[]> = new PaginatedResult<PersonLinqResultToReturnDto[]>();
    const params = {};
    return this.http.get<PersonLinqResultToReturnDto[]>(this.baseUrl + 'websiteemployee/addwebsiteemployee/'   + websiteemployeeid + '/' + websiteguid, { observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  addNewEmployee(parentguid, websiteguid, person): Observable<any>{
    return this.http.put<any>(this.baseUrl + 'websiteemployee/addnewemployee/' + parentguid + '/' + websiteguid, person);
  }

  }
