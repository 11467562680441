import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { AmoPayment } from 'src/app/_models/amopayments';
import { TakePaymentDto } from 'src/app/_models/DTOS/takepaymentdto';
import { Sale } from 'src/app/_models/sale';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AmoInstallmentService } from 'src/app/_services/amoinstallmentservices/amoinstallments.service';
import { AuthService } from 'src/app/_services/auth.service';
import { LoanService } from 'src/app/_services/loanservieces/loan.service';

import { AddPaymentModalComponent } from '../add-payment-modal/add-payment-modal.component';

@Component({
  selector: 'app-loan-payments',
  templateUrl: './loan-payments.component.html',
  styleUrls: ['./loan-payments.component.css']
})
export class LoanPaymentsComponent implements OnInit {
  amoPayments = [] as AmoPayment[];
  sale = {} as Sale;

ifIsDetails: boolean;
ifIscustomerInfo: boolean;
ifIsAmortization: boolean;
ifispickInfo: boolean;
amortization = {} as AmoAmortization[];
selectedCompany1: UserAssignedToCompany;
invoiceId: number;
loanId: number;
invoiceAndAmoPayments: any;
bsModalRefAddPayment: any;
numZero = 0;
todayDate =  Date.now;
todayPayoff: number;
amoPayment = { } as AmoPayment;
takePaymentDto: TakePaymentDto =  {
 datePaid: moment(Date.now()).toDate(),
  invoiceId: null,
  todayLoanPaid: 1,
  todayDeferredDown: 2,
  todayLateFee: 3,
  todayMisc: 4,
  sumPaidToday: 5,
  parentId: ' '

};
  constructor( private route: ActivatedRoute,
    private loanService: AmoInstallmentService,
    private takePaymentLoan: LoanService,
    private router: Router,
    private alertify: AlertifyService,
    private authService: AuthService,
    private modalService: BsModalService) { const now = moment(); }

  ngOnInit() {
    this.ifIsDetails = true;
    this.ifispickInfo = false;
    this.ifIsAmortization = false;
    this.ifIscustomerInfo = false;
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      this.invoiceAndAmoPayments = data.invoiceAndAmoPayments;
    });
    // this.chartInvoice = this.invoiceAndAmoPayments.invoice;
    // this.amoPayments = this.invoiceAndAmoPayments.amoPayments;
    // this.amortization = this.invoiceAndAmoPayments.amortizations;
    this.loanId = this.sale?.amoLoans?.id;
    this.invoiceId = this.sale?.id;
    // this.getAmortization();
  //  this.dueTodayLoad();
  }
  isDetails(e) {
    if (e === 'loanInfo') {
      this.ifIsDetails = true;
      this.ifispickInfo = false;
      this.ifIscustomerInfo = false;
      this.ifIsAmortization = false;
    }
    if (e === 'pickInfo') {
      this.ifIsDetails = false;
      this.ifispickInfo = true;
      this.ifIscustomerInfo = false;
      this.ifIsAmortization = false;
    }
    if (e === 'customerInfo') {
      this.ifIsDetails = false;
      this.ifispickInfo = false;
      this.ifIscustomerInfo = true;
      this.ifIsAmortization = false;
    }
    if (e === 'amotization') {
      this.ifIsAmortization = true;
      this.ifIsDetails = false;
      this.ifispickInfo = false;
      this.ifIscustomerInfo = false;
    }
  }
  getAmortization() {
    this.loanService.getAmortization(this.sale.amoLoans.id).subscribe(data => {
      this.amortization = data;
    }, error => {
      this.alertify.error(error);
    }, () => { this.alertify.success('Amortization Recieved'); });
  }
  returnToLoans() {
    this.router.navigate(['/loan/list/'  + this.selectedCompany1.parentGuid]);
  }
  takePayment()  {
    const initialState = {
      chartInvoiceTransaction: this.sale,
      takePaymentDto: this.takePaymentDto
    };
    this.bsModalRefAddPayment = this.modalService.show(AddPaymentModalComponent, {initialState, class: 'modal-md'});
      this.bsModalRefAddPayment.content.paymentEnetered.subscribe((values) => {
      //  console.log(values);
        this.takePaymentDto = values;
        this.takePaymentLoan.takePayment(values).subscribe(data => {
          this.sale = data.invoice;
          this.amoPayments = data.amoPayments;
          this.alertify.success('Payment Applied Successullfy.');
        }, error => {
          this.alertify.error(error);
        });
  });
  }
  async dueTodayLoad() {
    this.takePaymentDto.datePaid = moment(Date.now()).toDate();
    this.takePaymentDto.parentId = this.selectedCompany1.parentGuid;
    this.takePaymentDto.invoiceId = this.invoiceId;
    this.takePaymentLoan.getPaymentDueToday(this.takePaymentDto).subscribe(data => {
    //  console.log(moment(Date.now()).toDate());
      this.takePaymentDto.sumPaidToday = parseFloat(data.sumDueTOday.toFixed(2));
      this.takePaymentDto.todayNonAmortizedPaid = parseFloat(data.nonAmortizedPaymentDue.toFixed(2));
      this.takePaymentDto.todayDeferredDown = parseFloat(data.deferredBalance.toFixed(2));
      this.takePaymentDto.todayLateFee = parseFloat(this.numZero.toFixed(2));
      this.takePaymentDto.todayMisc = parseFloat(this.numZero.toFixed(2));
      this.takePaymentDto.todayLoanPaid = data.dueToday;
      // returned items
//       sumPaidNoAmo: 260.4
// sumLoanPaid: 6048.02
// nonAmortizedPaymentDue: 26.04
// nonAmortizedBlance: 364.6
// todayPayoffAmortized: 4659.560063013699
// totalDueTillToday: 11658.96
// dueToday: 5110.160063013699
// deferredBalance: 86
// totalPayoff: 5110.16006301369
    });
  }
}

