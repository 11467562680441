
import { Injectable } from '@angular/core';
import { PDFDocument, PDFPage, PDFTextField } from 'pdf-lib';
import { promise } from 'protractor';
import { Observable, of } from 'rxjs';
import { PdfFieldServer } from 'src/app/_models/PdfFieldServer';
import { PdfForms } from 'src/app/_models/PdfForms';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
@Injectable({ providedIn: 'root' })
export class  ConvertPdfToArraysAndBuffers {
  constructor(private pdfService: PdfService) {}

/**
 * contains url for form to convert to array
 * @param pdfForms PDForms is internal model retrieved from sql data table 
 */
async convertPdfDocumentToUint8Array(pdfForms: PdfForms): Promise<Uint8Array>{
    const formPdfBytes = await fetch(pdfForms.pdfFormUrl).then(res => res.arrayBuffer());
    const pdfDoc2 = await PDFDocument.load(formPdfBytes);
    const uint8Array = pdfDoc2.save();
    return uint8Array;
}


/**
 * contains url for form to convert to array
 * @param pdfForms PDForms is internal model retrieved from sql data table
 */
async convertPdfDocumentBase64Array(pdfForms: PdfForms): Promise<string>{
    const formPdfBytes = await fetch(pdfForms.pdfFormUrl).then(res => res.arrayBuffer());
    const pdfDoc2 = await PDFDocument.load(formPdfBytes);
    const base64Array = pdfDoc2.saveAsBase64();
    return base64Array;
}


/* EXAMPLE: Converts Uint8Array to window.view of type application/pdf
    must send type example for pdf 'application/pdf'
    this.convertPdfToArraysAndBuffers.createBlobUrl(this.byteArray).subscribe(data => {
    window.open(data);
  });
  OBERVABLE!!!!
*/
/**
 * @param uint8Array as name says
 * @param type 'application/pdf' header  type is a object literal so no need for type: type,
 * this opens a preview window of pdf or image or other type  at location its called
 * as _blank
 */
createBlobUrlToView(uint8Array, type): Observable<string>{
const blob = new Blob([uint8Array], {type});
  return of(URL.createObjectURL(blob));
}

/**
 * @param uint8Array as name says
 * @param type 'application/pdf' header  type is a object literal so no need for type: type,
 */
createBlob(uint8Array, type): Observable<Blob>{
    const blob = new Blob([uint8Array], {type});
      return of(blob);
    }

/** A Blob() is almost a File() converts a blob to a uploadable file
 * @param theBlob the converted file to blob above routine works
 * @param fileName assign it a name
 */
   blobToFile(theBlob, fileName): Observable<any> {
      theBlob.lastModifiedDate = new Date();
      theBlob.name = fileName;
      return of(theBlob);
  }
// does not work below however has example of how to print, download...
/**
 * 
 * @param uint8Array as name says
 * @param type type is object literal same as type:type re 'application/pdf'
 * @param isPrint if is print will open new window
 * @param reportName name of file to download
 * example: have not found way to turn below code into example used on print
 *     const blob = new Blob([byte], {type: 'application/pdf'});
 *   const link = document.createElement('a');
 *  link.href = window.URL.createObjectURL(blob);
 *  const blobURL = URL.createObjectURL(blob);
 *   if (this.isPrint) { window.open(blobURL); }
 *    if (!this.isPrint) {
 *      const fileName = reportName;
 *     link.download = fileName;
 *      link.click();
 *    }
 * alpha does not work!!!!!!!!!!!!!!!
 */
createDownLoadLink(uint8Array, type, isPrint: boolean, reportName) {
    const link = document.createElement('a');
    const blobURL = URL.createObjectURL(uint8Array);
      if (isPrint) { window.open(blobURL); }
      if (!isPrint) {
        const fileName = reportName;
        link.download = fileName;
        link.click();
      }
}
}