
<div style="margin-top: -30px;" class="container-fluid customer-tabs-sec">
  <ul class="nav nav-tabs">
    <li routerLinkActive="active">
      <a
        data-toggle="tab"
        routerLinkActive="active show"
        (click)="filter('Employee')"
        >Scheduled Due This Month</a
      >
    </li>
    <li routerLinkActive="active">
      <a
        data-toggle="tab"
        routerLinkActive="active show"
        (click)="filter('PastDue')"
        >Past Due</a
      >
    </li>

    <div class="d-flex justify-content-right">
        <pagination
      [boundaryLinks]="true"
      pageBtnClass = "nav nav-tabs"
          [(ngModel)]="pagination.currentPage"
          [totalItems]="pagination.totalItems"
          (pageChanged)="pageChanged($event)"
          [itemsPerPage]="pagination.itemsPerPage"
          previousText="&lsaquo;"
          nextText="&rsaquo;"
          firstText="&laquo;"
          lastText="&raquo;"
        >
        </pagination>
      </div>

</ul>  
</div>
<app-date-filter (calenderOutput) = "updateDates($event)" *ngIf = "isFilterDates"></app-date-filter>
<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-11">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">          
                
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <div id="printableTable">
                    <table borderColor="#ccc">
                      <tr>
                        <th></th>
                        <th>ID</th>
                           <th>Sale Type</th>
                           <th>Sale Status</th>

                        
                        <th (click)="sort('isTransactionDateAscending')">Transaction Date&nbsp;
                            <i [ngClass]="isTransactionDateAscending ? 'up': 'down'"></i>
                        </th>
                        <th (click)="sort('isInvoiceNumberAscending')">Invoice # &nbsp;
                          <i [ngClass]="isInvoiceNumberAscending ? 'up': 'down'"></i>
                      </th>
                        <th (click)="sort('isCustomerAscending')">Customer &nbsp;
                          <i [ngClass]="isCustomerAscending ? 'up': 'down'"></i>
                      </th>
                        <th (click)="sort('phone')">Phone&nbsp;
                            
                        </th>
                        <th (click)="sort('isAmountFinancedAscending')">Financed&nbsp;
                            <i [ngClass]="isAmountFinancedAscending ? 'up': 'down'"></i>
                        </th>
                        <th (click)="sort('isFirstDueDateAscending')">First Due&nbsp;
                            <i [ngClass]="isFirstDueDateAscending ? 'up': 'down'"></i>
                        </th>
                       
                        <th (click)="sort('isNextScheduledDateAscending')">Next Scehduled Date&nbsp;
                          <i [ngClass]="isNextScheduledDateAscending ? 'up': 'down'"></i>
                      </th>
                        <th (click)="sort('isLastPaidDateAscending')">Last Paid&nbsp;
                          <i [ngClass]="isLastPaidDateAscending ? 'up': 'down'"></i>
                      </th>
                        <th (click)="sort('isAmountDueAscending')">Amount Due&nbsp;
                          <i [ngClass]="isAmountDueAscending ? 'up': 'down'"></i>
                      </th>
                        <th (click)="sort('isPaymentAscending')">Payment&nbsp;
                            <i [ngClass]="isPaymentAscending ? 'up': 'down'"></i>
                        </th>
                       
                        <th (click)="sort('isPayoffAscending')">Payoff&nbsp;
                          <i [ngClass]="isPayoffAscending ? 'up': 'down'"></i>
                      </th>
                        <th></th>
                      </tr>
                      <tr  [hidden]="isPrint">
                          <td></td>
                          <td></td>
                         
                          <td><select  
                            [(ngModel)] ="selectType" (change) ="selectedTypeChanged($event.target.value)"
                            name="vehicleBodyClassTypes"
                            onmousedown = "value = '';" 
                            #vehicleBodyClassTypes="ngModel">
                            <option ngValue= "null">--All--</option>
                            <option *ngFor="let summary of chartInvoiceSaleTypes" value="{{summary.id}}">
                              {{summary.value}}
                            </option>
                        </select></td>  
                        <td><select  
                          [(ngModel)] ="selectStatus" (change) ="selectedStatusChanged($event.target.value)"
                          name="chartInvoiceStatusess"
                          onmousedown = "value = '';" 
                          #chartInvoiceStatusess="ngModel">
                          <option ngValue= "null">--All--</option>
                          <option *ngFor="let summary of chartInvoiceStatuses" value="{{summary.id}}">
                            {{summary.value}}
                          </option>
                      </select>
                    </td>
                        
                        <td style="width: 76px"><button class="btn-success" (click)="showTransactionDate()">Filter</button></td>
                        <td style="width: 76px"><input (keyup)="setSearchBy('searchInvoiceNumber', $event.target.value)" >
                        <td style="width: 76px"><input (keyup)="setSearchBy('searchCustomer', $event.target.value)" >
                        </td>  
                        <td><input style="width: 55px;" (keyup)="setSearchBy('searchPhone', $event.target.value)">
                        </td>  
                        <td></td>  
                        <td></td>  
                        <td><button class="btn-success" (click)="showFirstDue()">Set</button></td>  
                        <td><button class="btn-success" (click)="showScheduledDate()">Set</button></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchAmountDue', $event.target.value)"></td>  
                        <td></td>  
                        <td></td>  
                      </tr>
                      <ng-container  *ngFor="let item of loans; let i=index; last as isLast" > 
                      <tr  align="right" class="td-row" (dblclick) = "dueTodayLoad(item) ">
                      <td  align="left" *ngIf="i != loans.length - 1" style="cursor: pointer;" (click)="findDetails(item)"><i style="color: rgb(0, 184, 0);" class="fa fa-plus"></i></td>
                      <td align="left" *ngIf="i != loans.length - 1">{{ item.id }}</td>
                      <td align="left" *ngIf="i != loans.length - 1">{{ item?.saleType }}</td>
                      <td align="left" *ngIf="i != loans.length - 1">{{ item?.saleStatus }}</td>
                        <td align="left" *ngIf="i != loans.length - 1">{{ item?.soldDate | date: "shortDate" }}</td>
                        <td align="left" *ngIf="i != loans.length - 1">{{ item?.invoiceNumber }}</td>
                        <td align="left" *ngIf="i != loans.length - 1">{{ item?.buyer }}</td>
                        <td align="left" *ngIf="i != loans.length - 1">{{ item?.phone }}</td>
                        <td *ngIf="i != loans.length - 1">{{ item?.amountFinanced  | number:'1.2-2'}}</td>
                        <td align="left" *ngIf="i != loans.length - 1">{{ item?.firstDueDate | date: "shortDate"}}</td>
                        <!-- {{ item?.nextDueDate | date: "shortDate"}} -->
                        <td align="left" *ngIf="i != loans.length - 1">{{ item?.scheduledDueDate | date: "shortDate"}}</td>
                        <td align="left" *ngIf="i != loans.length - 1">{{ item?.lastPaidDate | date: "shortDate"}}</td>
                        <td *ngIf="i != loans.length - 1">{{ item?.currentDue  | number:'1.2-2'}}</td>
                        <td *ngIf="i != loans.length - 1">{{ item?.payment  | number:'1.2-2'}}</td>
                        <td *ngIf="i != loans.length - 1">{{ item?.payoffToday  | number:'1.2-2'}}</td>
                        <!-- <td *ngIf="i != loans.length - 1" [hidden]="isPrint" colspan="3">
                          <button *ngIf="item?.payoffToday > 0"
                            class="btn btn-outline-success"
                            (click)="loanDetails(item)"
                          >
                             Payment
                          </button>
                        </td> -->
                      </tr>

                      <ng-container *ngIf="item?.id === itemId && this.isExpandPayments">
                          <tr >
                              <td></td>
                              <td></td>
                              <td>Pmt #</td>
                              <td>Date Paid</td>
                              <td>Today Paid</td>
                              <td>Deferred Today</td>
                              <td>Late Fee</td>
                              <td>Principle</td>
                              <td>Interest</td>
                              <td>Taxes</td>
                              <td>Balance</td>
                              <td>Edit</td>
                          </tr>
                          <tr *ngFor="let data of amoPayments" >
                              <td></td>
                              <td></td>
                            <td> {{ data.paymentNumber}} </td>
                            <td> {{ data.datePaid | date: "shortDate" }} </td>
                            <td> {{ data.sumPaidToday | number:'1.2-2' }} </td>
                            <td> {{ data.todayDeferredDown | number: '1.2-2' }}</td>
                            <td> {{ data.todayLateFee | number:'1.2-2' }} </td>
                            <td> {{ data.todayPrinciple | number:'1.2-2' }} </td>
                            <td> {{ data.todayInterest | number:'1.2-2' }} </td>
                            <td> {{ data.todayNonAmortizedPaid | number:'1.2-2' }} </td>
                            <td> {{ data.principleBalance | number:'1.2-2' }} </td>
                            <td> {{ data.principleBalance | number:'1.2-2' }} </td>   <td  align="left" *ngIf="i != loans.length - 1" style="cursor: pointer;" (click)="editPayment(data, item)"><i style="color: rgb(0, 184, 0);" class="fa fa-plus"></i></td>

                          </tr>
                         
                        </ng-container>
                       
                      </ng-container>

                      <tr  align="right" class="border_top" *ngFor="let item of loans; let i=index; last as isLast">
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">{{ item?.amountFinanced  | number:'1.2-2'}}</td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">{{ item?.currentDue  | number:'1.2-2'}}</td>
                        <td *ngIf="isLast && i !==0">{{ item?.payment  | number:'1.2-2'}}</td>
                        <td *ngIf="isLast && i !==0">{{ item?.payoffToday  | number:'1.2-2'}}</td>
                        <td *ngIf="isLast && i !==0"></td>
                    </tr>
                    </table>
                    </div>
                  </div>
                  <!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-1">
        <div class="inner-sidebar">
          <ul>
            <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
            <!-- <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png" />
                <span>Add Vehicle</span>
              </button>
            </li> -->
            <li *appHasRole="['Admin']">
              <button class="cstm-btn" (click) = "exportAsXLSX()">
                <img src="assets/images/icons8-microsoft-excel-48.png" />
                <span>Export Excel</span> 
              </button>
            </li>
            <li>
              <button (click) = "printDiv()" class="cstm-btn">
                <img src="assets/images/ap-icon-6.png" />
                <span>Print Report</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>