<div class="customer-form-sec">
  <div class="container-fluid">
    <br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->
                <div class="row">
                  <div class="col-md-10  customer-tabs-sec">
                    <ul class="nav nav-tabs">
                      <li><a [ngClass] = "isNotes ? 'active show' : ''" (click) ="tabClick('isNotes')"  href="javascript:void(0)">Notes</a></li>
                      <li><a [ngClass] = "isMiscCharges ? 'active show' : ''" (click) ="tabClick('isMiscCharges')"  href="javascript:void(0)">Misc Charges</a></li>
                      <li  ><a [ngClass] = "isWashout ? 'active show' : ''" (click) ="tabClick('isWashout')"  href="javascript:void(0)">Washout/Recap</a></li>
                      <li><a [ngClass] = "isPaymentHistory ? 'active show' : ''" (click) ="tabClick('isPaymentHistory')" href="javascript:void(0)">Payment History</a></li>
                      <!-- <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Payables</a></li>
                      <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Recievable</a></li> -->
                      <!-- <li><a  [ngClass] = "isAmortization ? 'active show' : ''" (click) ="tabClick('isAmortization')" href="javascript:void(0)">Amortization</a></li> -->
                    </ul>

                   
                  </div>
                </div>
                <br><br>
                <div class="row" *ngIf="isAmortization">
                  <div class="col-md-12 table-sec">
                    <app-sale-amortization [amoAmortization] = "amoAmortization"  ></app-sale-amortization>           
                  </div>
                </div>
                <div class="row" *ngIf="isNotes">
                  <div class="col-md-12 table-sec">
                    <app-sale-notes [sale] = "sale" [saleNotesCache] = "saleNotes"  [saleNotes] = "saleNotes" ></app-sale-notes>
                
                  </div>
                </div>
                <div class="row" *ngIf="isWashout">
                  <div class="col-md-12 table-sec">
                    <app-washout-grid [programDefaults] = "programDefaults" [dollarTypesAll] = "dollarTypesAll" [dollars] = "dollars" [sale] = "sale" [dollarTypes] = "dollarTypes"></app-washout-grid>
                  </div>
                </div>
                <div class="row" *ngIf="isMiscCharges">
                  <div class="col-md-12 table-sec">
                    <app-misccharges-grid [dollars] = "dollars" [sale] = "sale" [miscChargeDollarTypes] = "miscChargeDollarTypes"></app-misccharges-grid>
                   
                  </div>
                  </div>
                  <div class="row" *ngIf="isPaymentHistory">
                    <div class="col-md-12 table-sec">
                     <app-salewashout-paymentsrecieved [sale] = "sale"></app-salewashout-paymentsrecieved>
                     
                    </div>
                   
                </div>
              </div>
            </div>
          </div><!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-2">
        <div class="inner-sidebar">
          <ul>
            <li *ngIf="isWashout">
              <button (click) = "addSaleCost()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>Add New</span>
              </button>
            </li>
            <li>
              <button (click) = "showProfitItems()" *ngIf="isWashout" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-3.png">
                <span>Profit Items</span>
              </button>
            </li>

          


            <li *ngIf="isNotes">
              <button (click) = "addNote()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>Add New</span>
              </button>
            </li>
            <li *ngIf="isMiscCharges">
              <button (click) = "addMiscCharge()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>Add New</span>
              </button>
            </li>
            <li>
              <a href="javascript:void(0)"   (click)="tabelViewClick()">
                <img src="assets/images/sidebar-icon-9.jpg">
                <span>Table View</span>
              </a>
              </li>
            <!-- <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-2.png">
                <span>View/Edit</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-3.png">
                <span>Mark Item Paid</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-4.png">
                <span>Print Check</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn" disabled="">
                <img src="assets/images/ap-icon-5.png">
                <span>Print Batch</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-6.png">
                <span>Print Report</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-7.png">
                <span>Check</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-7.png">
                <span>Bill</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-7.png">
                <span>Charge</span>
              </button>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
