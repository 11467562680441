<div class="customer-form-sec">
  <div class="container-fluid">
<br><br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="customer" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12 cstm-col">
              <div class="row account-rw">
                <div class="cstm-col">
                  <label>Account</label>
                  <input type="text" class="cstm-input" name="acct"
                [ngModel]="sale?.acct"
                (ngModel)="sale.acct = $event"
                (ngModelChange) = "sale.acct = $event; "
                (blur) = "onChange($event)" >
                </div>
                <div class="cstm-col">
                  <label>Sold As</label>
                  <select class="cstm-input">
                    <option>60 Days or 2000 Miles 50/50L</option>
                    <option>60 Days or 2000 Miles 50/50L</option>
                  </select>
                </div>
                <div class="cstm-col">
                  <label>Sale Status</label>
                  <select class="cstm-input" 
									#saleStatuses="ngModel" 
                  name="saleStatus" 
                  id="saleStatuses"   
                  [(ngModel)]="selectedSaleStatus"   
                  (ngModelChange)="amoSaleStatusSelected(selectedSaleStatus)">
									  <option *ngFor="let item of saleStatus; index as i" 
                    [selected] = "item.value === sale?.saleStatus.value "  
                    (ngValue)="item"
									  [ngValue] = "item.value">{{item.value}}</option>
                  </select>
                 
                </div>
                <div class="cstm-col">
                  <label>Sale Type</label>

                  <!--ASsigned value in ngchange or nginit-->
                  <select class="cstm-input" 
									#saleType="ngModel" 
                  name="saleTypes" 
                  id="saleTypes"   
                  [(ngModel)]="selectedSaleType"   
                  (ngModelChange)="amoSaleTypeSelected(selectedSaleType)">
									  <option *ngFor="let item of saleTypes; index as i" 
                    [selected] = "item.value === sale?.saleTypes?.value "  
                    (ngValue)="item"
									  [ngValue] = "item.value">{{item?.value}}</option>
                  </select>
                  <!-- <select id="accounts" #accounts = 'ngModel'  [(ngModel)]="selectedSaleType" (ngModelChange)="test(selectedSaleType)">
                    <option *ngFor="let account of saleTypes" [value]="account.value" [ngValue]="account" [selected]="selectedSaleType.id" >{{account.value}}</option>
                  </select> -->
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 cstm-col">
              <ul class="customer-form-btns">
                <li>
                  <a href="javascript:void(0)">
                    <img src="assets/images/btn-icon-1.jpg">
                    <span>Insurance</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <img src="assets/images/btn-icon-2.jpg">
                    <span>Lien holder</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <img src="assets/images/btn-icon-3.jpg">
                    <span>References</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Status</label>
              <select class="cstm-input">
                <option>A</option>
                <option>B</option>
              </select>
              <a href="javascript:void(0)">Active Account</a>
            </div> -->
            
            <div class="col-md-6 cstm-col">
              <div class="inline-blk">
                <label>First name</label>
                <input type="text" class="cstm-input" name="lastName"
                [ngModel]="sale?.buyer?.firstName"
                (ngModel)="sale.buyer.firstName = $event"
                (ngModelChange) = "sale.buyer.firstName = $event; "
                (blur) = "onChange($event)" >
              </div>
              <ul class="ph-btns">
                <li>
                  <a href="javascript:void(0)">
                    <img src="assets/images/phone-icon.jpg">
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>SDN</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Ins Company</label>
              <input type="text" class="cstm-input" name="insuranceCompany"
                [ngModel]="sale?.buyer?.insuranceCompany"
                (ngModel)="sale.buyer.insuranceCompany = $event"
                (ngModelChange) = "sale.buyer.insuranceCompany = $event; "
                (blur) = "onChange($event)" >
            </div>

            <div class="col-md-6 cstm-col">
              <div class="inline-blk">
                <label>Last Name</label>
                <input type="text" class="cstm-input" name="lastName"
                [ngModel]="sale?.buyer?.lastName"
                (ngModel)="sale.buyer.lastName = $event"
                (ngModelChange) = "sale.buyer.lastName = $event; "
                (blur) = "onChange($event)" >
              </div>
         
            </div>
            <div class="col-md-6 cstm-col">
              <label >Ins Exp</label>
              <input type="date" class="cstm-input" name="insuranceExpires"
              [ngModel]="sale?.buyer?.insuranceExpires  | date: 'yyyy-MM-dd'"
              (ngModel)="sale.buyer.insuranceExpires = $event"
              (ngModelChange) = "sale.buyer.insuranceExpires = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <div class="inline-blk">
                <label>Middle Name</label>
                <input type="text" class="cstm-input" name="middleName"
                [ngModel]="sale?.buyer?.middleName"
                (ngModel)="sale.buyer.middleName = $event"
                (ngModelChange) = "sale.buyer.middleName = $event; "
                (blur) = "onChange($event)" >
              </div>
          
            </div>
            <div class="col-md-6 cstm-col">
              <label>Policy#</label>
              <input type="text" class="cstm-input" name="insurancePolicy"
              [ngModel]="sale?.buyer?.insurancePolicy"
              (ngModel)="sale.buyer.insurancePolicy = $event"
              (ngModelChange) = "sale.buyer.insurancePolicy = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Zip Code</label>
              <input type="text" class="cstm-input" name="zip"
              [ngModel]="sale?.buyer?.addressDetail?.zip"
              (ngModel)="sale.buyer.addressDetail.zip = $event"
              (ngModelChange) = "sale.buyer.addressDetail.zip = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Employer</label>
              <input type="text" class="cstm-input" name="name"
              [ngModel]="sale?.buyer?.employer?.name"
              (ngModel)="sale.buyer.employer.name = $event"
              (ngModelChange) = "sale.buyer.employer.name = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Address</label>
              <input type="text" class="cstm-input" name="street"
              [ngModel]="sale?.buyer?.addressDetail?.street"
              (ngModel)="sale.buyer.addressDetail.street = $event"
              (ngModelChange) = "sale.buyer.addressDetail.street = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Emp Phone</label>
              <input type="text" class="cstm-input" name="phone"
              [ngModel]="sale?.buyer?.employer?.phone"
              (ngModel)="sale.buyer.employer.phone = $event"
              (ngModelChange) = "sale.buyer.employer.phone = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>City</label>
              <input type="text" class="cstm-input" name="city"
              [ngModel]="sale?.buyer?.addressDetail?.city"
              (ngModel)="sale.buyer.addressDetail.city = $event"
              (ngModelChange) = "sale.buyer.addressDetail.city = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Lienholder</label>
              <input type="text" class="cstm-input" name="name"
              [ngModel]="sale?.lienHolder?.name"
              (ngModel)="sale.lienHolder.name = $event"
              (ngModelChange) = "sale.lienHolder.name = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>County</label>
              <input type="text" class="cstm-input" name="county"
              [ngModel]="sale?.buyer?.addressDetail?.county"
              (ngModel)="sale.buyer.addressDetail.county = $event"
              (ngModelChange) = "sale.buyer.addressDetail.county = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Lien Phone</label>
              <input disabled = "true" type="text" class="cstm-input" name="phone"
              [ngModel]="sale?.lienHolder?.phone"
              (ngModel)="sale.lienHolder.phone = $event"
              (ngModelChange) = "sale.lienHolder.phone = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col state-sec">
              <label>State</label>
              <input type="text" class="cstm-input" name="state"
              [ngModel]="sale?.buyer?.addressDetail?.state"
              (ngModel)="sale.buyer.addressDetail.state = $event"
              (ngModelChange) = "sale.buyer.addressDetail.state = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Extra </label>
              <input type="text" class="cstm-input" name="extraNumber"
              [ngModel]="sale?.buyer?.extraNumber"
              (ngModel)="sale.buyer.extraNumber = $event"
              (ngModelChange) = "sale.buyer.extraNumber = $event; "
              (blur) = "onChange($event)" >
            </div>

            <div class="col-md-6 cstm-col">
              <label>Email</label>
              <input type="text" class="cstm-input" name="email"
              [ngModel]="sale?.buyer?.email"
              (ngModel)="sale.buyer.email = $event"
              (ngModelChange) = "sale.buyer.email = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Drive Lic</label>
              <input type="text" class="cstm-input" name="driversLicense"
              [ngModel]="sale?.buyer?.driversLicense"
              (ngModel)="sale.buyer.driversLicense = $event"
              (ngModelChange) = "sale.buyer.driversLicense = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Phone</label>
              <input type="text" class="cstm-input" name="phone"
              [ngModel]="sale?.buyer?.phone"
              (ngModel)="sale.buyer.phone = $event"
              (ngModelChange) = "sale.buyer.phone = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>DL Exp</label>
              <input type="date" class="cstm-input" name="driverLicenseExpires"
              [ngModel]="sale?.buyer?.driverLicenseExpires | date: 'yyyy-MM-dd'"
              (ngModel)="sale.buyer.driverLicenseExpires = $event"
              (ngModelChange) = "sale.buyer.driverLicenseExpires = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Avatar</label>
              <input type="file">
            </div>
            <div class="col-md-6 cstm-col">
              <label>DL State</label>
              <input type="text" class="cstm-input" name="driversLicenseState"
              [ngModel]="sale?.buyer?.driversLicenseState"
              (ngModel)="sale.buyer.driversLicenseState = $event"
              (ngModelChange) = "sale.buyer.driversLicenseState = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Birthday</label>
              <input type="date" class="cstm-input" name="dateOfBirth"
                [ngModel]="sale?.buyer?.dateOfBirth | date: 'yyyy-MM-dd'"
                (ngModel)="sale.buyer.dateOfBirth = $event"
                (ngModelChange) = "sale.buyer.dateOfBirth = $event; "
                (blur) = "onChange($event)" >
            </div>
            <!--COSIGNER -->
         
          </div>
        </div>
        <div id="deal-info" class="tab-pane fade">
          <h2>Deal info form</h2>
        </div>
        <div id="loan" class="tab-pane fade">
          <h2>Loan form</h2>
        </div>
        <div id="vehicle" class="tab-pane fade">
          <h2>Vehicle tab</h2>
        </div>
        <div id="misc-info" class="tab-pane fade">
          <h2>Misc tab</h2>
        </div>
        <div id="notes" class="tab-pane fade">
          <h2>Notes tab</h2>
        </div>
        <div id="washout-info" class="tab-pane fade">
          <h2>Washout tab</h2>
        </div>
        <div id="insurance" class="tab-pane fade">
          <h2>Insurance tab</h2>
        </div>
        <div id="lien-holder" class="tab-pane fade">
          <h2>Lien holder tab</h2>
        </div>
        <div id="references" class="tab-pane fade">
          <h2>References tab</h2>
        </div>
      </div>
      <div class="customer-sidebar col-md-2">
        <div class="inner-sidebar">
          <ul>
            
            <li>
              <a (click) = "update()" href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-2.jpg">
                <span>Save</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="tabelViewClick()">
                <img src="assets/images/sidebar-icon-9.jpg">
                <span>Table View</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
