import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoanListComponent } from './loan-list/loan-list.component';
import { SaleActiveTypeResolver } from '../_resolvers/sale-activetype.resolver';
import { AmoLinqPaymentsResolver } from '../_resolvers/amo-retrievepayments.resolver';
import { SaleStatusResolver } from '../_resolvers/sale-status.resolver';







const routes: Routes = [
  // {path: 'vehiclecharts/:parrentGuid', component: InventoryGridComponent},
  // {
  //   path: 'loanpayments/:parentGuid',
  //   component: LoanPaymentsComponent,
  //   resolve: {
  //   },
  // },
  {
    path: 'loanlist/:parentGuid',
    component: LoanListComponent,
    resolve: { loans: AmoLinqPaymentsResolver,
      chartInvoiceStatuses: SaleStatusResolver,
      chartInvoiceSaleTypes: SaleActiveTypeResolver,
    },
  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoanRoutingModule {}

// {path: 'productlist/aging/:parentGuid', component: AgingListComponent,
// resolve: {chartItems: VehicleGridResolver, bodyTypes: VehicleBodyClassTypesResolver
// }},
