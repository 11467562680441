import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CompanyDepartmentType } from 'src/app/_models/CompanyDepartmentType';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from '../auth.service';
import { CompanyType } from 'src/app/_models/companyType';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { CompanyProfileSubscription } from 'src/app/_models/CompanyProfileSubscription';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  baseUrl = environment.apiUrl;
  companyId: number;
constructor(private http: HttpClient) { }


getCompanies(parentGuid): Observable<CompanyProfile[]> {
  return this.http.get<CompanyProfile[]>(this.baseUrl + 'company/getcompanies/' + parentGuid);
}
getCompanyTypeByName(parentGuid, typename): Observable<CompanyType> {
  return this.http.get<CompanyType>(this.baseUrl + 'company/getcompanytypebyname/' + parentGuid + '/typename/' + typename);
}
  getCompanyById(id): Observable<CompanyProfile> {
    return this.http.get<CompanyProfile>(this.baseUrl + 'companyprofile/getcompany/' + id);
  }

  getCompanyByCompanyGuid(companyGuid): Observable<CompanyProfile> {
    return this.http.get<CompanyProfile>(this.baseUrl + 'companyprofile/getcompanybycompanyguid/' + companyGuid);
  }

  makeCompanyTheDefault(id, company): Observable<CompanyProfile[]> {
    return this.http.put<CompanyProfile[]>(this.baseUrl + 'company/makecompanydefault/' + id, company);
  }
  editCompany(id, company): Observable<CompanyProfile> {
    return this.http.put<CompanyProfile>(this.baseUrl + 'company/updatecompanyfromwebpageedit/' + id, company);
  }
  saveEditCompany(id, company) {
    return this.http.put(this.baseUrl + 'companyprofile/updateparentcompany/' + id, company);
  }
  applyCompanyPayment(id, sub: CompanyProfileSubscription) {
    return this.http.put<CompanyProfile>(this.baseUrl + 'companyprofile/companyprofilepayment/' + id, sub);
  }
  getOnlyParentCompanies(): Observable<CompanyProfile[]> {
    return this.http.get<CompanyProfile[]>(this.baseUrl + 'company/getonlyparentcompanies/');
  }
  getOnlyParentCompaniesGrid(): Observable<CompanyProfile[]> {
    return this.http.get<CompanyProfile[]>(this.baseUrl + 'company/getonlyparentcompaniesgrid/1');
  }

  createParentCompanies(company): Observable<CompanyProfile> {
    return  this.http.put<CompanyProfile>(this.baseUrl + 'company/addnewparentcompany/', company );
  }

  createCompany(company, parentGuid): Observable<CompanyProfile> {
    return  this.http.put<CompanyProfile>(this.baseUrl + 'company/addcompany/' + parentGuid, company );
  }

  createCompanyReturnCompanies(company, parentGuid): Observable<CompanyProfile[]> {
    return  this.http.put<CompanyProfile[]>(this.baseUrl + 'company/addcompanyreturncompanies/' + parentGuid, company );
  }

  getCompanyTypes(parentGuid): Observable<CompanyType[]> {
    return  this.http.get<CompanyType[]>(this.baseUrl + 'company/getcompanytypes/' + parentGuid );
  }
  getCompanyDepartmentTypes(): Observable<CompanyDepartmentType[]> {
    return  this.http.get<CompanyDepartmentType[]>(this.baseUrl + 'company/getcompanydeparmenttypes/');
  }

  getUsersAssignedToCompanies(id): Observable<UserAssignedToCompany[]> {
    return  this.http.get<UserAssignedToCompany[]>(this.baseUrl + 'company/getusersassignedtocompanies/' + id);
  }
  getUsersAssignedToCompaniesForMenu(id): Observable<UserAssignedToCompany[]> {
    return  this.http.get<UserAssignedToCompany[]>(this.baseUrl + 'company/getusersassignedtocompaniesformenu/' + id);
  }
  getUsersAssignedToCompaniesAssignTempCheck(id): Observable<CompanyProfile> {
    return this.http.get<CompanyProfile>(this.baseUrl + 'company/getusersassignedtocompaniessettempcheck/' + id);
  }
  getUsersUnAssignedToCompaniesAssignTempCheck(id): Observable<CompanyProfile> {
    return this.http.get<CompanyProfile>(this.baseUrl + 'company/getusersassignedtocompaniessettempuncheck/' + id);
  }

  assignUsersToCompanies(companies: CompanyProfile[], id): Observable<CompanyProfile[]> {
    return this.http.put<CompanyProfile[]>(this.baseUrl + 'company/assigncompanytouser/' + id, companies);
  }

  retrieveUsersToCompanies( id): Observable<CompanyProfile[]> {
    return this.http.get<CompanyProfile[]>(this.baseUrl + 'company/retrievecompaniesperuserlogin/' + id);
  }

}
