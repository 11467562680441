import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { PhotoManagementComponent } from './photo-management/photo-management.component';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../_modules/shared.module';

@NgModule({
  declarations: [
    AdminPanelComponent,
    UserManagementComponent,
    PhotoManagementComponent
],
  entryComponents: [
// InventoryBuyersguideComponent,

],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    AdminRoutingModule,
    AgChartsAngularModule,
    AgGridModule.withComponents([]),
  ]
})
export class AdminModule { }

