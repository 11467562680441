<br>
  <div class="container-fluid customer-tabs-sec">
    <ul class="nav nav-tabs">
      <li><a [ngClass]="{'active show' : isCompany}"  (click)="tabClicked('isCompany')" href="javascript:void(0)" >Company</a></li>
      <li><a  [ngClass]="{'active show' : isEmployee}" (click)="tabClicked('isEmployee')"  href="javascript:void(0)" >Employees</a></li>
      <li><a [ngClass]="{'active show' : isSettings}"  (click)="tabClicked('isSettings')" href="javascript:void(0)" >Settings</a></li>
      <li><a  [ngClass]="{'active show' : isCashDown}" (click)="tabClicked('isCashDown')"  href="javascript:void(0)" >Cash Down</a></li>
       <li><a  [ngClass]="{'active show' : isMenus}" (click)="tabClicked('isMenus')"  href="javascript:void(0)" >Menus</a></li> 
      <li><a  [ngClass]="{'active show' : isSocial}" (click)="tabClicked('isSocial')"  href="javascript:void(0)" >Social Media</a></li>
      <!-- <li><a  [ngClass]="{'active show' : isStoreHours}" (click)="tabClicked('isStoreHours')"  href="javascript:void(0)" >Store Hours</a></li> -->
    </ul>
</div>
<div class="col-md-12">
 <app-employees [companyDepartmentTypes] = "companyDepartmentTypes" *ngIf="isEmployee" [website] = "website"></app-employees> 
  <app-store-hours *ngIf="isStoreHours"  [website] = "website"></app-store-hours>
  <app-social-media [website] = "website" *ngIf="isSocial" ></app-social-media>
  <app-cash-down *ngIf="isCashDown" [website] = "website"></app-cash-down>
  <app-company-info [website] = "website" *ngIf="isCompany"></app-company-info>
  <app-website-settings *ngIf="isSettings" [website] = "website"></app-website-settings>
  <app-websitemenus *ngIf="isMenus" [website] = "website"></app-websitemenus>
  <!-- <app-pdffield-person [personType] = "personType" [person] = "person" [hidden] ="!isBuyer"></app-pdffield-person>
  <app-pdffield-person [personType] = "personType" [person] = "person"  [hidden]="!isCobuyer"></app-pdffield-person> -->
</div>