
<br>

      <div class="container-fluid customer-tabs-sec col-md-12">
        <ul class="nav nav-tabs" style="z-index: 9999;">
          <li><a [ngClass]="{'active show' : isTree}"  (click)="tabClicked('isTree')" href="javascript:void(0)" ><i class="fa fa-bars"></i></a></li>
          <!--Selecte State-->
          <li><a [ngClass]="{'active show' : isTree}"  (click)="tabClicked('isTree')" href="javascript:void(0)" >
            <!-- <input type="submit" (click)="getRefreshStateList()"> -->
            <div class="input-group ">
              <div class="input-group-prepend">
                <button class="btn btn-outline-secondary" (click)="hamburgerClick()" type="button">Set</button>
              </div>
              
                <select tabindex="-1" class="cstm-select" 
                #pdfStateLists="ngModel"  
                name="pdfStateListId"  
                [(ngModel)]="selectedStateListId"							 
                (ngModelChange)="selectedStateChanged($event)" >
                <option style="color: #bb400f;" [value]="-1">None</option>
                <option  *ngFor="let item of pdfStateList"  [ngValue]="item.fullName"   [ngValue]="item.id">{{item?.fullName}}</option>
                </select>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="getRefreshStateList()" type="button">Refresh</button>
                </div>
              </div>
            <!-- <select  
                [(ngModel)] ="selectStateList" 
                (change) ="selectedStateChanged($event.target.value)"
                name="selectedStateListId"
                onmousedown = "value = '';" 
                #selectedStateListId="ngModel">
    
                <option [selected]="selectedStateListId" *ngFor="let summary of pdfStateList"  value="{{summary.id}}">
                    {{summary.fullName}}
                </option>
            </select> -->
          </a></li>
          <!--Upload PDF BUTTON -->
          <li [hidden]="isUploadPhotoButtonLive === false"  [ngClass]="{'transition':show}" [hidden]="!show">
            <button (click) = "createPdf()"  class="btn btn-outline-info">
              <span >
                <div >Upload Pdf to {{selectedType?.value}}</div>
               </span>
            </button>
          </li>
        
          <li  *ngIf=" !isEditMode && ifFormSelected && !typeIsFocus || isMerging"  >
            <button (click) = "editPdf()"  class="btn btn-outline-primary">
              <span >
                <div >{{ editLabel }} {{ selectedItem?.name}}</div>
               </span>
            </button>
          </li>
          <!-- <li  *ngIf=" isEditMode && isCustomEdit && selectedItem" >
            <button (click) = "editPdf('Edit Pdf')"  class="btn btn-outline-primary">
              <span >
                <div >Close {{ selectedItem?.name}}</div>
               </span>
            </button>
          </li> -->
          <li [hidden]="isUploadPhotoButtonLive === false" > <<<<-----OR--->>>></li>
          <li [hidden]="isCategorySelected === false"  [ngClass]="{'transition':show}" [hidden]="!show">
            <button (click) = "createTypeClick()"  class="btn btn-outline-danger">
              <span >
                <div >Create Type in {{selectedPdfCategoryType?.value}}</div>
               </span>
            </button>
          </li>
          <li [hidden]="selectedType?.value == null"></li>    
          <!-- <li><a [ngClass]="{'active show' : isTreeRealEstate}"  (click)="tabClicked('isTreeRealEstate')" href="javascript:void(0)" >Real Estate Pdfs</a></li> -->
          <!-- <li><a [ngClass]="{'active show' : isTreeInventory}"  (click)="tabClicked('isTreeInventory')" href="javascript:void(0)" >Inventory Pdfs</a></li> -->
          <li *ngIf="isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory 
          && selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Inventory' "><a  [ngClass]="{'active show' : isActiveVehicle}" (click)="tabClicked('isActiveVehicle')"  href="javascript:void(0)" >VEHICLE</a></li>
    
    
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a [ngClass]="{'active show' : isDealer}"  (click)="tabClicked('isDealer')" href="javascript:void(0)" >Dealer</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a [ngClass]="{'active show' : isBuyer}"  (click)="tabClicked('isBuyer')" href="javascript:void(0)" >Buyer</a></li>
    
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a  [ngClass]="{'active show' : isCobuyer}" (click)="tabClicked('isCobuyer')"  href="javascript:void(0)" >Co Buyer</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a  [ngClass]="{'active show' : isSoldVehice}" (click)="tabClicked('isSoldVehice')"  href="javascript:void(0)" >Sold Vehicle</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a  [ngClass]="{'active show' : isTrade1}" (click)="tabClicked('isTrade1')"  href="javascript:void(0)" >Trade 1</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a  [ngClass]="{'active show' : isTrade2}" (click)="tabClicked('isTrade2')"  href="javascript:void(0)" >Trade 2</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a [ngClass]="{'active show' : isDealInfoDollars}"  (click)="tabClicked('isDealInfoDollars')" href="javascript:void(0)" >Deal $$</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a [ngClass]="{'active show' : isCashDown}"  (click)="tabClicked('isCashDown')" href="javascript:void(0)" >Down Payments</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a [ngClass]="{'active show' : isDealInfo}"  (click)="tabClicked('isDealInfo')" href="javascript:void(0)" >Deal +</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a [ngClass]="{'active show' : isTaxes}"  (click)="tabClicked('isTaxes')" href="javascript:void(0)" >Taxes $$</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a  [ngClass]="{'active show' : isLoan}" (click)="tabClicked('isLoan')"  href="javascript:void(0)" >Loan</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a  [ngClass]="{'active show' : isLienHolder}" (click)="tabClicked('isLienHolder')"  href="javascript:void(0)" >LienHolder</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a  [ngClass]="{'active show' : isDates}" (click)="tabClicked('isDates')"  href="javascript:void(0)" >Dates</a></li>
          <li *ngIf="selectedPdfCategoryType?.pdfEntityModelTypes?.value === 'Sales' && isCustomEdit && !isUpload && !isEditMode && !isMerging && !isTreeInventory"><a  [ngClass]="{'active show' : isCustom}" (click)="tabClicked('isCustom')"  href="javascript:void(0)" >Custom</a></li>
    
        </ul>
    
      <div class="container-fluid  tree-section-container col-md-12 ">
        <div class="col-md-12">
      <br>
          <div class="row" >

           
               <!--DATA MENUS FOR EDIT PDF-->
              <div  class="col-md-2">
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isActiveVehicle"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person" [hidden] ="!isBuyer"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person" [hidden] ="!isDealer"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isCobuyer"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isDealInfo"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isDealInfoDollars"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isLoan"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isTaxes"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isCashDown"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isLienHolder"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isTrade1"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isTrade2"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isSoldVehice"></app-pdffield-person>
                <app-pdffield-person style="position: fixed;" [personType] = "personType" [person] = "person"  [hidden]="!isCustom"></app-pdffield-person>
               
      
                <div style="position: fixed;" *ngIf="isNotTree" class="tree-search">
                 <ul   class="tree-buttons-sec ">
                  <li>
                    <a (click) = "packageClick()" href="javascript:void(0)">
                      <img src="assets/images/rm-2.jpg">
                      <span>Sale Packages</span>
                    </a>
                  </li>
                  <!-- <li>
                    <a href="javascript:void(0)">
                      <img src="assets/images/rm-3.jpg">
                      <span>Filter</span>
                    </a>
                  </li> -->
                </ul>
              </div>
                  <!-- TREE VIEW-->
             
                  <div [hidden] = "!isNotTree" id="tree1" style="border-color: rgb(34, 34, 33); border-width: 1px; border: rgb(34, 34, 33) !important; position: fixed; overflow-y:scroll; cursor: pointer; min-height: 600px; min-width: 15%; resize: both;">
                    <div *ngFor="let item of pdfFormTypesCategorys; let i = index; ; count as c" class="ml-3">
                      <span  (click) = "typeCategorySelected(item)"  style="font-size: larger;">   <a [class.aUlHeader]="item === typeCategorySelected" style="text-decoration: none !important; color: black; font-style: italic;" class="aUlHeader ">
                      
                        &nbsp; <i style="color: rosybrown;" class="fa fa-download"></i> &nbsp;  {{item.value}}   ({{pdfFormTypesCategorys[i]?.pdfFormTypes.length }}) 
                      </a></span>
                      <div *ngIf = "item?.id === selectedPdfCategoryTypeId && isCollapsedCategory"> 
                      <!-- START SINGLE FORM TREE-->
                      <div *ngFor="let item of formTypes; let i = index; ; count as d" >
                        <span  (click) = "typeSelected(item)"  style="font-size: larger; color:blue;">   <a [class.aUlHeader]="item === selectedType" style="padding-left: 30px; font-size: smaller !important; text-decoration: none !important; color: black;" >
                       
                          {{item.value}}  ({{formTypes[i]?.pdfForms.length }}) 
                        </a></span>
                        <div *ngIf = "item?.id === selectedType?.id && isCollapsed">
                        <div  (dblclick) ="editNameDblClickForm(items)"   (click) = "pdfSelectedTreeView(items)"  *ngFor = "let items of item?.pdfForms">
                          <a style="padding-left: 20px; "  [class.active]="items === selectedItem">{{items.name}}</a>
                        </div>
                      </div>
                    </div> 
                    <!-- END SINGLE FORM TREE-->
                  </div>
                    </div>
                    
                    
                  
                  
                  </div>
          </div>
                <!--CONTENT AREA-->
              <div class="col-md-9 wrapper" style="padding-bottom: 50px;">
                 <!-- <div class="col-md-12">
            <div class="row"> -->
                <app-pdf-mergingitems (newPackageClickedPdfMergingItems)="set($event)" [packageButtonLocations] = "packageButtonLocations" [item] = "item"  *ngIf="isMerging && selectedPdfCategoryType?.pdfEntityModelTypes?.value == 'Inventory'"></app-pdf-mergingitems>
                <app-inventorylist  (callParentIsVehicleItem) = "inventoryListPrintClick($event)"  *ngIf="!isCustomEdit && !isUpload && !isEditMode && !isMerging && selectedPdfCategoryType?.pdfEntityModelTypes?.value == 'Inventory' " [selectedItem] = "selectedItem" [isNewFormTypeClick] ="isNewFormTypeClick"></app-inventorylist>
                <app-sales-list (callParentIsUpload) = "saleListPrintClick($event)" *ngIf="!isCustomEdit && !isUpload && !isEditMode && !isMerging && selectedPdfCategoryType?.pdfEntityModelTypes?.value == 'Sales' " [selectedItem] = "selectedItem" [isNewFormTypeClick] ="isNewFormTypeClick"></app-sales-list>
                <app-Pdf-View-Sized [pdfFieldNameTypes] = "pdfFieldNameTypes" [pdfForm] = "selectedItem" *ngIf="isCustomEdit && !isUpload && !isEditMode && !isMerging"></app-Pdf-View-Sized>
                <app-pdf-uploader (callParentCanceUploader) = "closeUploader()" (callParentGetForms2)="setStateList($event)"  (callParentGetForms)="getPdfFormsOnUploadComplete($event)" *ngIf="isUpload" [formNameToUpload] = "formNameToUpload" [selectedType] = "selectedType" ></app-pdf-uploader>
                <app-pdf-merging  [packageButtonLocations] = "packageButtonLocations" [sale] = "sale"   *ngIf="isMerging && selectedPdfCategoryType?.pdfEntityModelTypes?.value == 'Sales'"></app-pdf-merging>
                <app-pdfform-editor [sale] = "sale" (callParentIsUpload)="privateIsUploadRemoteClick($event)" [selectedType] = "selectedType" [formTypes] = "formTypes"  *ngIf="isEditMode" style="padding-top: -110px;" [pdfForm] = "pdfForm"></app-pdfform-editor>
              </div>
            <!-- </div>
          </div> -->
             <!-- <div class="col-md-12"> -->
          <!--MENU  style="position:fixed; right: 0;" -->
          <!-- <div  style="position:fixed; right: 0;"> -->
              <div    class="customer-sidebar sidebar-with-btns col-md-1">
                <div class="inner-sidebar">
                  <ul style="list-style-type:none;">
                  
                    <li *ngIf="!isEditMode">
                      <button *ngIf="typeIsFocus" [routerLink]="['/reportsmanager/pdfimport']" class="cstm-btn img-blk-btn">
                        <img src="assets/images/ap-icon-6.png" />
                        <span>Import PDF</span>
                      </button>
                    </li>
                    <li *ngIf="!isEditMode && selectedPdfCategoryType?.pdfEntityModelTypes?.value != null">
                      <button (click) = "mergePdf()" class="cstm-btn img-blk-btn">
                        <img src="assets/images/ap-icon-6.png" />
                        <span>Form Packages</span>
                      </button>
                    </li>
                    <li *ngIf="!isEditMode">
                      <button (click) = "gridView()" class="cstm-btn img-blk-btn">
                        <img src="assets/images/ap-icon-6.png" />
                        <span>Grid View</span>
                      </button>
                    </li>
                      <!-- <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                        <button (click) = "editPdf()" class="cstm-btn img-blk-btn">
                          <img src="assets/images/ap-icon-6.png" />
                          <span>{{editLabel}}</span>
                        </button>
                      </li> -->
                      <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                        <button (click) = "changeIsWithData()"  class="cstm-btn img-blk-btn">
                          <img src="assets/images/ap-icon-1.png" />
                          <span >
                            <div *ngIf="!isWithData">With Data</div>
                            <div *ngIf="isWithData">With Fields</div>
                         
                           </span>
                        </button>
                      </li>
                      <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                        <button (click) = "changeIsWithData('emptyform')"  class="cstm-btn img-blk-btn">
                          <img src="assets/images/ap-icon-1.png" />
                          <span >
                            <div>Empty PDF</div>
                           </span>
                        </button>
                      </li>
                      <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                        <button (click) = "deletePdf()"  class="cstm-btn img-blk-btn">
                          <img src="assets/images/ap-icon-1.png" />
                          <span >
                            <div>Delete Pdf</div>
                           </span>
                        </button>
                      </li>
                      <!-- <li *ngIf="!isEditMode">
                        <button (click) = "uploadPdf()" class="cstm-btn img-blk-btn">
                          <img src="assets/images/ap-icon-6.png" />
                          <span>Upload PDF</span>
                        </button>
                      </li> -->
                    </ul>
                  </div>
                <!-- </div> -->
      
         
      
          <!-- <div    class="customer-sidebar sidebar-with-btns col-md-2">
            <div class="inner-sidebar">
              <ul style="list-style-type:none;">
                <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                  <button (click) = "editPdf()" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-6.png" />
                    <span>Edit Pdf </span>
                  </button>
                </li>
                <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                  <button (click) = "createPdf()"  class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-1.png" />
                    <span >
                      <div *ngIf="!isWithData">Create New Pdf</div>
                     </span>
                  </button>
                </li>
                <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                  <button (click) = "PrintPdf('print')" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-6.png" />
                    <span>Print PDF</span>
                  </button>
                </li>
                <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                  <button (click) = "viewPdf('view')" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-6.png" />
                    <span>View PDF</span>
                  </button>
                </li>
                <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                  <button (click) = "downloadPdf('download')" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-6.png" />
                    <span>Download</span>
                  </button>
                </li>
                <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                  <button (click) = "changeIsWithData()"  class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-1.png" />
                    <span >
                      <div *ngIf="!isWithData">With Data</div>
                      <div *ngIf="isWithData">With Fields</div>
                   
                     </span>
                  </button>
                </li>
                <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                  <button (click) = "changeIsWithData('emptyform')"  class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-1.png" />
                    <span >
                      <div>Empty PDF</div>
                     </span>
                  </button>
                </li>
                <li *ngIf="!isEditMode && ifFormSelected && !typeIsFocus || isMerging">
                  <button (click) = "deletePdf()"  class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-1.png" />
                    <span >
                      <div>Delete Pdf</div>
                     </span>
                  </button>
                </li>
                <li *ngIf="!isEditMode">
                  <button (click) = "uploadPdf()" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-6.png" />
                    <span>Upload PDF</span>
                  </button>
                </li>
                <li *ngIf="!isEditMode">
                  <button [routerLink]="['/reportsmanager/pdfimport']" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-6.png" />
                    <span>Import PDFs</span>
                  </button>
                </li>
                <li *ngIf="!isEditMode">
                  <button (click) = "mergePdf()" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-6.png" />
                    <span>Merge PDF</span>
                  </button>
                </li>
                <li *ngIf="!isEditMode">
                  <button (click) = "gridView()" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-6.png" />
                    <span>Grid Vieww</span>
                  </button>
                </li>
              </ul>
            </div>
          </div> -->
        </div>
         <!-- </div>
          </div> -->
       </div>
         
        </div>
      
      
       
      </div>
    </div>
  



<!-- <div class="print-txt-sec">
  <p><a href="javascript:void(0)"><img src="assets/images/rm-4.jpg"></a> Create Merged Package of Pdf Forms</p>
</div> -->