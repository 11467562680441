
<div class="container-fluid customer-tabs-sec">
  <ul class="nav nav-tabs">
    <li><a [ngClass]="{'active show' : isCompanyInfo}"  (click)="tabClicked('isCompanyInfo')" href="javascript:void(0)" >Company Information</a></li>
    <li><a  [ngClass]="{'active show' : isSubscriptions}" (click)="tabClicked('isSubscriptions')"  href="javascript:void(0)" >Subscriptions</a></li>
    <li><a  *ngIf="userSubscriptionPlan"  [ngClass]="{'active show' : isSubscriptions}" (click)="tabClicked('isPayment')"  href="javascript:void(0)" >Payment</a></li>
  </ul>
</div>


    <app-company-information [hidden] = "!isCompanyInfo" [company] = "company"></app-company-information>
     <app-subscriptions  [userSubscriptionPlan] = "userSubscriptionPlan"  [hidden] = "!isSubscriptions" ></app-subscriptions>
     <app-payme-individual [hidden] = "!isPayment" ></app-payme-individual>


