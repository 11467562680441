import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { Item } from 'src/app/_models/item';
import { PdfEntityModelTypes } from 'src/app/_models/PDFentites/PdfEntityModelTypes';
import { PdfForms } from 'src/app/_models/PdfForms';
import { Sale } from 'src/app/_models/sale';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { PdfMergeInventoryPdfs } from '../pdffielddatats/merge-inventorydocuments';
import { PdfMergePdfs } from '../pdffielddatats/merge-pdfdocuments';


@Injectable({ providedIn: 'root' })
export class PdfEntityTypeForDataType {
  constructor(private alertify: ToastrService,
    private pdfService: PdfService,
    private pdfMergePdfs: PdfMergePdfs,
    private pdfInventoryMergePdf: PdfMergeInventoryPdfs) {}

  parsePdfEntityTypeForDataType(pdfForm: PdfForms, sale?: Sale, item?: Item): Observable<any>{
    switch (pdfForm?.pdfEntityModelTypes?.value) {
        case 'Sales': {
            return of(sale);
           break;
        }
        case 'Inventory': {
            return of(item);
        }
        default: {
            return of(sale);
        }
     }
  }

  copyPagesPerAnyEntity(entity, pdfForm, urlList, isWithData, emptyForm): Observable<boolean> {
    switch (pdfForm?.pdfEntityModelTypes?.value) {
        case 'Sales': {
            this.pdfService.changePdfForm(pdfForm);
            if (entity.id > 0 ) {
             this.pdfMergePdfs.copyPages(entity, pdfForm.pdfFormUrl, urlList, isWithData, emptyForm).then(
                 data => {
             //  this.alertify.success('Sale Pdfs Populated.');
               return of(true);
             });
            }
           break;
        }
        case 'Inventory': {
            this.pdfService.changePdfForm(pdfForm);
            if (entity.id > 0 ) {
             this.pdfInventoryMergePdf.copyPages(entity, pdfForm.pdfFormUrl, urlList, isWithData, emptyForm).then(
                 data => {
            //   this.alertify.success('Inventory Pdfs Populated.');
               return of(true);
             });
            }

            break;
        }
        default: {
            return of(false);
         }
     }
  }
}

