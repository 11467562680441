import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
@Injectable({ providedIn: 'root' })
export class SaleDealInfoDollars {

    fnz02 = (num) => num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
        })

          fnz04 = (num) => num.toLocaleString('en-US', {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
            useGrouping: false,
            })
            fnz0 = (num) => num.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
                })

      fillPdfFieldsDealInfoDollars(sale: Sale, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
        if (pdfFields.length > 0 ) {
        let  field = '';
        let newpdfField = {} as PdfSaleFields;
        const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
        field = element.field;
        const str = field;
        const matches = str.match(/\d+/g);
        let i;
        if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }

        field = field.replace(i.toString(), '');
            switch (field) {
                case prefix + 'Acct': {
                    newpdfField  = {
                        field: prefix + 'Acct' + i,
                        value: sale?.acct,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case 'Fnz_02': {
                    newpdfField  = {
                        field: 'Fnz_02' + i,
                        value: (0).toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case 'NA': {
                    newpdfField  = {
                        field: 'NA' + i,
                        value: 'N/A',
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'SellingPrice': {
                    newpdfField  = {
                        field: prefix + 'SellingPrice' + i,
                        value: sale?.sellingPrice.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Predelivery': {
                    newpdfField  = {
                        field: prefix + 'Predelivery' + i,
                        value: sale?.predelivery.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Title': {
                    newpdfField  = {
                        field: prefix + 'Title' + i,
                        value: sale?.title.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Tag': {
                    newpdfField  = {
                        field: prefix + 'Tag' + i,
                        value: sale?.tag.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Registration': {
                    newpdfField  = {
                        field: prefix + 'Registration' + i,
                        value: sale?.registration.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'MiscTaxable': {
                    newpdfField  = {
                        field: prefix + 'MiscTaxable' + i,
                        value: sale?.miscTaxable.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'MiscNonTax': {
                    newpdfField  = {
                        field: prefix + 'MiscNonTax' + i,
                        value: sale?.miscNonTax.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'SmogC': {
                    newpdfField  = {
                        field: prefix + 'SmogC' + i,
                        value: sale?.smogC1.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'TradeAllow': {
                    newpdfField  = {
                        field: prefix + 'TradeAllow' + i,
                        value: sale?.tradeAllow.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'TradeLien': {
                    newpdfField  = {
                        field: prefix + 'TradeLien' + i,
                        value: sale?.tradeLien.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'CashDown': {
                    newpdfField  = {
                        field: prefix + 'CashDown' + i,
                        value: sale?.cashDown.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Warranty': {
                    newpdfField  = {
                        field: prefix + 'Warranty' + i,
                        value: sale?.warranty.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'CreditLife': {
                    newpdfField  = {
                        field: prefix + 'CreditLife' + i,
                        value: sale?.creditLife.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'Disability': {
                    newpdfField  = {
                        field: prefix + 'Disability' + i,
                        value: sale?.disability.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'DocFees': {
                    newpdfField  = {
                        field: prefix + 'DocFees' + i,
                        value: sale?.docFees.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
                case prefix + 'AmountFinanced': {
                    newpdfField  = {
                        field: prefix + 'AmountFinanced' + i,
                        value: sale?.amountFinanced.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                break;
                }
            }
        });
    return of(newPdfFields);
        }
        return of(pdfFields);
    }
}


