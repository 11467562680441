import { Component, OnInit } from '@angular/core';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Pagination, PaginatedResult } from 'src/app/_models/pagination';
import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { PersonDataService } from 'src/app/_services/personinfo/person-data.service';

import { Person } from 'src/app/_models/person';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { ToastrService } from 'ngx-toastr';
import { AddPersonModalComponent } from '../add-person-modal/add-person-modal.component';
import { PersonPhoto } from 'src/app/_models/personphoto';
import { AddnewPersonmodalComponent } from '../addnew-personmodal/addnew-personmodal.component';

@Component({
  selector: 'app-people-list',
  templateUrl: './people-list.component.html',
  styleUrls: ['./people-list.component.css']
})
export class PeopleListComponent implements OnInit {
  persons: PersonLinqResultToReturnDto[];
  person1: Person;
  photos = [] as PersonPhoto[];
  selectedCompany: UserAssignedToCompany;
  pagination: Pagination;
  userParams = {} as any;
  isHasPurchased = true;
  isLastNameAscending = true;
  isFirstNameAscending = true;
  isCityAscending = true;
  isStateAscending = true;
  isBirthDateAscending = true;
  isInsuranceExpire = true;
  isEmailAscending = true;
  bsModalRefBuyer: any;
  isAscending = true;
  isCustomerAscending = true;
  isWebleadAscending = true;
  isFilterDates = false;
  personToEdit: Person;
  isEmployee = false;
  isCustomer = false;
  isBirthday = false;
  isAll = false;
  isWebsiteLead = false;
  constructor(private authService: AuthService,
    private alertify: ToastrService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
  private personService: PersonDataService,
  private excelService: ExcelService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany = company);
    this.getPersons();
  }
  getPersons() {
    this.route.data.subscribe(data => {
      this.persons = data.persons.result;
      this.pagination = data.persons.pagination;
    });
  }
  showEmaileTrue() {
    this.isFilterDates = !this.isFilterDates;
  }
  showBirthdayTrue() {
    this.isFilterDates = !this.isFilterDates;
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadPeople();
  }
  loadPeople() {
    this.personService.getCustomerLinqList(this.selectedCompany.parentGuid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult <PersonLinqResultToReturnDto[]>) => {
      this.persons = data.result;
      this.pagination = data.pagination;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Persons Retrieved.');
    });
  }
  sort(e) {
    console.log(e);
    this.isHasPurchased = true;
    this.isWebleadAscending = true;
    this.isLastNameAscending = true;
    this.isFirstNameAscending = true;
    this.isCityAscending = true;
    this.isStateAscending = true;
    this.isBirthDateAscending = true;
    this.isInsuranceExpire = true;
    this.isEmailAscending = true;
    this.isCustomerAscending = true;
    this.isAscending = !this.isAscending;
    this.userParams.isAscending = this.isAscending;
    this.userParams.itemsPerPage = 15;
    this.userParams.orderBy = e;
    this.loadPeople();
    if (e === 'lastName') {this.isLastNameAscending = this.isAscending; }
    if (e === 'firstName') {this.isFirstNameAscending = this.isAscending; }
    if (e === 'city') {this.isCityAscending = this.isAscending; }
    if (e === 'state') {this.isStateAscending = this.isAscending; }
    if (e === 'bithday') {this.isBirthDateAscending = this.isAscending; }
    if (e === 'insExpires') {this.isInsuranceExpire = this.isAscending; }
    if (e === 'email') {this.isEmailAscending = this.isAscending; }
    if (e === 'personType') {this.isCustomerAscending = this.isAscending; }
    if (e === 'weblead') {this.isWebleadAscending = this.isAscending; }

  }

  setSearchBy(e, event) {
    setTimeout(() => {
      console.log(event);
      console.log(e);
      this.userParams.searchBy = e;
      this.userParams.searchFor = event;
      this.loadPeople();
    }, 2000);
  }
  editPerson(e) {
    this.personService.getPerson(this.selectedCompany.parentGuid, e.id).subscribe(data =>    {
      this.person1 = data;
      this.photos = data.personPhotos;
    }, error => {this.alertify.error(error); }, () => {
      this.alertify.success('Success');
      const initialState = {
        person: this.person1,
        selectedCompany: this.selectedCompany,
        photos: this.photos
      };
      console.log(this.person1);
      this.bsModalRefBuyer = this.modalService.show(AddPersonModalComponent, {initialState, class: 'modal-lg'});
      this.bsModalRefBuyer.content.userWebsiteToReturnDto.subscribe(data => {
        console.log(data);
        this.photos = data.personPhotos;
      });
      this.bsModalRefBuyer.content.update.subscribe((values) => {
        this.personToEdit = values;
        this.personToEdit.id = e.id;
        this.personService.edit(this.selectedCompany.parentGuid, this.personToEdit).subscribe(data => {
          this.person1 = data.person;
          this.loadPeople();
        });
        this.alertify.success('Customer Retrived');
      }, error => {this.alertify.error(error); }, () => {
      });
    });
  }

  filter(e) {
    console.log(e);
    this.isEmployee = false;
    this.isCustomer = false;
    this.isBirthday = false;
    this.isAll = false;
    this.isWebsiteLead = false;
    if (e === 'Customer') {this.isCustomer = true; }
    if (e === 'Employee') { this.isEmployee = true; }
    if (e === 'Birthday') { this.isBirthday = true; }
    if (e === 'All') { this.isAll = true; }
    if (e === 'WebsiteLead') { this.isWebsiteLead = true; }
    this.userParams.filter = e;
    this.loadPeople();
  }

  exportAsXLSX(): void {
    this.pagination.itemsPerPage = 1000000;
    this.personService.getCustomerLinqList(this.selectedCompany.parentGuid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult<PersonLinqResultToReturnDto[]>) => {
      this.excelService.exportAsExcelFile(data.result, 'Vehicles Advertised');
      this.pagination.itemsPerPage = 15;
    }, error => { this.alertify.error(error); }, () => { this.alertify.success('Your Export is Ready'); } );
  }
  printDiv() {
    const newLocal = 'print_frame';
    // tslint:disable-next-line:variable-name
    const newLocal_1 = 'print_frame';
    // tslint:disable-next-line:variable-name
    const newLocal_2 = 'print_frame';
  window.frames[newLocal].document.body.innerHTML = document.getElementById('printableTable').innerHTML;
  window.frames[newLocal_1].window.focus();
  window.frames[newLocal_2].window.print();
  }

  addPerson() {
    const initialState = {
      person: this.person1,
      selectedCompany: this.selectedCompany,
      photos: this.photos
    };
    console.log(this.person1);
    this.bsModalRefBuyer = this.modalService.show(AddnewPersonmodalComponent, {initialState, class: 'modal-lg'});
    this.bsModalRefBuyer.content.update.subscribe(data => {
      console.log(data);
        this.personService.addPeson(data, this.selectedCompany.parentGuid).subscribe(
          (value) => {
            console.log(value);
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.alertify.success('Person Added');
            this.loadPeople();
          }
        );
    });
  }
}
