// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:5000/api/',
  // hubUrl: 'http://localhost:5000/hubs/',
  // supportUrl: 'http://localhost:5010/api/',
  // supportUrl: 'http://chipperdave.com/api/',

  apiUrl: 'https://www.visibleontheweb.com/api/',
  hubUrl: 'https://www.visibleontheweb.com/hubs/',
  supportUrl: 'https://chipperdave.com/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
//  git add .
// git commit -m "General Update"
// git push origin master

// identity update
// dotnet ef migrations add IdentityAddress -p Infrastructure -s API -c AppIdentityDbContext

// storecontext ef migrations
// dotnet ef migrations add updateparagrapphsplacementid  -p Infrastructure -s API -c StoreContext

// PUBLISHING
// dotnet publish -c Release -o publish clmnet.sln

// docker-compose up --detach
