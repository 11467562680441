import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AmoLinqPayments } from '../_models/DTOS/amolinqpayments';
import { AmoInstallmentService } from '../_services/amoinstallmentservices/amoinstallments.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class AmoLinqPaymentsResolver implements Resolve<any> {
    pageNumber = 1;
    pageSize = 15;

    constructor(private getPayments: AmoInstallmentService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<AmoLinqPayments[]> {
        const newLocal = 'parentGuid';
        console.log(route.params[newLocal]);
        return this.getPayments.retrievePagedLoans(route.params[newLocal], this.pageNumber, this.pageSize).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving loan list.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
