import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { Website } from 'src/app/_models/website';
import { CompanyDepartment } from 'src/app/_models/CompanyDepartment';
import { CompanyDepartmentType } from 'src/app/_models/CompanyDepartmentType';
import { NgForm } from '@angular/forms';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { WebsiteCompany } from 'src/app/_models/company';
import { CompanyDepartmentsService } from 'src/app/_services/companyDepartments/company-departments.service';
import { CompanyPhoto } from 'src/app/_models/companyphoto';
import { DepartmentphotosComponent } from './departmentphotos/departmentphotos.component';

import { CompanydeptModalComponent } from './companydept-modal/companydept-modal.component';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ServiceofferaddModalComponent } from 'src/app/modals/serviceofferadd-modal/serviceofferadd-modal.component';
import { ServiceOffer } from 'src/app/_models/serviceoffer';
import { ServiceOffersService } from 'src/app/_services/advertising/serviceoffer.service';
import { WebsiteService } from 'src/app/_services/advertising/website.service';

@Component({
  selector: 'app-company-departments',
  templateUrl: './company-departments.component.html',
  styleUrls: ['./company-departments.component.css']
})
export class CompanyDepartmentsComponent implements OnInit {
@ViewChild('editForm', {static: true}) editForm: NgForm;
@Input() company: WebsiteCompany;
@Input() website: Website;
@Output() myCompanyDepartment = new EventEmitter();
@ViewChild(DepartmentphotosComponent, {static: true}) child;
companyPhotos: CompanyPhoto[] = [];
isHidden: boolean;
@Input() companyDepartments: CompanyDepartment[];
@Input() model: CompanyDepartment;
companyDepartmentToUpdate: CompanyDepartment;
isSelected: boolean;
userAssignedToCompany: UserAssignedToCompany;
companyGuid: string;
bsModalRef: any;
servOffer = new ServiceOffer();
@HostListener('window:beforeunload', ['$event'])
unloadNotification($event: any) {
  if (this.editForm.dirty) {
    $event.returnValue = true;
  }
}
  constructor(
    private alertify: ToastrService,
    private companyDepartmentsService: CompanyDepartmentsService,
    private modalService: BsModalService,
    private serviceOffers: ServiceOffersService,
    private websiteService: WebsiteService,
    private authService: AuthService) {
      const servOffer = new ServiceOffer();
    }

  ngOnInit() {
      this.authService.company.subscribe(company => this.userAssignedToCompany = company);
  }

  navigateDepartment(e) {
    this.model = e;
    console.log(e);
    this.myCompanyDepartment.emit(e);
    this.isHidden = true;
    this.companyDepartmentsService.getDepartmentPhotos(this.website?.company?.id, e.id).subscribe(data => {
      this.companyPhotos = data;
      this.model = e;
      this.child.initializeUploader();
    }, error => {
      this.alertify.error(error);
      this.model = e;
      this.child.initializeUploader();
    }, () => {
      this.alertify.success('Photos Retrieved');
      this.isSelected = true;
      this.model = e;
      this.child.initializeUploader();
    });
  }
  addServiceOffer() {
    const config = {
      class: 'modal-dialog-large',
      initialState: {
        serviceOfferDept: this.model.departmentName
       }
    };
    this.bsModalRef = this.modalService.show(ServiceofferaddModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values: ServiceOffer) => {
      this.servOffer = values;
      this.servOffer.WebsiteCompanyId = this.model?.websiteCompanyId;
      this.servOffer.websiteGuid = this.website?.websiteGuid;
      this.servOffer.parentGuid = this.userAssignedToCompany?.parentGuid;
      this.serviceOffers.add(this.servOffer).subscribe(data => {
        console.log(data);
        this.websiteService.changeWebsite(data);
      });
    });
    console.log(this.servOffer);
  }
  onSubmitDepartment(e) {
    console.log(e);
    this.companyDepartmentToUpdate = e.value;
    console.log(this.model);
      this.companyDepartmentsService.edit(this.model, this.model.id).subscribe(data => {
        this.model = data;
      },
        error => { this.alertify.error(error); },
        () => {this.alertify.success('Department Updated'); });
  }

  addNew() {
    this.bsModalRef = this.modalService.show(CompanydeptModalComponent);
    this.bsModalRef.content.updateData.subscribe((values) => {
      console.log(this.userAssignedToCompany.parentGuid, values);
      this.companyDepartmentsService.add(this.userAssignedToCompany.parentGuid, values, this.website.websiteGuid).subscribe(data => {
        this.companyDepartments = data;
        this.website.company.companyDepartments = data;
      }, error => {this.alertify.error(error); }, () => {this.alertify.success('Payment Type Added'); });
    }, () => { this.websiteService.changeWebsite(this.website); });
  }
}
