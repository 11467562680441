import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { LoanPaymentsComponent } from './loan-payments/loan-payments.component';
import { LoanAddpaymentComponent } from './loan-addpayment/loan-addpayment.component';
import { LoanListComponent } from './loan-list/loan-list.component';
import { AddPaymentModalComponent } from './add-payment-modal/add-payment-modal.component';
import { LoanRoutingModule } from './loan-routing.module';



@NgModule({
  declarations: [
    LoanPaymentsComponent,
    LoanListComponent,
    LoanAddpaymentComponent,
    AddPaymentModalComponent
],
  entryComponents: [
    AddPaymentModalComponent
],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    AgChartsAngularModule,
    LoanRoutingModule,
    AgGridModule.withComponents([]),
  ],
  providers: [],
})

export class LoanModule { }
