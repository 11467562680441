import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddsalenoteModalComponent } from 'src/app/modals/addsalenote-modal/addsalenote-modal.component';
import { EditsalenoteModalComponent } from 'src/app/modals/editsalenote-modal/editsalenote-modal.component';
import { Sale } from 'src/app/_models/sale';
import { SaleNotes } from 'src/app/_models/SaleNote';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';

@Component({
  selector: 'app-sale-notes',
  templateUrl: './sale-notes.component.html',
  styleUrls: ['./sale-notes.component.css']
})
export class SaleNotesComponent implements OnInit, AfterViewInit {
  @Input() sale: Sale;
  bsModalRef: any;
  isActive = false;
  isAll = true;
  isAdd = false;
  isDeleted = false;
  @Input() saleNotesCache = [] as SaleNotes[];
  @Input() saleNotes = [] as SaleNotes[];
  selectedCompany1: UserAssignedToCompany;
  constructor(private modalService: BsModalService,
    private alertify: ToastrService,
    private saleService: SaleService, private authService: AuthService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
   
  }
  tabClick(e){
    console.log(e);
    this.isActive = false;
    this.isAll = false;
    this.isAdd = false;
    this.isDeleted = false;
    if (e === 'isActive') { this.isActive = true; this.filterNotes(); }
    if (e === 'isAll') { this.isAll = true; this.filterNotes(); }
    if (e === 'isAdd') { this.isAdd = true; this.openModal(); }
    if (e === 'isDeleted') { this.isDeleted = true; this.filterNotes(); }
  }
  ngAfterViewInit() {
    console.log('after init fired');
    this.filterNotes();
  }
  doubleClick(e) {}
  openModal() {
    console.log('reached gap');
    const config = {
      class: 'modal-lg',
      initialState: {
       sale: this.sale
      }
    };
    this.bsModalRef = this.modalService.show(AddsalenoteModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe(values => {
      this.saleService.addSaleNote(this.selectedCompany1.parentGuid, values, this.sale.id).subscribe(data => {
        this.saleNotes = data;
        this.saleNotesCache = data;
        this.tabClick('isAll');
      }, error => { this.alertify.error(error); }, () => {
        this.alertify.success('Note Saved');
        console.log(this.sale.saleNotes);
        this.filterNotes();
      });
    });
  }
  filterNotes() {
    this.saleNotes = this.saleNotesCache;
    if (this.isActive || this.isDeleted) {
      this.saleNotes = this.saleNotes.filter(note => note.isDeleted === this.isDeleted);
     // this.isDeleted = !this.isDeleted;
    }
  }
  editNote(e: SaleNotes) {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        saleNotes: e
      }
    };
    this.bsModalRef = this.modalService.show(EditsalenoteModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe(values => {
      this.saleService.editSaleNote(this.selectedCompany1.parentGuid, values, this.sale.id).subscribe(data => {
        this.saleNotes = data;
       this.saleNotesCache = data;
       this.tabClick('isAll');
      }, error => { this.alertify.error(error); }, () => {
        this.alertify.success('Note Saved');
        this.filterNotes();
      });

    });
  }
}

