import { User } from './user';

export class UserParams {
    gender: string;
    minAge = 18;
    maxAge = 99;
    pageNumber = 1;
    pageSize = 50;
    orderBy = 'lastActive';
    year = '0';
    searchBy?: string;
    searchFor?: string;
    isAscending = 'true';
    filter: 'all';
    returnByBodyTypeId: '0';
    make: string;
    model: string;

    constructor(user?: User) {
     //   this.gender = user.gender === 'female' ? 'male' : 'female';
    }
}
