import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PersonLinqResultToReturnDtoResolver } from '../_resolvers/PersonLinqResultToReturnDto.resolver';
import { PeopleListComponent } from './people-list/people-list.component';



const routes: Routes = [

    {path: 'list/:parentGuid', component: PeopleListComponent, resolve: { persons: PersonLinqResultToReturnDtoResolver }},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PeopleRoutingModule {}

