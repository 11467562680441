import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RealPropertyLinqListDto } from 'src/app/_models/DTOS/realpropertylinqlistdto';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { RealPropertyService } from 'src/app/_services/realpropertyservices/realpropertyservice';
import { AddHousemodalComponent } from '../add-housemodal/add-housemodal.component';

@Component({
  selector: 'app-realestate-list',
  templateUrl: './realestate-list.component.html',
  styleUrls: ['./realestate-list.component.css']
})
export class RealestateListComponent implements OnInit {
  selectedCompany: UserAssignedToCompany;
  pagination: Pagination;
  properties = [] as RealPropertyLinqListDto[];
  userParams = {} as any;
  isAll = true;
  ispropertyName = false;
  isFirstNameAscending = false;
  isCityAscending = false;
  isStateAscending = false;
  isCreatedDateAscnding = false;
  bsModalRefBuyer: any;
  constructor(private alertify: ToastrService,
    private authService: AuthService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private realPropertyService: RealPropertyService,
    private router: Router) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany = data; });
    this.load();
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.load();
  }
  load() {
    this.route.data.subscribe(data => {
      this.properties = data.persons.result;
      this.pagination = data.persons.pagination;
    });
  }
  sort(e) {
    this.isAll = true;
    this.ispropertyName = false;
    this.isFirstNameAscending = false;
    this.isCityAscending = false;
    this.isStateAscending = false;
    this.isCreatedDateAscnding = false;
    if (e === 'ispropertyName') {   this.ispropertyName = true; }
    if (e === 'isFirstNameAscending') {  this.isFirstNameAscending = true; }
    if (e === 'isCityAscending') {  this.isCityAscending = true; }
    if (e === 'isStateAscending') {  this.isStateAscending = true; }
    if (e === 'isCreatedDateAscnding') {  this.isCreatedDateAscnding = true; }

  }
  edit(e) {
    console.log(e);
    this.router.navigate(['realestate/edit/' + e.id + '/' + this.selectedCompany.parentGuid]);
  }
  filter(e) {
    console.log(e);
    this.isAll = false;
    if (e === 'All') { this.isAll = true; }
    this.userParams.filter = e;
    this.load();
  }
  add() {
    const initialState = {
      selectedCompany: this.selectedCompany,
    };
    this.bsModalRefBuyer = this.modalService.show(AddHousemodalComponent, {initialState, class: 'modal-lg'});
    this.bsModalRefBuyer.content.update.subscribe(data => {
      console.log(data);
        this.realPropertyService.add(data, this.selectedCompany.parentGuid).subscribe(
          (value) => {
            console.log(value);
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.alertify.success('Person Added');
            this.load();
          }
        );
    });
  }
  exportAsXLSX(): void {
    this.pagination.itemsPerPage = 1000000;
    // this.personService.getCustomerLinqList(this.selectedCompany.parentGuid, this.pagination.currentPage,
    //   this.pagination.itemsPerPage, this.userParams)
    // .subscribe((data: PaginatedResult<PersonLinqResultToReturnDto[]>) => {
    //   this.excelService.exportAsExcelFile(data.result, 'Vehicles Advertised');
    //   this.pagination.itemsPerPage = 15;
    // }, error => { this.alertify.error(error); }, () => { this.alertify.success('Your Export is Ready'); } );
  }
  printDiv() {
    const newLocal = 'print_frame';
    // tslint:disable-next-line:variable-name
    const newLocal_1 = 'print_frame';
    // tslint:disable-next-line:variable-name
    const newLocal_2 = 'print_frame';
  window.frames[newLocal].document.body.innerHTML = document.getElementById('printableTable').innerHTML;
  window.frames[newLocal_1].window.focus();
  window.frames[newLocal_2].window.print();
  }
}
