<br /><br />

  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="depreciate-costs2"  class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">

                  
                    <div
                      class="card"
                      style="background-color: #f0f0f0 !important"
                    >
                      <div class="col-sm-12">
                        <div *ngIf="editForm.dirty" class="alert alert-info">
                          <strong>Information:</strong> You have made changes.
                          Any unsaved changes will be lost!
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-11">
                          <form
                            novalidate
                            #editForm="ngForm"
                            (ngSubmit)="onSubmitSocial(editForm)"
                          >
                            <div class="container">
                              <!-- Social next field-->
                              <div class="col">
                                <div class="form-group form-inline">
                                  <label 
                                    >PERSONAL DOMAIN</label
                                  >
                                  <input
                                    style="width: 76%; "
                                    type="text"
                                    class="form-control"
                                    [ngModel]="socialMedia?.companyWebsite"
                                    (ngModelChange)="
                                      socialMedia.companyWebsite = $event
                                    "
                                    name="companyWebsite"
                                    #companyWebsite="ngModel"
                                  />
                                </div>
                              </div>
                              <!-- Social next field-->
                              <div class="col">
                                <div class="form-group  form-inline">
                                  <label >Face Book URL</label>
                                  <input
                                    type="text" style="width: 76%;"
                                    class="form-control"
                                    [ngModel]="socialMedia?.facebookUrl"
                                    (ngModel)="socialMedia.facebookUrl"
                                    (ngModelChange)="
                                      website.socialMedia.facebookUrl = $event
                                    "
                                    name="facebookUrl"
                                    #facebookUrl="ngModel"
                                  />
                                </div>
                              </div>
                              <!-- Social next field-->
                              <div class="col">
                                <div class="form-group  form-inline">
                                  <label >Twitter URL</label>
                                  <input
                                    type="text" style="width: 76%;"
                                    class="form-control"
                                    [ngModel]="socialMedia?.twitterUrl"
                                    (ngModelChange)="socialMedia.twitterUrl = $event"
                                    name="twitterUrl"
                                    #twitterUrl="ngModel"
                                  />
                                </div>
                              </div>
                              <!-- Social next field-->
                              <div class="col">
                                <div class="form-group  form-inline">
                                  <label >Google Plus</label>
                                  <input
                                    type="text" style="width: 76%;"
                                    class="form-control"
                                    [ngModel]="socialMedia?.googlePlusUrl"
                                    (ngModelChange)="
                                      socialMedia.googlePlusUrl = $event
                                    "
                                    name="googlePlusUrl"
                                    #googlePlusUrl="ngModel"
                                  />
                                </div>
                              </div>
                              <!-- Social next field-->
                              <div class="col">
                                <div class="form-group  form-inline">
                                  <label >Instagram</label>
                                  <input
                                    type="text" style="width: 76%;"
                                    class="form-control"
                                    [ngModel]="socialMedia?.instagramUrl"
                                    (ngModelChange)="
                                      socialMedia.instagramUrl = $event
                                    "
                                    name="instagramUrl"
                                    #instagramUrl="ngModel"
                                  />
                                </div>
                              </div>
                              <!-- Social next field-->
                              <div class="col">
                                <div class="form-group  form-inline">
                                  <label >You Tube</label>
                                  <input
                                    type="text" style="width: 76%;"
                                    class="form-control"
                                    [ngModel]="socialMedia?.youTubeUrl"
                                    (ngModelChange)="socialMedia.youTubeUrl = $event"
                                    name="youTubeUrl"
                                    #youTubeUrl="ngModel"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /.table-sec -->
              
            </div>
          </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-1 mr-2">
        <div class="inner-sidebar">
          <ul style="list-style-type:none;">
            <li>
              <button
                type="submit" *ngIf="editForm.dirty" 
                (click)="onSubmitSocial(editForm)"
                class="cstm-btn img-blk-btn"
              >
                <img src="assets/images/ap-icon-1.png" />
                <span>Save</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

