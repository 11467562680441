<br>
<div class="container-fluid customer-tabs-sec">
  <ul class="nav nav-tabs">
    <li><a [ngClass]="{'active show' : isHouseInfo}"  (click)="tabClicked('isHouseInfo')" href="javascript:void(0)" >House Info</a></li>
    <li><a  [ngClass]="{'active show' : isRepairs}" (click)="tabClicked('isRepairs')"  href="javascript:void(0)" >Repairs</a></li>
    <li><a  [ngClass]="{'active show' : isCarryingCost}" (click)="tabClicked('isCarryingCost')"  href="javascript:void(0)" >Carrying Cost</a></li>
    <li><a  [ngClass]="{'active show' : isPhotos}" (click)="tabClicked('isPhotos')"  href="javascript:void(0)" >Photos</a></li>
  </ul>
</div>
<app-repair-list *ngIf="isRepairs" [house] = "house" [repairTypes] = "repairTypes" [repairs]="repairs"></app-repair-list>
  <!-- <app-date-filter *ngIf = "isFilterDates"></app-date-filter> -->
  <br>
  <div class="customer-form-sec" *ngIf="isHouseInfo">
    <div class="container-fluid">
      <div class="row">
        <div class="form-sec tab-content col-md-11">
       
            <div class="card" style="background-color: #f0f0f0 !important">
              <div class="col-md-12">
                <div *ngIf="editForm.dirty" class="alert alert-info">
                  <strong>Information:</strong> You have made changes. Any unsaved changes
                  will be lost!
                </div>
              </div>
              <form #editForm="ngForm" id="editForm">
                <div class="row">
                <div class="col-md-6">

                
                <div class="row">
                  <div class="col-md-12">
                    <div class="col-md-12">
                      <div
                        class="form-group form-inline"
                        [ngClass]="{
                          'has-success': propertyName?.valid && (propertyName?.touched || propertyName?.dirty),
                          'has-danger': propertyName?.invalid && (propertyName?.touched || propertyName?.dirty)
                        }"
                      >
                        <label>Property Name</label>
                        <div class="input-group">
                          <input
                          style="width: 70%"
                            type="text"
                            class="form-control"
                            [ngModel]="house?.propertyName"
                            (ngModelChange)="house.propertyName = $event"
                            name="propertyName"
                            #propertyName="ngModel"
                            minlength="2"
                            required
                          />
                          <div
                            *ngIf="!propertyName.errors && (propertyName?.touched || propertyName?.dirty)"
                            class="input-group-prepend"
                          >
                            <button type="button" class="btn btn-outline-success">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          class="form-group-feedback"
                          *ngIf="propertyName.errors && (propertyName?.touched || propertyName?.dirty)"
                        >
                          <p style="color: red" *ngIf="propertyName?.errors.required">
                            Name Is Required
                          </p>
                          <p style="color: red" *ngIf="propertyName?.errors.minlength">
                            Name required
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div
                        class="form-group form-inline"
                        [ngClass]="{
                          'has-success': purchasedPrice?.valid && (purchasedPrice?.touched || purchasedPrice?.dirty),
                          'has-danger': purchasedPrice?.invalid && (purchasedPrice?.touched || purchasedPrice?.dirty)
                        }"
                      >
                        <label>Purchase Price</label>
                        <div class="input-group">
                          <input
                            type="number"
                            class="form-control"
                            [ngModel]="house?.itemAmountSums?.purchasedPrice"
                            (ngModelChange)="house.itemAmountSums.purchasedPrice = $event"
                            name="purchasedPrice"
                            #purchasedPrice="ngModel"
                            minlength="2"
                            required
                          />
                          <div
                            *ngIf="!purchasedPrice.errors && (purchasedPrice?.touched || purchasedPrice?.dirty)"
                            class="input-group-prepend"
                          >
                            <button type="button" class="btn btn-outline-success">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          class="form-group-feedback"
                          *ngIf="purchasedPrice.errors && (purchasedPrice?.touched || purchasedPrice?.dirty)"
                        >
                          <p style="color: red" *ngIf="purchasedPrice?.errors.required">
                            PRice Is Required
                          </p>
                          <p style="color: red" *ngIf="purchasedPrice?.errors.minlength">
                            PurchasePrice required
                          </p>
                        </div>
                      </div>
                    </div>
                    <fieldset ngModelGroup="addressDetails">
                         <!--ZIP-->
                        <div
                          class="form-group form-inline"
                          [ngClass]="{
                            'has-success': zip?.valid && (zip?.touched || zip?.dirty),
                            'has-danger': zip?.invalid && (zip?.touched || zip?.dirty)
                          }"
                        >
                          <label>Zip</label>
                          <div class="input-group">
                            <input
                              type="text"
                              style="width: 100px;"
                              class="form-control"
                              [ngModel]="house?.addressDetails?.zip"
                              (ngModelChange)="house.addressDetails.zip = $event"
                              name="zip"
                              #zip="ngModel"
                              minlength="2"
                              required
                            />
                            <div
                              *ngIf="!zip.errors && (zip?.touched || zip?.dirty)"
                              class="input-group-prepend"
                            >
                              <button type="button" class="btn btn-outline-success">
                                <i class="fa fa-check" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                          <div
                            class="form-group-feedback"
                            *ngIf="zip.errors && (zip?.touched || zip?.dirty)"
                          >
                            <p style="color: red" *ngIf="zip?.errors.required">
                              Zip Is Required
                            </p>
                            <p style="color: red" *ngIf="zip?.errors.minlength">
                              Zip requires at least 2 characters
                            </p>
                          </div>
                        </div>
                      <!--STREET-->
                      <div
                        class="form-group form-inline"
                        [ngClass]="{
                          'has-success':
                            street?.valid && (street?.touched || street?.dirty),
                          'has-danger':
                            street?.invalid && (street?.touched || street?.dirty)
                        }"
                      >
                        <label>Street</label>
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control"
                            [ngModel]="house?.addressDetails?.street"
                            (ngModelChange)="house.addressDetails.street = $event"
                            name="street"
                            #street="ngModel"
                            required
                          />
                          <div
                            *ngIf="!street.errors && (street?.touched || street?.dirty)"
                            class="input-group-prepend"
                          >
                            <button type="button" class="btn btn-outline-success">
                              <i class="fa fa-check" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                        <div
                          class="form-group-feedback"
                          *ngIf="street.errors && (street?.touched || street?.dirty)"
                        >
                          <p style="color: red" *ngIf="street?.errors.required">
                            Street Is Required
                          </p>
                        </div>
                      </div>
                       <!--CITY-->
                          <div
                            class="form-group form-inline"
                            [ngClass]="{
                              'has-success':
                              city?.valid && (city?.touched || city?.dirty),
                              'has-danger':
                              city?.invalid && (city?.touched || city?.dirty)
                            }"
                          >
                            <label>City</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                [ngModel]="house?.addressDetails?.city"
                                (ngModelChange)="house.addressDetails.city = $event"
                                name="city"
                                #city="ngModel"
                                required
                              />
                              <div
                                *ngIf="!city.errors && (city?.touched || city?.dirty)"
                                class="input-group-prepend"
                              >
                                <button type="button" class="btn btn-outline-success">
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                            <div
                              class="form-group-feedback"
                              *ngIf="city.errors && (city?.touched || city?.dirty)"
                            >
                              <p style="color: red" *ngIf="city?.errors.required">
                                City Is Required
                              </p>
                            </div>
                          </div>
                       <!--COUNTY-->
                      <div class="row">
                        <div class="col">
                          <div
                            class="form-group form-inline"
                            [ngClass]="{
                              'has-success':
                                county?.valid && (county?.touched || county?.dirty),
                              'has-danger':
                                county?.invalid && (county?.touched || county?.dirty)
                            }"
                          >
                            <label>County</label>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                [ngModel]="house?.addressDetails?.county"
                                (ngModelChange)="house.addressDetails.county = $event"
                                name="county"
                                #county="ngModel"
                                required
                              />
                              <div
                                *ngIf="
                                  !county.errors && (county?.touched || county?.dirty)
                                "
                                class="input-group-prepend"
                              >
                                <button type="button" class="btn btn-outline-success">
                                  <i class="fa fa-check" aria-hidden="true"></i>
                                </button>
                              </div>
                            </div>
                            <div
                              class="form-group-feedback"
                              *ngIf="county.errors && (county?.touched || county?.dirty)"
                            >
                              <p style="color: red" *ngIf="county?.errors.required">
                                County Is Required
                              </p>
                            </div>
                          </div>
                        </div>
          
                      
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>

                <div class="col-md-6 right-side-deal-info">
                <div class="row">
                  <div class="md-col-6">

                  
                  <!--PURCHASE PRICE-->
                  <div class="form-row">
                    <label>Purchase Price</label>
                    <input  name="purchasePrice" [ngModel]="house?.purchasePrice || 0.00"
                    (ngModel)="house.purchasePrice = $event"
                    (ngModelChange) = "house.purchasePrice = $event; "
                      value='0.00' type="number"  class="cstm-input">
                  </div>
                  <!--DOWN PAYMENT-->
                  <div class="form-row">
                    <label>DOWN PAYMENT</label>
                    <input  name="downPayment" [ngModel]="house?.downPayment || 0.00"
                    (ngModel)="house.downPayment = $event"
                    (ngModelChange) = "house.downPayment = $event; "
                      value='0.00' type="number"  class="cstm-input">
                  </div>
                    <!--Deposit-->
                    <div class="form-row">
                      <label>Deposit</label>
                      <input  name="deposit" [ngModel]="house?.deposit || 0.00"
                      (ngModel)="house.deposit = $event"
                      (ngModelChange) = "house.deposit = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                  <!--TAXES-->
                  <div class="form-row">
                    <label>TAXES</label>
                    <input  name="taxes" [ngModel]="house?.taxes || 0.00"
                    (ngModel)="house.taxes = $event"
                    (ngModelChange) = "house.taxes = $event; "
                    value='0.00' type="number"    class="cstm-input sec-input">
                  </div>
                  <!--CLOSING COST-->
                  <div class="form-row">
                    <label>Closing Cost</label>
                    <input  name="closingCostMisc" [ngModel]="house?.closingCostMisc || 0.00"
                    (ngModel)="house.closingCostMisc = $event"
                    (ngModelChange) = "house.closingCostMisc = $event; "
                      value='0.00' type="number"  class="cstm-input">
                  </div>
                    <!--POINTS-->
                    <div class="form-row">
                      <label>Points</label>
                      <input  name="points" [ngModel]="house?.points || 0.00"
                      (ngModel)="house.points = $event"
                      (ngModelChange) = "house.points = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                    <!--SURVEY-->
                    <div class="form-row">
                      <label>Survey</label>
                      <input  name="survey" [ngModel]="house?.survey || 0.00"
                      (ngModel)="house.survey = $event"
                      (ngModelChange) = "house.survey = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                    <!--INSURANCE-->
                    <div class="form-row">
                      <label>Insurance</label>
                      <input  name="insurance" [ngModel]="house?.insurance || 0.00"
                      (ngModel)="house.insurance = $event"
                      (ngModelChange) = "house.insurance = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                    <!--Cost At Close (amount to bring)-->
                    <div class="form-row">
                      <label>Cost At Close</label>
                      <input  name="costAtClose" [ngModel]="house?.costAtClose || 0.00"
                      (ngModel)="house.costAtClose = $event"
                      (ngModelChange) = "house.costAtClose = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                     <!--Fair Market Value-->
                     <div class="form-row">
                      <label>Fair Market Value</label>
                      <input  name="fairMarketValue" [ngModel]="house?.fairMarketValue || 0.00"
                      (ngModel)="house.fairMarketValue = $event"
                      (ngModelChange) = "house.fairMarketValue = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                      <!--Rehab-->
                      <div class="form-row">
                        <label>Projected Rehab</label>
                        <input  name="projectedRehab" [ngModel]="house?.projectedRehab || 0.00"
                        (ngModel)="house.projectedRehab = $event"
                        (ngModelChange) = "house.projectedRehab = $event; "
                          value='0.00' type="number"  class="cstm-input">
                      </div>
                        <!--Equity-->
                    <div class="form-row">
                      <label>Equity At Rehab</label>
                      <input  name="equityAtClose" [ngModel]="house?.equityAtClose || 0.00"
                      (ngModel)="house.equityAtClose = $event"
                      (ngModelChange) = "house.equityAtClose = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                     <!-- Square Feet-->
                     <div class="form-row">
                      <label>Square Feet</label>
                      <input  name="Square Feet" [ngModel]="house?.squareFeet || 0.00"
                      (ngModel)="house.squareFeet = $event"
                      (ngModelChange) = "house.squareFeet = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>

                     <!--owed p Square Feet-->
                     <div class="form-row">
                      <label>Owed P Square</label>
                      <input  name="owedPerSquare" [ngModel]="house?.owedPerSquare || 0.00"
                      (ngModel)="house.owedPerSquare = $event"
                      (ngModelChange) = "house.owedPerSquare = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                  </div>
                  <div class="md-col-6">

                
                  <!--FINANCED-->
                  <div class="form-row">
                    <label>Financed</label>
                    <input  name="financed" [ngModel]="house?.financed || 0.00"
                    (ngModel)="house.financed = $event"
                    (ngModelChange) = "house.financed = $event; "
                      value='0.00' type="number"  class="cstm-input">
                  </div>
                  <!--APR-->
                  <div class="form-row">
                    <label>APR</label>
                    <input  name="apr" [ngModel]="house?.apr || 0.00"
                    (ngModel)="house.apr = $event"
                    (ngModelChange) = "house.apr = $event; "
                    (blur) = "aprChanged($event)"
                    (keydown) = "aprEntered($event)"
                      value='0.00' type="number"  class="cstm-input">
                  </div>
                   <!--TERM-->
                  <div class="form-row">
                    <label>Loan Term</label>
                    <input   name="term" [ngModel]="house?.term || 0.00"
                    (ngModel)="house.payment = $event"
                    (ngModelChange) = "house.term = $event; "
                    (blur) = "termChanged($event)"
                    (keydown) = "termEntered($event)"
                      value='0.00' type="number"  class="cstm-input">
                  </div>
                   <!--PAYMENT-->
                  <div class="form-row">
                    <label>Payment</label>
                    <input  name="payment" [ngModel]="house?.payment || 0.00"
                    (ngModel)="house.payment = $event"
                    (ngModelChange) = "house.payment = $event; "
                    (blur) = "paymentChanged($event)"
                    (keydown) = "paymentEntered($event)"
                      value='0.00' type="number"  class="cstm-input">
                  </div>
   <!--Projected Monthly-->
   <div class="form-row">
    <label>Monthly BNB Cost</label>
    <input  name="projectedMonthlyMiscCost" [ngModel]="house?.projectedMonthlyMiscCost || 0.00"
    (ngModel)="house.projectedMonthlyMiscCost = $event"
    (ngModelChange) = "house.projectedMonthlyMiscCost = $event; "
      value='0.00' type="number"  class="cstm-input">
  </div>
                     <!--Projected Monthly-->
                  <div class="form-row">
                    <label>Projected BNB Income</label>
                    <input  name="projectedMonthlyIncome" [ngModel]="house?.projectedMonthlyIncome || 0.00"
                    (ngModel)="house.projectedMonthlyIncome = $event"
                    (ngModelChange) = "house.projectedMonthlyIncome = $event; "
                      value='0.00' type="number"  class="cstm-input">
                  </div>
                    <!-- Utilities-->
                    <div class="form-row">
                      <label>Utilities</label>
                      <input  name="utilities" [ngModel]="house?.utilities || 0.00"
                      (ngModel)="house.utilities = $event"
                      (ngModelChange) = "house.utilities = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                       <!-- Escrows-->
                       <div class="form-row">
                        <label>Escrows</label>
                        <input  name="escrows" [ngModel]="house?.escrows || 0.00"
                        (ngModel)="house.escrows = $event"
                        (ngModelChange) = "house.escrows = $event; "
                          value='0.00' type="number"  class="cstm-input">
                      </div>
                   <!-- PerMonth PayDown-->
                   <div class="form-row">
                    <label>Monthly PayDown</label>
                    <input  name="payDownMonthly" [ngModel]="house?.payDownMonthly || 0.00"
                    (ngModel)="house.payDownMonthly = $event"
                    (ngModelChange) = "house.payDownMonthly = $event; "
                      value='0.00' type="number"  class="cstm-input">
                  </div>
                     <!--Projected Monthly-->
                     <div class="form-row">
                      <label>Carrying Monthly</label>
                      <input  name="carryingMonthly" [ngModel]="house?.carryingMonthly || 0.00"
                      (ngModel)="house.carryingMonthly = $event"
                      (ngModelChange) = "house.carryingMonthly = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                     <!--Net PerMonth-->
                     <div class="form-row">
                      <label>Monthly Net</label>
                      <input  name="monthlyNet" [ngModel]="house?.monthlyNet || 0.00"
                      (ngModel)="house.monthlyNet = $event"
                      (ngModelChange) = "house.monthlyNet = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                     <!--Equity PerMonth-->
                    <div class="form-row">
                      <label>Monthly Equity</label>
                      <input  name="equityPerMonth" [ngModel]="house?.equityPerMonth || 0.00"
                      (ngModel)="house.projectedMonthly = $event"
                      (ngModelChange) = "house.equityPerMonth = $event; "
                        value='0.00' type="number"  class="cstm-input">
                    </div>
                     
                    
                  </div>
                </div>
              </div>
                </div>
              </form>
           
            </div>
        </div>
        <div class="customer-sidebar sidebar-with-btns col-md-1">
          <div class="inner-sidebar">
            <ul>
              <li>
                <button (click) = "onSubmit(editForm)"  class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-1.png" />
                  <span>Save</span>
                </button>
              </li>
              <li>
                <button (click) = "add()"  class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-1.png" />
                  <span>Add Repair</span>
                </button>
              </li>

              <li *appHasRole="['Admin']">
                <button class="cstm-btn" (click) = "print()">
                  <img src="assets/images/icons8-microsoft-excel-48.png" />
                  <span>Print </span>
                </button>
              </li>
            
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>







