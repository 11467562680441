<div class="customer-form-sec">
  <div class="container-fluid">
<br><br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="customer" class="tab-pane fade in active show">
          <div class="row">

            <!-- <div class="col-md-6 cstm-col">
              <ul class="customer-form-btns">
                <li>
                  <a href="javascript:void(0)">
                    <img src="assets/images/btn-icon-1.jpg">
                    <span>Insurance</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <img src="assets/images/btn-icon-2.jpg">
                    <span>Lien holder</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <img src="assets/images/btn-icon-3.jpg">
                    <span>References</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Status</label>
              <select class="cstm-input">
                <option>A</option>
                <option>B</option>
              </select>
              <a href="javascript:void(0)">Active Account</a>
            </div> -->
            
            <div class="col-md-6 cstm-col">
              <div class="inline-blk">
                <label>First name</label>
                <input type="text" class="cstm-input" name="lastName"
                [ngModel]="sale?.coBuyer?.firstName"
                (ngModel)="sale.coBuyer.firstName = $event"
                (ngModelChange) = "sale.coBuyer.firstName = $event; "
                (blur) = "onChange($event)" >
              </div>
              <ul class="ph-btns">
                <li>
                  <a href="javascript:void(0)">
                    <img src="assets/images/phone-icon.jpg">
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>SDN</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 cstm-col">
              <label>INS Company</label>
              <input type="text" class="cstm-input" name="insuranceCompany"
                [ngModel]="sale?.coBuyer?.insuranceCompany"
                (ngModel)="sale.coBuyer.insuranceCompany = $event"
                (ngModelChange) = "sale.coBuyer.insuranceCompany = $event; "
                (blur) = "onChange($event)" >
            </div>

            <div class="col-md-6 cstm-col">
              <div class="inline-blk">
                <label>Last Name</label>
                <input type="text" class="cstm-input" name="lastName"
                [ngModel]="sale?.coBuyer?.lastName"
                (ngModel)="sale.coBuyer.lastName = $event"
                (ngModelChange) = "sale.coBuyer.lastName = $event; "
                (blur) = "onChange($event)" >
              </div>
         
            </div>
          
            <div class="col-md-6 cstm-col">
              <label >Ins Exp</label>
              <input type="date" class="cstm-input" name="insuranceExpires"
              [ngModel]="sale?.coBuyer?.insuranceExpires   | date: 'yyyy-MM-dd'"
              (ngModel)="sale.coBuyer.insuranceExpires = $event"
              (ngModelChange) = "sale.coBuyer.insuranceExpires = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <div class="inline-blk">
                <label>Middle Name</label>
                <input type="text" class="cstm-input" name="middleName"
                [ngModel]="sale?.coBuyer?.middleName"
                (ngModel)="sale.coBuyer.middleName = $event"
                (ngModelChange) = "sale.coBuyer.middleName = $event; "
                (blur) = "onChange($event)" >
              </div>
          
            </div>
            <div class="col-md-6 cstm-col">
              <label>Policy#</label>
              <input type="text" class="cstm-input" name="insurancePolicy"
              [ngModel]="sale?.coBuyer?.insurancePolicy"
              (ngModel)="sale.coBuyer.insurancePolicy = $event"
              (ngModelChange) = "sale.coBuyer.insurancePolicy = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Zip Code</label>
              <input type="text" class="cstm-input" name="zip"
              [ngModel]="sale?.coBuyer?.addressDetail?.zip"
              (ngModel)="sale.coBuyer.addressDetail.zip = $event"
              (ngModelChange) = "sale.coBuyer.addressDetail.zip = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Employer</label>
              <input type="text" class="cstm-input" name="name"
              [ngModel]="sale?.coBuyer?.employer?.name"
              (ngModel)="sale.coBuyer.employer.name = $event"
              (ngModelChange) = "sale.coBuyer.employer.name = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Address</label>
              <input type="text" class="cstm-input" name="street"
              [ngModel]="sale?.coBuyer?.addressDetail?.street"
              (ngModel)="sale.coBuyer.addressDetail.street = $event"
              (ngModelChange) = "sale.coBuyer.addressDetail.street = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Emp Phone</label>
              <input type="text" class="cstm-input" name="phone"
              [ngModel]="sale?.coBuyer?.employer?.phone"
              (ngModel)="sale.coBuyer.employer.phone = $event"
              (ngModelChange) = "sale.coBuyer.employer.phone = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>City</label>
              <input type="text" class="cstm-input" name="city"
              [ngModel]="sale?.coBuyer?.addressDetail?.city"
              (ngModel)="sale.coBuyer.addressDetail.city = $event"
              (ngModelChange) = "sale.coBuyer.addressDetail.city = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
             
            </div>
            <div class="col-md-6 cstm-col">
              <label>County</label>
              <input type="text" class="cstm-input" name="county"
              [ngModel]="sale?.coBuyer?.addressDetail?.county"
              (ngModel)="sale.coBuyer.addressDetail.county = $event"
              (ngModelChange) = "sale.coBuyer.addressDetail.county = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
             
            </div>
            <div class="col-md-6 cstm-col state-sec">
              <label>State</label>
              <input type="text" class="cstm-input" name="state"
              [ngModel]="sale?.coBuyer?.addressDetail?.state"
              (ngModel)="sale.coBuyer.addressDetail.state = $event"
              (ngModelChange) = "sale.coBuyer.addressDetail.state = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>SSN22</label>
              <input type="text" value="21137541">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Email</label>
              <input type="text" class="cstm-input" name="email"
              [ngModel]="sale?.coBuyer?.email"
              (ngModel)="sale.coBuyer.email = $event"
              (ngModelChange) = "sale.coBuyer.email = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Drive Lic</label>
              <input type="text" class="cstm-input" name="driverLicense"
              [ngModel]="sale?.coBuyer?.driversLicense"
              (ngModel)="sale.coBuyer.driversLicense = $event"
              (ngModelChange) = "sale.coBuyer.driversLicense = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Phone</label>
              <input type="text" class="cstm-input" name="phone"
              [ngModel]="sale?.coBuyer?.phone"
              (ngModel)="sale.coBuyer.phone = $event"
              (ngModelChange) = "sale.coBuyer.phone = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>DL Exp</label>
              <input type="date" class="cstm-input" name="driverLicenseExpires"
              [ngModel]="sale?.coBuyer?.driverLicenseExpires | date: 'yyyy-MM-dd'"
              (ngModel)="sale.coBuyer.driverLicenseExpires = $event"
              (ngModelChange) = "sale.coBuyer.driverLicenseExpires = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Avatar</label>
              <input type="file">
            </div>
            <div class="col-md-6 cstm-col">
              <label>DL State</label>
              <input type="text" class="cstm-input" name="driversLicenseState"
              [ngModel]="sale?.coBuyer?.driversLicenseState"
              (ngModel)="sale.coBuyer.driversLicenseState = $event"
              (ngModelChange) = "sale.coBuyer.driversLicenseState = $event; "
              (blur) = "onChange($event)" >
            </div>
            <div class="col-md-6 cstm-col">
              <label>Birthday</label>
              <input type="date" class="cstm-input" name="dateOfBirth"
                [ngModel]="sale?.coBuyer?.dateOfBirth | date: 'yyyy-MM-dd'"
                (ngModel)="sale.coBuyer.dateOfBirth = $event"
                (ngModelChange) = "sale.coBuyer.dateOfBirth = $event; "
                (blur) = "onChange($event)" >
            </div>

            <!--COSIGNER -->
       
          </div>
        </div>
        <div id="deal-info" class="tab-pane fade">
          <h2>Deal info form</h2>
        </div>
        <div id="loan" class="tab-pane fade">
          <h2>Loan form</h2>
        </div>
        <div id="vehicle" class="tab-pane fade">
          <h2>Vehicle tab</h2>
        </div>
        <div id="misc-info" class="tab-pane fade">
          <h2>Misc tab</h2>
        </div>
        <div id="notes" class="tab-pane fade">
          <h2>Notes tab</h2>
        </div>
        <div id="washout-info" class="tab-pane fade">
          <h2>Washout tab</h2>
        </div>
        <div id="insurance" class="tab-pane fade">
          <h2>Insurance tab</h2>
        </div>
        <div id="lien-holder" class="tab-pane fade">
          <h2>Lien holder tab</h2>
        </div>
        <div id="references" class="tab-pane fade">
          <h2>References tab</h2>
        </div>
      </div>
      <div class="customer-sidebar col-md-2">
        <div class="inner-sidebar">
          <ul>
            <li>
              <a href="javascript:void(0)" (click) = "update()">
                <img src="assets/images/sidebar-icon-2.jpg">
                <span>Save</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="tabelViewClick()">
                <img src="assets/images/sidebar-icon-9.jpg">
                <span>Table View</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
