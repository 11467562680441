import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { UserWebsiteToReturnDto } from 'src/app/_models/DTOS/UserWebsiteToReturnDto';
import { WebsiteListToReturnDto } from 'src/app/_models/DTOS/WebsiteListToReturnDto';
import { MenuReturnWebsiteMenuParagraphDto } from 'src/app/_models/DTOS/WebsiteMenuParagraphReturndto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Website } from 'src/app/_models/website';
import { WebsiteMenu } from 'src/app/_models/websites/WebsiteMenu';
import { WebsiteMenuParagraph } from 'src/app/_models/websites/WebsiteMenuParagraph ';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Injectable({
    providedIn: 'root'
  })

  export class WebsiteMenuService {
    userAssignedToCompany: UserAssignedToCompany;
    website: Subject<Website> =  new BehaviorSubject<Website>(null);
  constructor(private http: HttpClient, private authService: AuthService) {
      this.authService.company.subscribe(data => { this.userAssignedToCompany = data; });
  }
  baseUrl = environment.apiUrl;

  updateMenuParagraph(entity: WebsiteMenuParagraph, webisiteId: any): Observable<WebsiteMenuParagraph> {
    return this.http.post<WebsiteMenuParagraph>(this.baseUrl + 'websitemenu/editparagraph/', entity);
  }
  addWebsiteMenuParagraph(websiteid: any, websiteMenuParagrahp: any): Observable<MenuReturnWebsiteMenuParagraphDto> {
    return this.http.post<MenuReturnWebsiteMenuParagraphDto>
    (this.baseUrl + 'websitemenu/postnewparagraph/' + websiteid, websiteMenuParagrahp);
  } 
  editWebsiteMenuParagraph(websiteid: any, websiteMenuParagrahp: any): Observable<MenuReturnWebsiteMenuParagraphDto> {
    return this.http.post<MenuReturnWebsiteMenuParagraphDto>
    (this.baseUrl + 'websitemenu/editparagraph/' + websiteid, websiteMenuParagrahp);
  }
  getWebsiteMenus(websiteid: number): Observable<WebsiteMenu[]> {
    return this.http.get<WebsiteMenu[]>(this.baseUrl + 'websitemenu/getwebsitemenu/' + websiteid);
  }
  }
