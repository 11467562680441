<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-11">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->

                <div class="row">
                  <div class="col-md-12 table-sec">
                    <table border ="1" borderColor="#ccc">
                      <tr>
                        <th>ID</th>
                        <th>URL</th>
                        <th>Company</th>
                        <th>Acct Type</th>

                      </tr>
                      <tr class="td-row" *ngFor="let website of WebsiteListToReturnDto">
                        <td>{{website.id}}</td>
                        <td>{{website.url}}</td>
                        <td>{{website.companyName}}</td>
                        <td>{{website.name}}</td>
                        <td >
                            <a class="btn btn-primary" (click)="navigateCompany(website)" data-toggle="tooltip" data-placement="top" title="View Website Detail"><i class="fa fa-fw fa-edit"></i></a> 
                          
                          </td>
                      </tr>
                    </table>
                  </div><!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div><!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-1">
        <div class="inner-sidebar">
          <ul>
            <li>
              <button (click) = "addNew()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>Add New</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-2.png">
                <span>View/Edit</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

