<div class="customer-form-sec">
  <div class="container-fluid">
    <br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="vehicle" class="tab-pane fade in active show">
          <div class="row vehicle-form">
            <div class="col-md-12 label-row label-row-padd-0">
              <label>Vehicle</label>
            </div>
            <div class="col-md-6 cstm-col">
              <div class="inline-blk">
                <label>Stock #</label>
                <input type="text" class="cstm-input" value="2114542">
                <input type="Checkbox" name="">
                <span>Draft</span>
              </div>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Country Code</label>
              <input type="text" value="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>VEH LOC</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Status</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>VIN 1</label>
              <input type="text" value="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Title No.</label>
              <input type="text" value="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Year 1</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Prev Title No.</label>
              <input type="text" value="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Make 1</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Previous State</label>
              <input type="text" value="">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Model 1</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Purchased as</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>EXT COLOR</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Purchase Price</label>
              <input type="text" value="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Style 1</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Repairs/Depreciate</label>
              <input type="text" value="0.00" disabled="" class="cstm-input">
              <input type="text" value="0.00" disabled="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Isplacement 1</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Total Cost</label>
              <input type="text" value="0.00" disabled="" class="cstm-input">
              <button>&laquo;</button>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Cylinders 1</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>Suggested Price</label>
              <input type="text" value="0.00" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>TITLE IN?</label>
              <input type="text" value="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Date Purchased</label>
              <input type="text" value="03/28/2018" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>LPC</label>
              <input type="text" value="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Purchased From</label>
              <select class="select-input">
                <option>Option 1</option>
                <option>Option 2</option>
              </select>
            </div>
            <div class="col-md-6 cstm-col">
              <label>TAG</label>
              <input type="text" value="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Trade In Acct</label>
              <input type="text" value="" disabled="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Original Price</label>
              <input type="text" value="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Date Sold</label>
              <input type="text" value="" disabled="" class="cstm-input">
            </div>
            <div class="col-md-6 cstm-col">
              <label>Sold To Acct</label>
              <input type="text" value="" disabled="" class="cstm-input">
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 cstm-col">
                  <div class="input-sec veh-mile-sec">
                    <div class="rw">
                      <label>Mileage 1</label>
                      <input type="text" value="0.0" class="cstm-input">
                    </div>
                    <div class="rw">
                      <label>Weight</label>
                      <input type="text" value="0.0" class="cstm-input">
                    </div>
                    <div class="rw">
                      <label>Mileage Code</label>
                      <input type="text" value="" class="cstm-input">
                    </div>
                  </div>
                  <a href="javascript:void(0)" class="veh-ebay-btn">
                    <span>List vehicle on</span>
                    <img src="assets/images/ebay-icon.jpg">
                  </a>
                </div>
                <div class="col-md-6 cstm-col">
                  <div class="rw">
                    <label>Sold To</label>
                    <input type="text" value="" class="cstm-input">
                  </div>
                  <div class="rw">
                    <label>GS Tax %</label>
                    <img src="assets/images/save-icon.jpg">
                    <input type="text" value="0.00" class="save-cstm-input">
                  </div>
                  <div class="rw">
                    <label>GS Tax</label>
                    <input type="text" value="0.00" class="cstm-input">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customer-sidebar col-md-2">
        <div class="inner-sidebar">
          <ul>
            <li>
              <a href="javascript:void(0)" data-toggle="modal" data-target="#buyer-guide-model">
                <img src="assets/images/icon-7.jpg">
                <span>Buyer Guide</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/vi-1.jpg">
                <span>Add New</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/vi-2.jpg">
                <span>Save</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-3.jpg">
                <span>Check</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-3.jpg">
                <span>Bill</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-4.jpg">
                <span>Charge</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/vi-4.jpg">
                <span>Vehicle Forms</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/vi-5.jpg">
                <span>Sell Vehicle</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/vi-6.jpg">
                <span>Table View</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="buyer-guide-model" role="dialog" tabindex="-1">
  <!-- <app-inventory-buyersguide></app-inventory-buyersguide> -->
</div>