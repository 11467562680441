import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { AdvertisingRoutingModule } from './advertising-routing.module';
import { WebsiteListComponent } from './website-list/website-list.component';
import { WebsiteNewwebsiteComponent } from '../modals/website-newwebsite/website-newwebsite.component';
import { WebsiteDashboardComponent } from './website-dashboard/website-dashboard.component';
import { StoreHoursComponent } from './store-hours/store-hours.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { CashDownComponent } from './cash-down/cash-down.component';
import { WebsiteSettingsComponent } from './website-settings/website-settings.component';
import { CompanyInfoComponent } from './company-info/company-info.component';
import { GpsMapComponent } from './gps-map/gps-map.component';
import { CompanyServiceoffersComponent } from './company-serviceoffers/company-serviceoffers.component';
import { CompanyPhotosComponent } from './company-photos/company-photos.component';
import { CompanyDepartmentsComponent } from './company-departments/company-departments.component';
import { DepartmentphotosComponent } from './company-departments/departmentphotos/departmentphotos.component';
import { CompanydeptModalComponent } from './company-departments/companydept-modal/companydept-modal.component';
import { CompanyDepartmentModalComponent } from './company-departments/company-department-modal/company-department-modal.component';
import { ServiceofferaddModalComponent } from '../modals/serviceofferadd-modal/serviceofferadd-modal.component';
import { OrderBy } from '../_pipes/orderby';
import { CompanyservicePhotoComponent } from './company-serviceoffers/companyservice-photo/companyservice-photo.component';
import { EmployeesComponent } from './employees/employees.component';
import { EditdepartmentModalComponent } from './employees/editdepartment-modal/editdepartment-modal.component';
import { CompanyhomepagePhotosComponent } from './company-photos/companyhomepage-photos/companyhomepage-photos.component';
import { WebsitemenusComponent } from './websitemenus/websitemenus.component';
import { WebsitemenuModalComponent } from './websitemenus/websitemenu-modal/websitemenu-modal.component';
import { WebsiteimagemanagerComponent } from './websitemenus/websiteimagemanager/websiteimagemanager.component';
import { WebsitemenuContentlistComponent } from './websitemenus/websitemenu-contentlist/websitemenu-contentlist.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditmenuModalComponent } from './websitemenus/editmenu-modal/editmenu-modal.component';
import { UploadimageModalComponent } from './websitemenus/websiteimagemanager/uploadimage-modal/uploadimage-modal.component';
import { WebsitemenuParagraphComponent } from './websitemenus/websitemenu-contentlist/websitemenu-paragraph/websitemenu-paragraph.component';
import { CompanyServiceofferssuboffermodalComponent } from './company-serviceoffers/company-serviceofferssuboffermodal/company-serviceofferssuboffermodal.component';


@NgModule({
  declarations: [
    WebsitemenuParagraphComponent,
    CompanyServiceofferssuboffermodalComponent,
    EditmenuModalComponent,
    UploadimageModalComponent,
    EmployeesComponent,
    WebsitemenusComponent,
    WebsitemenuModalComponent,
    WebsiteimagemanagerComponent,
    WebsitemenuContentlistComponent,
    CompanyhomepagePhotosComponent,
    EditdepartmentModalComponent,
    WebsiteListComponent,
    CompanyservicePhotoComponent,
    WebsiteNewwebsiteComponent,
    WebsiteDashboardComponent,
    StoreHoursComponent,
    SocialMediaComponent,
    CashDownComponent,
    WebsiteSettingsComponent,
    CompanyInfoComponent,
    GpsMapComponent,
    CompanyServiceoffersComponent,
    CompanyPhotosComponent,
    CompanyDepartmentsComponent,
    DepartmentphotosComponent,
    CompanydeptModalComponent,
    CompanyDepartmentModalComponent,
    ServiceofferaddModalComponent
],
  entryComponents: [
    UploadimageModalComponent,
    EditmenuModalComponent,
    EditdepartmentModalComponent,
    CompanydeptModalComponent,
    CompanyDepartmentModalComponent,
    WebsiteNewwebsiteComponent,
    ServiceofferaddModalComponent,

],
  imports: [
    DragDropModule,
    FormsModule,
    CommonModule,
    SharedModule,
    AdvertisingRoutingModule,
    AgChartsAngularModule,
    AgGridModule.withComponents([]),
  ]
})
export class AdvertisingModule { }
